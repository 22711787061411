import { observer } from "mobx-react";
import { Typography } from "@thepiquelab/archus-components-web";
import { DivProps } from "../../utils";
import { useMemo } from "react";

/**
 * Breadcrumb text sizes
 */
export type BreadcrumbSizeType = 1 | 2 | 3 | 4 | undefined;

/** Properties in BreadcrumbItems */
interface BreadcrumbItemProps extends DivProps {
  /** Breadcrumb Item label */
  name: string;
  /** Set to true if BreadcrumbItem is first */
  root?: boolean;
  /** Custom separator (Default is '>') */
  separator?: string;
  /** Set to true if BreadcrumbItem is last item in Breadcrumbs */
  current?: boolean;
  /** Text size of breadcrumbs */
  level: BreadcrumbSizeType;
}

/** Clickable item in Breadcrumbs component */
const BreadcrumbItem: React.FC<BreadcrumbItemProps> = (props) => {
  const { onClick, name, root, separator = ">", current, level } = props;

  /** Classes for BreadcrumbItem label */
  const nameClasses = useMemo(
    () => "!font-normal !m-0 " + (current ? '' : '!text-primary-navyLight'),
    [current]
  );

  /** Classes for entire BreadcrumbItem */
  const breadcrumbClasses = useMemo(
    () => "flex gap-2  " + (current ? '' : 'cursor-pointer'),
    [current]
  );

  return (
    <div onClick={onClick} className={breadcrumbClasses}>
      {!root && (
        <Typography.Heading level={level} className="!font-normal !text-primary-navyLight">
          {separator}
        </Typography.Heading>)
      }
      <Typography.Heading level={level} className={nameClasses}>
          {name}
      </Typography.Heading>
    </div>
  );
}

export default observer(BreadcrumbItem);
