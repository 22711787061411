import { observer } from "mobx-react";
import { CoreClass } from "../../utils";
import DirectoryRow from "../Directory/DirectoryRow";
import { useAppStore, useCoreStore } from "../../mobx";
import { FolderIcon } from "../Icons";
import { Typography } from "@thepiquelab/archus-components-web";
import { colors } from "../../constant";
import { useMemo, useState } from "react";

/** Core classes list item component properties */
interface AcademicLevelClassesListItemProps {
  /** List item data */
  coreClass: CoreClass;
}

/** Core Classes List Item */
const AcademicLevelClassesListItem : React.FC<AcademicLevelClassesListItemProps> = (props) => {
  const { coreClass } = props;

  const {
    isAcademicSupport
  } = useAppStore();

  const {
    isNotDisplayingMyClasses,
    setClassDetails
  } = useCoreStore();

  /** True if hovering over list item */
  const [isHovering, setIsHovering] = useState(false);

  /** Disable item if lessonPlan is null */
  const isDisabled = useMemo(() => !coreClass.lessonPlan, [coreClass]);

  /** Custom styles for list item */
  const itemStyles: React.CSSProperties = useMemo(() => ({
    ...((isHovering) ? { 
      backgroundColor: colors.primary.navy100, cursor: "pointer" 
    }: {}),
    ...(isDisabled ? { opacity: 0.5 } : {})
  }), [isHovering, isDisabled]);

  return (
    <DirectoryRow 
      key={coreClass.id}
      style={itemStyles}
      onMouseEnter={() => !isDisabled && setIsHovering(true)}
      onMouseLeave={() => !isDisabled && setIsHovering(false)}
      onClick={() => {
        if (!isDisabled) {
          setClassDetails({ 
            _id: coreClass.id,
            name: coreClass.shorthand ?? coreClass.classCode
          });
        }
      }}
    >
      <FolderIcon />
      <div className="flex flex-col items-start">
        <Typography.Heading level={4} className="!font-normal">
          {coreClass.shorthand ?? coreClass.name}
        </Typography.Heading>
        {
          (isNotDisplayingMyClasses || isAcademicSupport) && !!coreClass?.teacher?.fullName && (
            <Typography.Text size="small" className="!text-3 !text-primary-navyLight">
              { coreClass?.teacher?.fullName }
            </Typography.Text>
          )
        }
      </div>
    </DirectoryRow>
  );
};


export default observer(AcademicLevelClassesListItem);
