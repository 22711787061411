import { useCallback, useEffect, useMemo, useState } from "react";
import { SharedEvent } from "../../mobx/app.store";
import {
  Dropdown,
  Input,
  Typography,
} from "@thepiquelab/archus-components-web";
import { CloseOutlined } from "@ant-design/icons";
import { MenuProps } from "antd";
import { SharePermissionType } from "../../utils";
import PermissionSelector from "./PermissionSelector";
import { colors } from "../../constant";
import { FileAccess } from "../../models/file.model";
import { User } from "../../models/users.model";

/** SharedUserSelect Properties */
interface ShareUserSelectProps {
  /** Action on select of permission */
  onPermissionSelect: (permission: SharePermissionType) => void;
  /** Action on add/remove of selected users */
  onSelectedUsersChange: (user: User[]) => void;
  /** Users with access to current item */
  sharedUsers: FileAccess[];
  /** All users available for file/folder sharing */
  allUsers: User[];
}

/** Tag Component props */
interface TagProps {
  /** Tag label */
  label: string;
  /** Action on click of close icon on Tag Component */
  onClose: () => void;
}

/** Tag Component */
const Tag: React.FC<TagProps> = (props) => (
  <div className="flex items-center gap-1 p-2 rounded bg-gray-50 border border-gray-400">
    <Typography.Text size="small">{props.label}</Typography.Text>
    <CloseOutlined className="text-3" onClick={props.onClose} />
  </div>
);

/** Component for selecting users to grant a specific permission level */
const ShareUserSelect: React.FC<ShareUserSelectProps> = (props) => {
  const { onPermissionSelect, onSelectedUsersChange, sharedUsers, allUsers } =
    props;

  /** Filter for allUsers list */
  const [searchParams, setSearchParams] = useState("");

  /** Users selected for file/folder sharing */
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

  useEffect(() => {
    // If selected users was updated, run onSelectedUsersChange function
    if (selectedUsers) {
      onSelectedUsersChange(selectedUsers);
    }
  }, [onSelectedUsersChange, selectedUsers]);

  /**
   * Add to SelectedUsers array
   */
  const addSelectedUsers = useCallback(
    (user: User) => setSelectedUsers([...selectedUsers, user]),
    [setSelectedUsers, selectedUsers]
  );

  /**
   * Remove use from SelectedUsers array
   * @param user
   */
  const removeSelectedUsers = (user: User) =>
    setSelectedUsers(
      selectedUsers.filter((slctdUser) => user._id !== slctdUser._id)
    );

  /**
   * Users Ids with access to current item
   */
  const sharedUserIds = useMemo(
    () => sharedUsers.map((_user) => _user.user._id),
    [sharedUsers]
  );

  /**
   * All Users filtered with searchParams
   */
  const filteredUsers: MenuProps = useMemo(
    () => ({
      items: allUsers
        .filter(
          (user: User) =>
            !searchParams || user.name.toLowerCase().includes(searchParams)
        )
        .map((user: User) => {
          const disabled =
            selectedUsers.includes(user) || sharedUserIds.includes(user._id);
          const style = disabled
            ? { color: colors.primary.navyLight }
            : undefined;
          return {
            key: user._id,
            label: <div style={style}>{user.name}</div>,
            onClick: () => {
              addSelectedUsers(user);
              if (searchParams !== "") setSearchParams((prev) => ""); // reset search after user is selected.
            },
            disabled,
          };
        }),
    }),
    [addSelectedUsers, allUsers, searchParams, selectedUsers, sharedUserIds]
  );

  /**
   * SelectedUsers displayed as closeable Tags
   */
  const SelectedUsersTags = () =>
    selectedUsers && selectedUsers.length ? (
      <>
        {selectedUsers.map((user: User) => (
          <Tag
            label={user.name}
            onClose={() => removeSelectedUsers(user)}
            key={user._id}
          />
        ))}
      </>
    ) : (
      <></>
    );

  return (
    <>
      <div className="flex flex-grow border border-gray-400 bg-gray-300 rounded items-center p-4">
        <div className="flex flex-grow flex-wrap min-w-fit gap-1">
          <SelectedUsersTags />
          <Dropdown menu={filteredUsers} trigger={["click"]}>
            <Input
              value={searchParams}
              className="!p-0 flex-grow !w-unset"
              onChange={(e) => setSearchParams(e.target.value)}
              inputMode="text"
              bordered={false}
            />
          </Dropdown>
        </div>
        <PermissionSelector onPermissionSelect={onPermissionSelect} />
      </div>
    </>
  );
};

export default ShareUserSelect;
