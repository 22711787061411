import { observer } from "mobx-react";
import { useCoreStore } from "../../mobx";
import DirectoryEmpty from "../Directory/DirectoryEmpty";
import { EmptyFolder } from "../Icons";
import { CoreClass, LessonFlowScheduleType } from "../../utils";
import { Tabs } from "@thepiquelab/archus-components-web";
import moment from "moment";
import { useState } from "react";
import DirectoryBaseListItem from "../Directory/DirectoryBaseListItem";
import { BreadCrumbDataInterface } from "../../mobx/app.store";
import './tests-list.scss';

/**
 * Test list tabs filtering between recent(within the last 2 weeks) or past tests
 */
enum TestTabs {
  past = "Past Tests",
  recent = "Recent Tests",
}

/**
 * Custom test component properties
 */
interface CustomTestProps {
  /** Function for filtering whcih tests to display */
  conditionFunc: (acadWeek: number) => boolean;
  /** Current class details being displayed */
  currentClass: CoreClass;
  /** Function on click of test */
  onClick: (breadCrumb: BreadCrumbDataInterface, worksheetId: string, acadWeek: number, lessonId: string) => void;
};

/**
 * Component to display when no tests are available
 */
const EmptyTests = () => (
  <DirectoryEmpty 
    showIconOnly
    EmptyIcon={EmptyFolder} 
    subtitle="All the tests will be displayed here"
    title="No tests have been added yet" />
);

/**
 * Component for displaying list of tests based on condition provided
 * @param param0 
 */
const CustomTests: React.FC<CustomTestProps> = ({
  conditionFunc, currentClass, onClick
}) => {
  const lessonFlows = currentClass?.lessons?.flatMap(lesson => ({...lesson.lessonFlow, lessonID: lesson.id})) ?? [];
  const testItems = lessonFlows.flatMap(lessonFlow => {
    const academicWeek = lessonFlow?.academicWeek?.weekNumber;
    return conditionFunc(academicWeek) 
      ? lessonFlow.schedules
        .filter(sched => sched.type === LessonFlowScheduleType.Test && !!sched.test)
        .map(sched => 
            (
              <DirectoryBaseListItem 
                key={sched.id}
                itemTitle={sched.test.testName}
                itemSubtitle={academicWeek ? `Academic Week ${ academicWeek }`: undefined}
                onClick={() => onClick({
                    _id: sched.test.worksheetId,
                    name: sched.test.testName,
                  }, sched.test.worksheetId, academicWeek, lessonFlow.lessonID ?? "")
                }
              />
            )
          )
      : []
  })

  return (<>
    {
      testItems && testItems.length ? testItems: <EmptyTests />
    }
  </>);
};

/** Component for displaying list of Tests from Archus Core Service */
const TestsList = () => {
  const {
    currentClass,
    appendCurrentTestsPath,
    setCurrentTestDetails,
    clearTestSubmissions
  } = useCoreStore();

  /** Current type of tests being displayed */
  const [currentView, setCurrentView] = useState(TestTabs.recent);

  /** Current week number */
  const currentWeekNumber = moment().isoWeeks();

  /** On click of test folder, set current test details and append to folder paths for breadcrumbs */
  const onTestClick = (
      /** Breadcrumb to append to the end of the current test paths */
      breadCrumb: BreadCrumbDataInterface, 
      /** Worksheet to help retrieve and set current test details */
      worksheetId: string, 
      /** Academic week the test selected is in*/
      academicWeek: number, 
      /** Lesson ID the selected test is under */
      lessonID: string
    ) => {
    clearTestSubmissions();
    appendCurrentTestsPath(breadCrumb);
    setCurrentTestDetails(worksheetId, academicWeek, lessonID);
  }

  return (
      <div className="flex flex-col flex-grow">
        <div className="flex-grow h-100 overflow-scroll" onClick={(e) => e.stopPropagation()}>
          <Tabs
            tabBarStyle={{
              paddingLeft: '24px',
            }}
            tabBarGutter={32}
            size="large"
            activeKey={currentView}
            onChange={(tab) => {
              setCurrentView(tab as TestTabs)
            }}
            className="px-0 pt-4 text-base leading-tight font_regular"
          >
            
            <Tabs.TabPane tab={TestTabs.recent} 
              key={TestTabs.recent}
              style={{
                fontSize: '14px !important',
                fontWeight: '400'
              }}
            >
              {
                currentClass 
                  ? <CustomTests 
                    // Only show tests from 2 weeks ago to now
                    conditionFunc={(acadWeek) => (acadWeek >= currentWeekNumber - 2 && acadWeek <= currentWeekNumber )}
                    currentClass={currentClass}
                    onClick={onTestClick}
                  />
                  : <EmptyTests />
              }
            </Tabs.TabPane>

            <Tabs.TabPane tab={TestTabs.past} key={TestTabs.past}>
              {
                currentClass 
                  ? <CustomTests 
                    conditionFunc={(acadWeek) => acadWeek < currentWeekNumber - 2}
                    currentClass={currentClass}
                    onClick={onTestClick}
                  />
                  : <EmptyTests />
              }
            </Tabs.TabPane>
          </Tabs>
        </div>
    </div>
  );
}

export default observer(TestsList);