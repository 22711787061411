import React, { SVGProps } from "react";
import { FileProps, FileResponse } from "../mobx/app.store";

export * from './core-types';

/** Type for <div> properties */
export type DivProps = React.HTMLAttributes<HTMLDivElement>;

/** Common Interface for SVG Icon Components */
export interface IconSVGProps extends SVGProps<SVGSVGElement> {
  /** Custom size for both width and height */
  size?: string | number;
}

/** Type for retrieved files and files to be uploaded */
export type FileTypes = FileResponse | FileProps;

/**
 * Generate function for local comparing based on which property to compare
 * @param properties
 */
export const localCompareFuncFactory = (...properties: string[]) => {
  return (a: any, b: any) => {
    const items: Record<string, string> = properties.reduce((agg, property: string) => {
      return {
        itemA: agg.itemA ? agg.itemA[property]: a[property],
        itemB: agg.itemB ? agg.itemB[property]: b[property],
      };
    }, {} as Record<string, any>);

    return items.itemA.localeCompare(items.itemB, 'en', { numeric: true })
  };
}

/**
 * Function type that accepts a boolean and returns nothing
 */
export type ToggleFuncType = ((x: boolean) => void);

/**
 * User roles available in the Notes system
 * These should correspond with the roles found in Auth0.
 * If these roles change in the future, please update them based on Auth0 roles.
 */
export enum UserRole {
  /** The `Owner` role name in Auth0. */
  Owner = "Owner",
  /** The `Teacher` role name in Auth0. */
  Teacher = "Teacher",
  /** The `Academic Support` role name in Auth0. */
  AcademicSupport = "Academic Support",
  /** The `Teacher Supervisor` role name in Auth0. */
  TeacherSupervisor = "Teacher Supervisor"
}

/**
 * Roles allowed to access Notes Service
 */
// TODO: Eventually transition to permission based access
export const AllowedRoles = Object.values(UserRole);

/**
 * User roles with SUPER_ADMIN access
 */
export const superAdminRoles = [UserRole.Owner, UserRole.AcademicSupport];

/**
 * Permission levels for different files/folders
 * // TODO move somewhere it's relevant. (in a specific category inside src/models/*)
 */
export enum FileAccessType {
  /** 
   * Permission to view item
   * @note for "Files", "Notebooks"
   */
  View = "View",
  /** 
   * Permission to view item and create notebooks 
   * @note for "Files", "Notebooks"
   */
  Edit = "Edit",
  /** 
   * Permission to view, rename, clone, delete, share, and edit item and create notebooks
   * @note for "Files"
   */
  Manage = "Manage",
}

/**
 * SharePermission option item interface
 */
export interface SharePermissionType {
  /** Share Permission label */
  label: string;
  /** Share Permission value */
  value: FileAccessType,
  /** Description on what actions users can take with the currnet permission type */
  description: string;
};

/**
 * SharePermission option items for dropdown
 */
export const SharePermissionOptions: SharePermissionType[] = [
  { 
    label: "Can View",
    value: FileAccessType.View,
    description: "Users can only view files in this folder",
  },
  { 
    label: "Can Edit",
    value: FileAccessType.Edit,
    description: "Users can view files and create notebooks",
  },
  { 
    label: "Can Manage",
    value: FileAccessType.Manage,
    description: "Users can rename, share, make a copy, delete and add new content into folders",
  }
];

/**
 * Month strings in `Mon` format
 */
const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

/**
 * Returns date object to string with format `dd Mon yyyy`
 */
export const customDateFormatter = (date: Date) => {
  return `${date.getDay()} ${MONTHS[date.getMonth() - 1]} ${date.getFullYear()}`
};

/**
 * Creates a test file name based on the student name, test name, and class shorthand.
 * @param studentName - The name of the student.
 * @param testName - The name of the test.
 * @param classShorthand - The shorthand representation of the class.
 * @returns The generated test file name.
 */
export const createTestFileName = 
  (studentName: string, testName: string, classShorthand: string) => 
    `${studentName} - ${testName} - ${classShorthand}`;

/**
 * Converts a camel case string to sentence case.
 * @param str - The camel case string to convert.
 * @returns The converted sentence case string.
 */
export const convertCamelCaseToSentenceCase = (str: string) => str.replace(/([a-z])([A-Z])/g, '$1 $2')