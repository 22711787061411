import { observer } from "mobx-react";
import DirectoryRow from "./DirectoryRow";
import { DivProps } from "../../utils";
import { Divider } from "antd";
import React from "react";
import BulkSelectButton from "./BulkSelectButton";

/**
 * DirectoryHeader Properties
 */
interface DirectoryHeaderProperties extends DivProps {
  /** Elements to be placed on the right side of the divider */
  buttons?: React.ReactNode;
  /** True to hide Bulk Select mode button  */
  hideBulkSelect?: boolean;
}

/** 
 * Content for Directory Header
 */
const DirectoryHeader: React.FC<DirectoryHeaderProperties> = ({ children, ...props}) => {
  const {
    buttons,
    hideBulkSelect = false,
    ...divProps
  } = props

  return (
      <DirectoryRow {...divProps}>
        <div className="flex flex-grow">
          { children }
        </div>
        <Divider type="vertical" className="!text-gray-500 !h-11 !m-0" />
        { !hideBulkSelect && <BulkSelectButton />}
        { !!buttons &&
          <div {...divProps} className="flex gap-8">
            {buttons}
          </div>
        }
      </DirectoryRow>
    );
};

export default observer(DirectoryHeader);
