import { observer } from "mobx-react";
import QuickLinksListItem from "../QuickLinksListItem";
import { useAppStore } from "../../../mobx";
import { FileResponse, QuickLink } from "../../../mobx/app.store";
import { QuickLinksItemProps } from "../QuickLinksList";

/**
 * Quick Links Home Component
 */
const QuickLinksHome:React.FC<QuickLinksItemProps> = (props) => {
  const { currentActive, setCurrentActive, checkRoute, ...linkData } = props;
  const { quickLinks, setFolderPaths, folderPathGoRoot } = useAppStore();

  return <div className="flex flex-col gap-5">
      <QuickLinksListItem 
        isRoute
        link={linkData}  
        currentActive={currentActive} 
        onClick={(links:QuickLink[]) => {
          if (linkData.route) {
            checkRoute(linkData.route);
            folderPathGoRoot();
          }
        }} />
      <div className="flex flex-col gap-2">
        { 
          quickLinks.map(quickLink => (
            <QuickLinksListItem link={quickLink} 
              key={quickLink._id}
              currentActive={currentActive} 
              onClick={(links:QuickLink[]) => {
                if (linkData.route) {
                  checkRoute(linkData.route);
                
                  if (links.length){
                    setCurrentActive(links[links.length - 1]._id);
                    const fileResponses = links
                      ?.filter((link: QuickLink) => link.fileResponse)
                      .map((link: QuickLink) => link.fileResponse) as FileResponse[];
                    if (fileResponses.length){
                      setFolderPaths(fileResponses);
                    }
                  }
                }
              }} />
            ))
        }
      </div>
    </div>
  ;
}

export default observer(QuickLinksHome);
