
import React from 'react';
import { Dropdown } from "@thepiquelab/archus-components-web";
import { observer } from 'mobx-react';
import useDragActions from '../../hooks/useDragActions';
import { Button } from "@thepiquelab/archus-components-web";
import { PlusCircleFilled } from "@ant-design/icons";
import { useAppStore } from '../../mobx';

/**
 * Directory Add Button dropdown
 */
const DirectoryAddButton = () => {
  const { dragActionRootProps } = useDragActions(false, false, true);
  const { startCreateNewFolder } = useAppStore();

  return (
    <Dropdown
      trigger={["click"]}
      menu={
        { 
          items: [
            {
              key: 'upload',
              label: (
                <div onClick={dragActionRootProps.onClick}>
                  Upload Files
                </div>
              ),
            },
            {
              key: 'create',
              label: (
                <div onClick={() => startCreateNewFolder()}>
                  Create New Folder
                </div>
              ),
            }
          ]
        }
      }
    >
      <Button type="primary" 
        className="!rounded-lg min-w-12 min-h-12" 
        icon={<PlusCircleFilled />} 
      />
    </Dropdown>
  );
};

export default observer(DirectoryAddButton);
