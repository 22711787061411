import { useEffect } from "react";
import { observer } from "mobx-react";
import { Divider, Typography } from "@thepiquelab/archus-components-web";
import DirectoryContent from "../components/Directory/DirectoryContent";
import { useAppStore } from "../mobx";
import DirectoryHeader from "../components/Directory/DirectoryHeader";
import DirectoryBody from "../components/Directory/DirectoryBody";
import DirectoryInfo from "../components/Directory/DirectoryInfo";
import DirectoryEmpty from "../components/Directory/DirectoryEmpty";
import { EmptyTrash } from "../components/Icons/EmptyTrash";
import TrashList from "../components/Trash/TrashList";
import { isEmpty } from "lodash";
import useSimpleModal from "../hooks/useSimpleModal";

/** Directory of Deleted Files/Folders */
const Trash = () => {
  const {
    initTrashList,
    trashList,
    emptyTrash
  } = useAppStore();

  const { doOpenModal, SimpleModal } = useSimpleModal({
    title: "Empty Trash?",
    body: "All the items in the Trash will be removed completely and cannot be restored. Do you still want to proceed?",
    primaryButton: {
      label: 'Confirm',
      onClick: () => emptyTrash()
    },
    secondaryButton: {
      label: 'Cancel'
    }
  });

  useEffect(() => {
    initTrashList();
  }, [initTrashList]);

  /**
   * Display warning then empty all items in the trash
   */
  const doEmptyTrash = () => {
    if (!isEmpty(trashList)) {
      doOpenModal(true);
    }
  }

  return (
    <DirectoryContent>
      <DirectoryInfo button={isEmpty(trashList) ? undefined :{label: "Empty Trash", onClick: doEmptyTrash }}>
        Items shown here will be deleted permanently after 30 days
      </DirectoryInfo>
      <DirectoryHeader >
        <Typography.Heading level={4} className="!font-normal">
          Trash 
        </Typography.Heading>
      </DirectoryHeader>
      <Divider className="!text-gray-500 !m-0" />
      <DirectoryBody 
        items={trashList}
        directoryEmpty={
          <DirectoryEmpty 
            EmptyIcon={EmptyTrash}
            title="The Trash Bin is empty"
            subtitle="All the deleted items will be displayed here" />
        }
        >
          <TrashList />
        </DirectoryBody>
      <SimpleModal />
    </DirectoryContent>
  );
}

export default observer(Trash);