import { Modal, ModalButtonGroup } from "@thepiquelab/archus-components-web";
import { FileResponse, User } from "../../mobx/app.store";
import ShareUser from "../ShareUser/ShareUser";
import { useState } from "react";
import { SharePermissionOptions, SharePermissionType } from "../../utils";
import { observer } from "mobx-react";
import { useAppStore } from "../../mobx";

/**
 * ShareUserModal Properties
 */
interface ShareUserModalProps {
  /** File/Folder of interest in ShareUserModal */
  item?: FileResponse;
  /** Action on click of close/cancel buttons */
  onClose?: () => void;
}

/**
 * Modal for managing access to a specific file/folder
 * @param props
 */
const ShareUserModal: React.FC<ShareUserModalProps> = (props) => {
  const { onClose = () => {}, item } = props;

  const { shareToUsers } = useAppStore();

  /** Users Selected for Sharing */
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

  /** Permission level to assign selected users */
  const [permission, setPermission] = useState<SharePermissionType>(
    SharePermissionOptions[0]
  );

  /** True when item is in the process of being shared to selectedUsers */
  const [isLoading, setLoading] = useState(false);

  /**
   * On click of OK button, share the file/folder to selected users
   */
  const onOk = async () => {
    setLoading(true);
    try {
      if (item) {
        await shareToUsers(
          item._id,
          permission.value,
          selectedUsers.map((_user) => _user._id)
        );
      }
    } finally {
      if (onClose) {
        onClose();
      }
      setLoading(false);
    }
  };

  return (
    <Modal visible onClose={() => onClose && onClose()}>
      <Modal.Header title="Share" />
      <Modal.Content>
        <ShareUser
          item={item}
          onPermissionSelect={setPermission}
          onSelectedUsersChange={setSelectedUsers}
        />
      </Modal.Content>
      <Modal.Footer>
        <ModalButtonGroup
          showOk
          showCancel
          okText="Share"
          okButtonProps={{
            type: "primary",
            disabled: isLoading || !selectedUsers.length,
          }}
          cancelButtonProps={{
            type: "ghost",
            disabled: isLoading,
          }}
          onOK={onOk}
          onCancel={() => {
            if (onClose) {
              onClose();
            }
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default observer(ShareUserModal);
