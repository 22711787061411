import React, { useEffect } from 'react';
import { useAppStore } from '../mobx';
import { Alert } from "@thepiquelab/archus-components-web";
import { observer } from 'mobx-react';

/*
*  Global Toast Component
*/
const Toast:React.FC = () => {
  const { showToast, toast, setToastShow } = useAppStore();

  return showToast ? (
    <div className="flex flex-row justify-center items-center w-full absolute">
      <div className="flex flex-row">
        <Alert
          description={toast.description}
          icon
          onClose={function noRefCheck() {
            setToastShow(false);
          }}
          type={toast.type}
          autoClose={toast.autoClose}
        />
      </div>
    </div>
  ): <></>;
};

export default observer(Toast);