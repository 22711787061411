import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import {  Skeleton } from "@thepiquelab/archus-components-web";
import { useCoreStore } from "../../mobx";
import DirectoryRow from "../Directory/DirectoryRow";
import { FolderIcon } from "../Icons";

/**
 * Component to display while classes/tests are loading
 */
const TestsSkeleton = () => {
  const {
    loadingInitData,
    coreClassesTotal,
    loadMoreOtherClasses,
    coreClasses
  } = useCoreStore();
  const domRef = useRef(null);

  /** For tracking if component can be seen inside scrollable parent */
  const [isVisible, setIsVisible] = useState(false);

  /**
   * Setting up observer to track if component has scrolled into view
   */
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setIsVisible(entry.isIntersecting));
    });

    //@ts-ignore
    observer.observe(domRef.current);
  }, []);

  /**
   * If component is visible and no data is being loaded, then load more data if there is any
   */
  useEffect(() => {
    if (isVisible && !loadingInitData) {
      loadMoreOtherClasses();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  return (<div ref={domRef}>
    {(loadingInitData || coreClasses.length < coreClassesTotal ) && 
    
      <DirectoryRow >
        <FolderIcon />
        <Skeleton.Input active className="max-h-4 rounded" /> 
      </DirectoryRow>
    }
  </div>);
}

export default observer(TestsSkeleton);