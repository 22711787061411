import { observer } from "mobx-react";
import QuickLinksListItem from "../QuickLinksListItem";
import { QuickLink } from "../../../mobx/app.store";
import { QuickLinksItemProps } from "../QuickLinksList";
import { useCoreStore } from "../../../mobx";

/**
 * Quick Links Tests Component
 */
const QuickLinksTests:React.FC<QuickLinksItemProps> = (props) => {
  const { currentActive, setCurrentActive, checkRoute, ...linkData } = props;
  const { testQuickLinks, setCurrentTestPaths, setClassDetails } = useCoreStore();

  return <div className="flex flex-col gap-5">
      <QuickLinksListItem 
        isRoute
        link={linkData}  
        currentActive={currentActive} 
        onClick={(links:QuickLink[]) => {
          if (linkData.route) {
            checkRoute(linkData.route);
            setCurrentTestPaths([]);
            setCurrentActive("");
          }
        }} />
      <div className="flex flex-col gap-2">
        { 
          testQuickLinks.map(quickLink => (
            <QuickLinksListItem link={quickLink} 
              currentActive={currentActive} 
              key={quickLink._id}
              isDisabled={quickLink.isDisabled}
              onClick={(links:QuickLink[]) => {
                if (linkData.route) {
                  checkRoute(linkData.route);

                  const currentLink = links[links.length - 1];
                  if (links.length && !currentLink.isDisabled){
                    setCurrentActive(currentLink._id);
                    setCurrentTestPaths(links);
                    setClassDetails(currentLink)
                  }
                }
              }} />
            ))
        }
      </div>
    </div>
  ;
}

export default observer(QuickLinksTests);
