import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Divider, Input, Button } from "@thepiquelab/archus-components-web";
import DirectoryContent from "../components/Directory/DirectoryContent";
import { useAppStore, useCoreStore } from "../mobx";
import DirectoryHeader from "../components/Directory/DirectoryHeader";
import Breadcrumbs from "../components/Breadcrumb/Breadcrumbs";
import { ClassFilterInput, ClassTabs } from "../utils";
import DirectoryRow from "../components/Directory/DirectoryRow";
import ClassList from "../components/Tests/ClassList";
import CustomSegment from "../components/Common/CustomSegment";
import ClassFilterDrawer from "../components/Tests/ClassFilterDrawer";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import TestsList from "../components/Tests/TestsList";
import StudentsList from "../components/Tests/StudentsList";



/** Directory of Classes and Class Tests */
const Tests = () => {
  const {
    initData,
    currentClassTab,
    currentTestPaths,
    setCurrentTestPaths,
    setCurrentClassTab,
    loadingInitData,
    setCoreClassesSearchValue,
    coreClassesSearchValue,
    isNotDisplayingMyClasses,
    loadOtherClassesWithFilter,

  } = useCoreStore();

  const {
    isAcademicSupport
  } = useAppStore();

  useEffect(() => {
    initData();
  }, [initData]);

  /** Set to true to show ClassFIlterDrawer */
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  /** ClassFilterInput Data */
  const [filterInput, setFilterInput] = useState<ClassFilterInput>({});

  useEffect(() => {
    loadOtherClassesWithFilter(filterInput);
  }, [filterInput]);

  return (
    <DirectoryContent>
      <DirectoryHeader 
        hideBulkSelect
        buttons={
          !currentTestPaths.length && !isAcademicSupport
            ? <CustomSegment
              disabled={loadingInitData}
              value={currentClassTab} 
              options={[ClassTabs.MyClasses, ClassTabs.OtherClasses]} 
              onChange={(value) => {
                setCurrentClassTab(value as ClassTabs)
              }}
            />
            : <></>
        }
      >
        <Breadcrumbs
          rootName="Tests"
          paths={currentTestPaths}
          onItemClick={
            path => {
              const items = [...currentTestPaths];
              const index = items.findIndex(item => item._id === path._id);
              setCurrentTestPaths(items.slice(0, index + 1));
            }
          }
          onRootClick={() => setCurrentTestPaths([])}
        />
      </DirectoryHeader>
      <Divider className="!text-gray-500 !m-0" />
      { 
        !currentTestPaths.length && (isNotDisplayingMyClasses || isAcademicSupport) &&
        <DirectoryRow>
          <Input
            value={coreClassesSearchValue}
            className=" !rounded-sm !w-80 !bg-gray-300" 
            placeholder="Search" 
            onChange={event => setCoreClassesSearchValue(event.target.value)}
            inputMode="search"
            addonAfter={
              <SearchOutlined className="cursor-pointer" />
            }
            disabled={loadingInitData}
          />
          <div className="flex-grow"></div>
          <Button onClick={() => setShowFilterDrawer(true)} disabled={loadingInitData}>
            <FilterOutlined /> Filter
          </Button>
        </DirectoryRow>
      }
      { !currentTestPaths.length && <ClassList /> }
      { currentTestPaths.length === 1 && <TestsList /> }
      { currentTestPaths.length === 2 && <StudentsList /> }
      { 
          showFilterDrawer 
          && <ClassFilterDrawer 
            onClose={() => setShowFilterDrawer(false)} 
            onApplyFilter={setFilterInput}
          />
        }
    </DirectoryContent>
  );
}

export default observer(Tests);