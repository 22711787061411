import React, { useState } from 'react';
import { ModalButtonGroup, Modal, Typography } from '@thepiquelab/archus-components-web';
import { ButtonProps } from 'antd';

/** Modal button props */
interface SimpleModalButtonProps extends ButtonProps {
  /** Button label */
  label: string;
  /** Function on click of modal button */
  onClick?: () => void;
}

/** Custom modal options */
interface SimpleModalOptions {
  /** Modal title text */
  title?: string;
  /** Modal content */
  body: React.ReactNode;
  /** Primary button properties */
  primaryButton?: SimpleModalButtonProps;
  /** Cancel button properties */
  secondaryButton?: SimpleModalButtonProps;
}

/**
 * Hook for using Modal component
 */
const useSimpleModal = (options: SimpleModalOptions) => {
  const {
    body,
    title,
    primaryButton,
    secondaryButton,
  } = options;

  /** Set to true if modal is displayed */
  const [openModal, doOpenModal] = useState(false);

  /**
   * Base Modal from archus-components-web
   */
  const SimpleModal = () => (
    <Modal visible={openModal} onClose={() => doOpenModal(false)}>
      <Modal.Header title={title} />
      <Modal.Content>
        <Typography.Text size="middle">
          {body}
        </Typography.Text>
      </Modal.Content>
      <Modal.Footer>
        <ModalButtonGroup 
          showOk={!!primaryButton} showCancel={!!secondaryButton}
          okText={primaryButton?.label}
          cancelText={secondaryButton?.label}
          okButtonProps={{
            type: "primary"
          }}
          cancelButtonProps={{
            type: "ghost"
          }}
          onOK={() => {
            if (primaryButton?.onClick) {
              primaryButton?.onClick();
            }
            doOpenModal(false);
          }}
          onCancel={() => {
            if (secondaryButton?.onClick) {
              secondaryButton?.onClick();
            }
            doOpenModal(false);
          }}
        />
      </Modal.Footer>
    </Modal>);
  
  return {
    doOpenModal,
    SimpleModal
  }};

export default useSimpleModal;
