import { observer } from "mobx-react";
import BreadcrumbItem, { BreadcrumbSizeType } from "./BreadcrumbItem";
import { last } from "lodash";
import { BreadCrumbDataInterface } from "../../mobx/app.store";

/**
 * Breadcrumb Properties
 */
export interface BreadCrumbsProps {
  /** Text size of breadcrumbs */
  level?: BreadcrumbSizeType;
  /** Paths to display as breadcrumbs */
  paths: BreadCrumbDataInterface[];
  /** Action on click of first item in the path */
  onRootClick: () => void;
  /** Action on click of any item in the path */
  onItemClick: (path: BreadCrumbDataInterface) => void;
  /** Path to display when paths is empty */
  rootName?: string;
};

/** Folder paths in Directory Header */
const Breadcrumbs:React.FC<BreadCrumbsProps> = (props) => {
  const {
    level = 4,
    rootName = "Dashboard",
    paths,
    onRootClick,
    onItemClick
  } = props;

  return (
    <div className="flex-grow flex flex-wrap gap-2">
      <BreadcrumbItem onClick={onRootClick} name={rootName}
        current={paths.length === 0} 
        level={level}
        root
      />
      { 
        paths.map((path) => (
          <BreadcrumbItem onClick={() => onItemClick(path)} 
            key={path._id}
            name={path.name} 
            current={last(paths)?._id === path._id}
            level={level}
          />
        ))
      }
    </div>
  );
}

export default observer(Breadcrumbs);
