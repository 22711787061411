import { observer } from "mobx-react";
import DirectoryRow from "./DirectoryRow";
import { Input } from "@thepiquelab/archus-components-web";
import { KeyboardEvent, useEffect, useState } from "react";
import { useAppStore } from "../../mobx";
import { FileIcon, FolderIcon } from "../Icons";

/** Newly created folder default name */
const newFolderName = "New Folder";

/**
 * New Folder Component Properties
 */
interface DirectoryNewFolderProps {
  /** Original name of edited file/folder */
  originalName?: string;
  /** ID of edited file/folder */
  id?: string;
  /** True if item is a file */
  isFile?: boolean;
  /** Action after updating file/folder */
  doAfterRename?: () => void;
};

/**
 * Create new folder component
 * @returns 
 */
const DirectoryNewFolder: React.FC<DirectoryNewFolderProps> = (props) => {
  const {
    originalName,
    id,
    isFile,
    doAfterRename,
  } = props;

  const { 
    displayFiles, 
    createNewFolder, 
    renameItem,
    setToastShow
  } = useAppStore();

  /** Total number of folders that start with 'New Folder' */
  const totalNew = displayFiles.reduce(
    (total, file) => (total + (file.name.startsWith(newFolderName) ? 1: 0) ),
    0
  );

  /** New/Edit folder name */
  const [newFolder, setNewFolder] = useState<string>( 
    originalName 
      ? originalName
      : newFolderName + (totalNew ? ` ${totalNew + 1}`: "")
  );

  /**
   * If user pressed 'Enter', create new folder with new folder name
   * @param e 
   */
  const doEnter = async (e:KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && newFolder) {
      // If id was provided, file/folder is for renaming
      if (id) {
        await renameItem(id, newFolder, doAfterRename);
      } else {
        createNewFolder(newFolder);
      }
    }
  }

  useEffect(() => {
    setToastShow(false);
  }, [setToastShow]);

  return (
    <DirectoryRow>
      { isFile ? <FileIcon /> : <FolderIcon /> }
      <Input className="basis-2/3" 
        value={newFolder} 
        onChange={e => setNewFolder(e.target.value)}
        onKeyUp={doEnter}
        autoFocus
        onFocus={e => e.currentTarget.select()}
      />
    </DirectoryRow>
  );
}

export default observer(DirectoryNewFolder);
