import { observer } from "mobx-react";
import { DivProps } from "../../utils";
import { Typography } from "@thepiquelab/archus-components-web";

/**
 * Directory Information  Properties
 */
interface DirectoryInfoProps extends DivProps {
  /** Button to dispay on the far right of the Directory Information component */
  button?: {
    /** Button label */
    label: string;
    /** Action on click of button */
    onClick: () => void;
  };
}

/** 
 * Content for Directory Information
 */
const DirectoryInfo: React.FC<DirectoryInfoProps> = ({button, children, ...props}) => (
    <div className="px-7 py-3 bg-primary-blue flex gap-7 items-center h-12" {...props}>
      <Typography.Text size="middle" className="!text-white flex-grow">
        {children}
      </Typography.Text>
      {
        button && <div onClick={button.onClick}>
          <Typography.Text size="large" className="!text-white cursor-pointer">
            {button.label}
          </Typography.Text>
      </div>
      }
    </div>
  );

export default observer(DirectoryInfo);
