import { observer } from "mobx-react";
import { DivProps } from "../../utils";

/** Layout for rows in Directory component */
const DirectoryRow: React.FC<DivProps> = ({children, ...props}) => (
    <div
      {...props}
      className={`flex px-6 py-3 items-center gap-6 min-h-16 ${props.className ?? ''}`} 
      onClick={props.onClick}
    >
      {children}
    </div>
  );

export default observer(DirectoryRow);
