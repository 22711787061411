import { observer } from "mobx-react";
import { SharedEvent } from "../../mobx/app.store";
import { Typography } from "@thepiquelab/archus-components-web";
import { useAppStore } from "../../mobx";
import PermissionSelector from "./PermissionSelector";
import { CloseCircleFilled } from "@ant-design/icons";
import { FileAccess } from "../../models/file.model";

/**
 * ShareUser List Properties
 */
interface ShareUserListProps {
  /** Item of interest in ShareUserModal */
  itemID: string;
  /** Users with access to current item */
  sharedUsers: FileAccess[];
  /** Action after updating users access */
  doAfterUpdate?: () => void;
}

/** List of users with access to specified Item */
const ShareUserList: React.FC<ShareUserListProps> = (props) => {
  const { sharedUsers, doAfterUpdate, itemID } = props;

  const { setItemSharedPermission, removeUserPermissionFromItem } =
    useAppStore();

  return (
    <div className="flex flex-col gap-5">
      {sharedUsers?.map((shared: FileAccess) => (
        <div className="flex gap-3 items-center">
          <img
            src={shared.user.avatarUrl}
            alt={shared.user.name}
            className="rounded-full h-10 w-10"
          />
          <Typography.Text size="middle" className="flex-grow">
            {shared.user.name}
          </Typography.Text>
          <PermissionSelector
            initialPermission={shared.accessType}
            onPermissionSelect={(selectedPermission) => {
              setItemSharedPermission(
                itemID,
                shared.user._id,
                selectedPermission.value
              ).finally(() => {
                if (doAfterUpdate) {
                  doAfterUpdate();
                }
              });
            }}
            isModified={shared.isSubModified}
          />
          <CloseCircleFilled
            className="!text-primary-navy !text-5 cursor-pointer"
            onClick={() => {
              removeUserPermissionFromItem(itemID, shared.user._id).finally(
                () => {
                  if (doAfterUpdate) {
                    doAfterUpdate();
                  }
                }
              );
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default observer(ShareUserList);
