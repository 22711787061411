import { IconSVGProps } from "../../utils";

/** SVG Icon for Tests */
export const TestsIcon:React.FC<IconSVGProps>  = ({size = 16, ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_253_2064)">
      <path d="M14.1174 4.01071L10.2746 0.167857C10.1674 0.0607143 10.0228 0 9.87101 0H2.2853C1.96922 0 1.71387 0.255357 1.71387 0.571429V15.4286C1.71387 15.7446 1.96922 16 2.2853 16H13.7139C14.0299 16 14.2853 15.7446 14.2853 15.4286V4.41607C14.2853 4.26429 14.2246 4.11786 14.1174 4.01071ZM12.9674 4.67857H9.60673V1.31786L12.9674 4.67857ZM12.9996 14.7143H2.99958V1.28571H8.39244V5.14286C8.39244 5.34177 8.47146 5.53254 8.61211 5.67319C8.75276 5.81384 8.94353 5.89286 9.14244 5.89286H12.9996V14.7143ZM7.85673 9.89286H4.57101C4.49244 9.89286 4.42815 9.95714 4.42815 10.0357V10.8929C4.42815 10.9714 4.49244 11.0357 4.57101 11.0357H7.85673C7.9353 11.0357 7.99958 10.9714 7.99958 10.8929V10.0357C7.99958 9.95714 7.9353 9.89286 7.85673 9.89286ZM4.42815 7.60714V8.46429C4.42815 8.54286 4.49244 8.60714 4.57101 8.60714H11.4282C11.5067 8.60714 11.571 8.54286 11.571 8.46429V7.60714C11.571 7.52857 11.5067 7.46429 11.4282 7.46429H4.57101C4.49244 7.46429 4.42815 7.52857 4.42815 7.60714Z" fill={props.color}/>
    </g>
    <defs>
      <clipPath id="clip0_253_2064">
      <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);