import { Typography } from "@thepiquelab/archus-components-web";
import { observer } from "mobx-react";
import DirectoryRow from "./DirectoryRow";
import { DivProps } from "../../utils";
import { FileIcon, FolderIcon } from "../Icons";
import { NotebookIcon } from "../Icons/NotebookIcon";

/**
 * Properties for Directory Base List Item
 */
export interface DirectoryBaseListItemProps extends DivProps {
  /** Header text */
  itemTitle: string;
  /** Sub header text */
  itemSubtitle?: string;
  /** Number of notebooks for file item */
  notebookNumber?: string;
  /** True to display FileIcon isntead of FolderIcon */
  isFile?: boolean;
  /** Component to display on the right side of the item */
  sideComponent?: React.ReactNode;
};

/**
 * Base Directory List Item
 * @param props 
 */
const DirectoryBaseListItem: React.FC<DirectoryBaseListItemProps> = (allProps) => {
  const {
    itemSubtitle,
    itemTitle,
    notebookNumber,
    isFile,
    children,
    sideComponent = <></>,
    ...props
  } = allProps;

  return (
    <DirectoryRow 
      className="cursor-pointer hover:bg-primary-navy100"
      {...props}
    >
      {
        children
      }
      { isFile ? <FileIcon /> : <FolderIcon /> }
      <div className="flex flex-col items-start flex-grow">
        <Typography.Heading level={4} className="!font-normal flex-grow break-words">
          {itemTitle}
        </Typography.Heading>
        {
          itemSubtitle ?
          <Typography.Text size="small" className="!text-3 !text-primary-navyLight">
            { itemSubtitle }
          </Typography.Text>
          : <></>
        }
      </div>
      { 
        !notebookNumber
          ? sideComponent
          : <div className="flex gap-1 items-center min-w-12 justify-start">
            <NotebookIcon />
            <Typography.Heading level={4} className="!font-normal !text-primary-blue">
              {notebookNumber}
            </Typography.Heading>
          </div> 
      }
    </DirectoryRow>
  );
}

export default observer(DirectoryBaseListItem);
