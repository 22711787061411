/* eslint-disable */
export const Auth0Config = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN ?? "thepilab.au.auth0.com",
  clientId:
    process.env.REACT_APP_AUTH0_CLIENT_ID ?? "mjDFdwiiPSjTN5pfnusDPzPSWF0sf6pa",

  authorizationParams: {
    audience:
      process.env.REACT_APP_AUTH0_API_AUDIENCE_NOTES_SERVICE ??
      "https://erp.thepilab.com",
    scope: process.env.REACT_APP_AUTH0_SCOPES ?? "openid profile email",
    organization: process.env.REACT_APP_AUTH0_ORGANIZATION || undefined,
    redirect_uri: window.location.origin,
  },
  coreAudience:
    process.env.REACT_APP_AUTH0_API_AUDIENCE_CORE_SERVICE ??
    "https://erp.thepilab.com",
  useRefreshTokens: true,
};

/* eslint-disable */
export const AppConfig = {
  baseUrl: process.env.REACT_APP_ARCHUS_NOTES_SERVICE_ENDPOINT ?? "",
  coreUrl: `${process.env.REACT_APP_ARCHUS_NOTES_SERVICE_ENDPOINT}/core`,
  pspdfkitLicenseKey: process.env.PSPDFKIT_LICENSE_KEY,
};
