import { IconSVGProps } from "../../utils";

/** SVG for no Files/Folder */
export const EmptyFolder:React.FC<IconSVGProps> = ({height = 200, width = 325, ...props}) => (
  <svg width={width} height={height} viewBox="0 0 325 200" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M112.376 199.769C132.638 199.769 149.064 189.992 149.064 177.931C149.064 165.87 132.638 156.093 112.376 156.093C92.1138 156.093 75.688 165.87 75.688 177.931C75.688 189.992 92.1138 199.769 112.376 199.769Z" fill="#E9EFF4"/>
    <path d="M220.156 29.2296L171.844 1.34387L123.531 29.2296L171.844 57.1153L220.156 29.2296Z" fill="#FC5454"/>
    <path d="M171.844 98.1039L220.157 70.2182V29.2296L171.844 57.1153V98.1039Z" fill="#F24444"/>
    <path d="M171.844 98.1039L123.531 70.2182V29.2296L171.844 57.1153V98.1039Z" fill="#E23737"/>
    <path d="M193.413 24.3916V11.8934H150.207V25.8027C150.207 32.9253 159.883 38.704 171.911 38.704C183.871 38.704 193.615 32.9253 193.615 25.8027C193.547 25.3323 193.48 24.862 193.413 24.3916Z" fill="#F24444"/>
    <path d="M171.843 25.8027C183.83 25.8027 193.547 20.0265 193.547 12.9013C193.547 5.77613 183.83 0 171.843 0C159.857 0 150.14 5.77613 150.14 12.9013C150.14 20.0265 159.857 25.8027 171.843 25.8027Z" fill="#FF6C6C"/>
    <path d="M171.844 97.2976L123.531 69.4119L75.2183 97.2976L123.531 125.183L171.844 97.2976Z" fill="#41C9F9"/>
    <path d="M123.531 166.172L171.844 138.286V97.2976L123.531 125.25V166.172Z" fill="#38B4F2"/>
    <path d="M123.531 166.172L75.2183 138.286V97.2976L123.531 125.183V166.172Z" fill="#2397E5"/>
    <path d="M145.101 92.5268V79.9614H101.895V93.8707C101.895 100.993 111.571 106.772 123.598 106.772C135.559 106.772 145.302 100.993 145.302 93.8707C145.235 93.4003 145.168 92.93 145.101 92.5268Z" fill="#38B4F2"/>
    <path d="M123.531 93.8707C135.518 93.8707 145.235 88.0945 145.235 80.9693C145.235 73.8441 135.518 68.068 123.531 68.068C111.544 68.068 101.827 73.8441 101.827 80.9693C101.827 88.0945 111.544 93.8707 123.531 93.8707Z" fill="#52DEFF"/>
    <path d="M268.537 97.2976L220.157 69.4119L171.844 97.2976L220.157 125.183L268.537 97.2976Z" fill="#41C9F9"/>
    <path d="M220.157 166.172L268.47 138.286V97.2976L220.157 125.25V166.172Z" fill="#38B4F2"/>
    <path d="M220.157 166.172L171.844 138.286V97.2976L220.157 125.183V166.172Z" fill="#2397E5"/>
    <path d="M241.726 92.5268V79.9614H198.52V93.8707C198.52 100.993 208.196 106.772 220.224 106.772C232.184 106.772 241.928 100.993 241.928 93.8707C241.86 93.4003 241.793 92.93 241.726 92.5268Z" fill="#38B4F2"/>
    <path d="M220.156 93.8707C232.143 93.8707 241.86 88.0945 241.86 80.9693C241.86 73.8441 232.143 68.068 220.156 68.068C208.17 68.068 198.453 73.8441 198.453 80.9693C198.453 88.0945 208.17 93.8707 220.156 93.8707Z" fill="#52DEFF"/>
    <path d="M220.156 125.116L171.844 97.2303L123.531 125.116L171.844 153.002L220.156 125.116Z" fill="#41C9F9"/>
    <path d="M171.844 193.99L220.157 166.037V125.116L171.844 153.002V193.99Z" fill="#38B4F2"/>
    <path d="M171.844 193.99L123.531 166.037V125.116L171.844 153.002V193.99Z" fill="#2397E5"/>
    <path d="M193.413 120.278V107.713H150.207V121.622C150.207 128.745 159.883 134.523 171.911 134.523C183.871 134.523 193.615 128.745 193.615 121.622C193.547 121.219 193.48 120.748 193.413 120.278Z" fill="#38B4F2"/>
    <path d="M171.843 121.689C183.83 121.689 193.547 115.913 193.547 108.788C193.547 101.663 183.83 95.8865 171.843 95.8865C159.857 95.8865 150.14 101.663 150.14 108.788C150.14 115.913 159.857 121.689 171.843 121.689Z" fill="#52DEFF"/>
    <path d="M79.989 137.749C75.3526 138.958 70.6489 140.1 66.0125 141.31C63.7279 141.915 64.6686 145.409 66.9533 144.871C71.5897 143.662 76.2933 142.519 80.9297 141.31C83.2144 140.705 82.2736 137.144 79.989 137.749Z" fill="#442A25"/>
    <path d="M93.9654 151.322C94.3686 155.219 94.6373 159.116 94.7045 163.081C94.7717 166.777 95.3765 171.48 94.3014 175.042C94.7046 174.638 95.1749 174.168 95.578 173.765C94.5029 174.168 93.3606 174.302 92.2183 174.437C89.8665 174.773 90.8744 178.334 93.159 177.998C94.9733 177.729 96.9219 177.797 97.7955 176.049C98.7362 174.101 98.2659 171.077 98.3331 168.994C98.4674 163.081 98.1986 157.235 97.5939 151.389C97.3923 148.97 93.7638 148.97 93.9654 151.322Z" fill="#442A25"/>
    <path d="M121.045 152.935C121.045 158.109 121.045 163.283 121.045 168.456C121.045 171.01 121.045 173.63 121.045 176.184C121.045 178.401 121.918 181.761 119.298 182.366C117.013 182.836 117.954 186.397 120.238 185.927C122.254 185.524 124.068 185.389 124.606 183.172C125.278 180.283 124.74 176.587 124.74 173.63C124.74 166.709 124.74 159.856 124.74 152.935C124.673 150.583 121.045 150.583 121.045 152.935Z" fill="#442A25"/>
    <path d="M112.78 172.219C133.71 172.219 150.678 155.252 150.678 134.322C150.678 113.391 133.71 96.424 112.78 96.424C91.8497 96.424 74.8823 113.391 74.8823 134.322C74.8823 155.252 91.8497 172.219 112.78 172.219Z" fill="#FDDA44"/>
    <path d="M137.709 116.515C146.041 127.199 147.049 140.436 143.69 143.057C140.397 145.678 130.923 139.093 122.591 128.409C114.258 117.725 110.16 106.974 113.452 104.353C116.812 101.732 129.377 105.831 137.709 116.515Z" fill="#FDE272"/>
    <path d="M85.1427 140.484C86.7728 139.864 87.2917 137.252 86.3017 134.651C85.3117 132.05 83.1876 130.444 81.5575 131.064C79.9274 131.685 79.4085 134.297 80.3985 136.898C81.3886 139.499 83.5126 141.105 85.1427 140.484Z" fill="#442A25"/>
    <path d="M120.08 149.706C121.594 147.829 120.948 144.796 118.637 142.932C116.326 141.068 113.224 141.08 111.71 142.958C110.196 144.836 110.842 147.869 113.154 149.732C115.465 151.596 118.566 151.584 120.08 149.706Z" fill="#442A25"/>
    <path d="M90.6731 141.243C90.9419 145.342 93.0249 149.575 97.4597 150.381C101.693 151.187 104.986 148.433 105.389 144.266C105.59 142.183 102.365 142.251 102.163 144.266C101.962 146.82 99.5427 147.895 97.2581 147.021C94.9063 146.148 94.1 143.527 93.8984 141.243C93.6968 139.227 90.5387 139.227 90.6731 141.243Z" fill="#442A25"/>
    <path d="M132.987 106.34C133.87 102.165 127.611 97.3043 119.007 95.4837C110.402 93.6631 102.711 95.5717 101.828 99.7468C100.944 103.922 107.203 108.782 115.808 110.603C124.412 112.424 132.103 110.515 132.987 106.34Z" fill="#F4504D"/>
    <path d="M100.349 101.128C94.7716 105.495 93.898 114.97 102.432 117.456C110.629 119.808 121.985 113.559 129.578 109.863C129.578 109.863 133.476 101.934 124.673 98.3055C116.005 94.677 105.993 96.76 100.349 101.128Z" fill="#EDC51B"/>
    <path d="M100.349 101.128C94.7716 105.495 94.5028 109.729 100.013 113.021C105.59 116.381 122.389 113.962 129.646 109.796C129.646 109.796 133.543 101.867 124.74 98.2383C116.005 94.677 105.993 96.76 100.349 101.128Z" fill="#E53E3E"/>
    <path d="M133.072 105.764L101.76 100.389V97.5663L133.14 100.456L133.072 105.764Z" fill="#F4504D"/>
    <path d="M102.499 95.0801C100.214 99.3806 103.238 102.001 111.167 105.025C119.096 108.049 128.301 107.713 131.796 104.286C135.29 100.859 131.594 91.586 123.665 88.5623C115.131 85.2698 105.119 90.1078 102.499 95.0801Z" fill="#F4504D"/>
    <path d="M118.357 166.844C124.808 165.03 130.251 159.856 132.266 153.472C132.938 151.188 129.444 150.247 128.705 152.531C127.092 157.705 122.59 161.871 117.349 163.35C115.132 163.954 116.14 167.449 118.357 166.844Z" fill="#442A25"/>
    <path d="M22.269 56.7121C26.1285 56.7121 29.2572 53.5834 29.2572 49.7239C29.2572 45.8644 26.1285 42.7357 22.269 42.7357C18.4095 42.7357 15.2808 45.8644 15.2808 49.7239C15.2808 53.5834 18.4095 56.7121 22.269 56.7121Z" fill="#F44A4A"/>
    <path d="M74.4116 48.8504C81.2399 48.8504 86.7754 43.3149 86.7754 36.4866C86.7754 29.6583 81.2399 24.1228 74.4116 24.1228C67.5832 24.1228 62.0479 29.6583 62.0479 36.4866C62.0479 43.3149 67.5832 48.8504 74.4116 48.8504Z" fill="#288AEA"/>
    <path d="M37.5893 116.448C33.4905 114.163 35.4391 110.804 31.3402 108.519C27.2413 106.234 25.3599 109.661 21.3282 107.444C17.2293 105.159 19.178 101.8 15.0792 99.515C10.9803 97.2304 9.09886 100.657 5 98.4399" stroke="#FFDA40" strokeWidth="8.7212" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M255.971 35.4114L261.682 49.3879" stroke="#FFDA40" strokeWidth="9.74" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M265.781 39.5775L251.872 45.2219" stroke="#FFDA40" strokeWidth="9.74" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M315.707 59.9375L303.142 54.6291C301.664 54.0244 300.992 52.3445 301.597 50.8663L306.905 38.3009C307.51 36.8226 309.19 36.1507 310.668 36.7554L323.233 42.0638C324.711 42.6685 325.383 44.3484 324.779 45.8267L319.47 58.392C318.866 59.8703 317.186 60.5423 315.707 59.9375Z" fill="#F44A4A"/>
    <path d="M308.652 114.835C313.068 114.835 316.648 111.255 316.648 106.839C316.648 102.423 313.068 98.843 308.652 98.843C304.236 98.843 300.656 102.423 300.656 106.839C300.656 111.255 304.236 114.835 308.652 114.835Z" fill="#288AEA"/>
  </svg>
)