import React, { useMemo } from 'react';
import { Dropdown } from "@thepiquelab/archus-components-web";
import { EditableTestSubmissionStatus, TestSubmissionStatus, convertCamelCaseToSentenceCase } from '../../utils';
import { CaretDownOutlined } from '@ant-design/icons';

/**
 * Props for the TestStatusDropdown component.
 */
interface TestStatusDropdownProps {
  /** Action on select of selectable statuses. */
  onClick: (status: TestSubmissionStatus) => void;
  /** Current test status on display. */
  value: TestSubmissionStatus;
}

/** String array of Test Submission Statuses  */
const EditableTestSubmissionStatusArray: TestSubmissionStatus[] = Object.values(EditableTestSubmissionStatus);

/**
 * A dropdown component for selecting test status.
 *
 * @component
 * @example
 * ```tsx
 * import React from 'react';
 * import TestStatusDropdown from './TestStatusDropdown';
 *
 * const MyComponent: React.FC = () => {
 *   const handleStatusChange = (status: string) => {
 *     // handle status change logic
 *   };
 *
 *   return (
 *     <div>
 *       <h1>Test Status</h1>
 *       <TestStatusDropdown onClick={handleStatusChange} value="In Progress" />
 *     </div>
 *   );
 * };
 *
 * export default MyComponent;
 * ```
 */
const TestStatusDropdown: React.FC<TestStatusDropdownProps> = (props) => {
  const {
    onClick,
    value
   } = props;

  /**
   * Represents an array of items for the test status dropdown.
   * Each item contains a key, label, and onClick function.
   */
  const items = EditableTestSubmissionStatusArray.map((status:TestSubmissionStatus) => ({
    key: status,
    label: (
      <div className="text-primary-navy text-3">
        {convertCamelCaseToSentenceCase(status)}
      </div>
    ),
    onClick: () => {
      if (status !== value) {
        onClick(status);
      }
    },
    disabled: status === value
  }));

  /**
   * Represents an array of items for the test status dropdown.
   * Each item contains a key, label, and onClick function.
   */
  const displayValue = useMemo(() => convertCamelCaseToSentenceCase(value), [value])

  return (
    EditableTestSubmissionStatusArray.includes(value) 
      ? (
        <div className="px-2 py-1 bg-primary-blue200 inline rounded text-3">
          <Dropdown
            trigger={["click"]}
            menu={{ items }}
          >
            <div className='flex gap-2 items-center' onClick={e => e.stopPropagation()}>
              <span className='text-primary-blue'>{ displayValue }</span>
              <CaretDownOutlined className="text-primary-navy h-3 w-3" />
            </div>
          </Dropdown>
        </div>
      ): (
        <div className="px-2 py-1 bg-gray-300 inline rounded text-3">
          <div>{ displayValue }</div>
        </div>
      )
  );
};

export default TestStatusDropdown;
