import { AllowedRoles, IconSVGProps, UserRole, superAdminRoles } from "../utils";
import { DashboardIcon } from "../components/Icons/DashboardIcon";
import { TrashIcon } from "../components/Icons/TrashIcon";
import { TestsIcon } from "../components/Icons/TestsIcon";
import Home from "./Home";
import Trash from "./Trash";
import Tests from "./Tests";
import QuickLinksHome from "../components/QuickLinks/Items/QuickLinksHome";
import { QuickLinksItemProps } from "../components/QuickLinks/QuickLinksList";
import QuickLinksTests from "../components/QuickLinks/Items/QuickLinksTests";
import QuickLinksTrash from "../components/QuickLinks/Items/QuickLinksTrash";

/**
 * Existing routes
 */
export enum RouteItemRoutes {
  dashboard = 'dashboard',
  trash = 'trash',
  tests = 'tests'
}

/**
 * Route Item Data props
 */
export interface RouteItem {
  /** Route name/label to display */
  name: string;
  /** Route identifier */
  _id: RouteItemRoutes;
  /** Actual route path */
  route: string;
  /** Route Icon */
  Icon: React.FC<IconSVGProps>;
  /** Route content */
  element: React.ReactNode;
  /** Quick Link Content */
  quickLink: React.FC<QuickLinksItemProps>;
  // TODO: Eventually transition to permission based access
  /** Roles allowed to access route */
  allowedRoles: UserRole[];
}

/**
 * All Route Data
 */
export const ALL_ROUTES: Record<RouteItemRoutes, RouteItem> = {
  dashboard: {
    name: 'Dashboard',
    _id: RouteItemRoutes.dashboard,
    route: '/',
    Icon: DashboardIcon,
    element: <Home />,
    quickLink: QuickLinksHome,
    allowedRoles: superAdminRoles
  },
  tests: {
    name: 'Tests',
    _id: RouteItemRoutes.tests,
    route: '/tests',
    Icon: TestsIcon,
    element: <Tests />,
    quickLink: QuickLinksTests,
    allowedRoles: AllowedRoles
  },
  trash: {
    name: 'Trash',
    _id: RouteItemRoutes.trash,
    route: '/trash',
    Icon: TrashIcon,
    element: <Trash />,
    quickLink: QuickLinksTrash,
    allowedRoles: superAdminRoles
  },
}