import React, { ReactNode } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { Auth0Config } from "./config";
import { useNavigate } from "react-router-dom";

export const Auth0ProviderWithHistory: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: any) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={Auth0Config.domain}
      clientId={Auth0Config.clientId}
      useRefreshTokens={Auth0Config.useRefreshTokens}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      authorizationParams={{
        ...Auth0Config.authorizationParams,
      }}
    >
      {children}
    </Auth0Provider>
  );
};
