import { IconSVGProps } from "../../utils";

/** SVG Icon for Empty Folder for MoveModal */
export const EmptyFolderMoveModal:React.FC<IconSVGProps>  = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="112" height="87" viewBox="0 0 112 87" fill="none" {...props}>
  <g clipPath="url(#clip0_6477_64991)">
    <path d="M92.2676 36.0557L51.1436 14.1426L54.8318 9.78467L95.9558 31.6772L92.2676 36.0557Z" fill="#F24444"/>
    <path d="M111.877 54.9059L108.189 59.2638L92.2681 36.0557L95.9563 31.6772L111.877 54.9059Z" fill="#E7EDF2"/>
    <path d="M108.189 59.2637L67.0645 37.3712L51.1436 14.1426L92.2676 36.0556L108.189 59.2637Z" fill="#E23737"/>
    <path d="M82.3097 39.571L42.5176 15.2939L46.4722 11.1621L86.2439 35.4391L82.3097 39.571Z" fill="#F24444"/>
    <path d="M100.771 59.5721L96.8372 63.7039L82.3096 39.5708L86.2437 35.439L100.771 59.5721Z" fill="#E7EDF2"/>
    <path d="M96.8374 63.7041L57.0657 39.4065L42.5176 15.2939L82.3097 39.571L96.8374 63.7041Z" fill="#E23737"/>
    <path d="M93.0466 65.2045C94.6449 65.6156 96.2431 66.0267 97.8414 66.4173C98.538 66.6023 98.8454 65.5128 98.1282 65.3278C96.53 64.9167 94.9317 64.5056 93.3335 64.115C92.6368 63.9505 92.35 65.04 93.0466 65.2045Z" fill="#442A25"/>
    <path d="M82.5758 73.1187C89.7618 73.1187 95.5872 67.2746 95.5872 60.0655C95.5872 52.8564 89.7618 47.0122 82.5758 47.0122C75.3898 47.0122 69.5645 52.8564 69.5645 60.0655C69.5645 67.2746 75.3898 73.1187 82.5758 73.1187Z" fill="#FDDA44"/>
    <path d="M74.0107 53.9191C71.1421 57.5986 70.8143 62.1622 71.9412 63.0461C73.0682 63.93 76.3261 61.6894 79.1948 58.0098C82.0634 54.3302 83.4568 50.63 82.3298 49.7256C81.2028 48.8416 76.8794 50.2395 74.0107 53.9191Z" fill="#FDE272"/>
    <path d="M93.6824 60.9406C94.0206 60.0493 93.8488 59.1565 93.2989 58.9466C92.7489 58.7366 92.029 59.2889 91.6908 60.1801C91.3527 61.0714 91.5245 61.9642 92.0744 62.1742C92.6244 62.3842 93.3443 61.8319 93.6824 60.9406Z" fill="#442A25"/>
    <path d="M82.4705 65.3795C83.2634 64.7382 83.4801 63.688 82.9544 63.034C82.4288 62.38 81.3599 62.3697 80.567 63.0111C79.7741 63.6524 79.5575 64.7026 80.0831 65.3566C80.6088 66.0106 81.6776 66.0209 82.4705 65.3795Z" fill="#442A25"/>
    <path d="M85.4034 67.5483C85.096 66.8082 86.264 64.5882 88.1696 63.9304C90.0752 63.2726 91.2636 64.8554 90.6489 65.8215C90.0137 66.8082 89.6653 66.4382 88.4769 66.9316C87.6983 67.2194 86.0386 69.09 85.4034 67.5483Z" fill="#912B20"/>
    <path d="M81.4079 61.7102C81.1825 62.5119 79.9941 62.9024 79.2974 63.1902C78.6417 63.4575 78.9286 64.547 79.5843 64.2797C80.6908 63.8069 82.1046 63.293 82.4734 62.0185C82.6783 61.299 81.6128 61.0113 81.4079 61.7102Z" fill="#442A25"/>
    <path d="M91.4485 59.2845C92.2886 59.5312 93.1287 59.5723 94.0097 59.4696C94.3171 59.4284 94.4605 59.0379 94.3991 58.7706C94.3171 58.4417 94.0097 58.3389 93.7024 58.3801C93.0467 58.4828 92.391 58.4006 91.7353 58.1951C91.0591 57.9895 90.7518 59.0584 91.4485 59.2845Z" fill="#442A25"/>
    <path d="M86.08 65.5541C85.5063 66.3353 85.2604 67.1575 85.4038 67.5481C86.039 69.0898 87.6987 67.2192 88.4569 66.9109C88.7847 66.7875 89.0306 66.7053 89.256 66.6642C88.088 64.4852 86.121 65.5336 86.08 65.5541Z" fill="#FC5454"/>
    <path d="M75.7525 70.2617C76.736 72.0501 77.7605 73.8385 78.826 75.5858C79.1948 76.2025 80.1579 75.6475 79.7891 75.0103C78.7236 73.263 77.699 71.4951 76.7155 69.6862C76.3672 69.0695 75.4041 69.6245 75.7525 70.2617Z" fill="#442A25"/>
    <path d="M60.7947 80.8482L19.937 56.674L35.899 20.207L76.7566 44.3813L60.7947 80.8482Z" fill="#F24444"/>
    <path d="M66.5527 78.258L60.7949 80.8481L76.7569 44.3812L82.5146 41.7705L66.5527 78.258Z" fill="#E7EDF2"/>
    <path d="M82.5144 41.7704L41.6567 17.6167L35.8989 20.2068L76.7566 44.381L82.5144 41.7704Z" fill="#FC5454"/>
    <path d="M44.0949 86.0486L6.02393 59.6954L25.6331 29.457L63.7041 55.8102L44.0949 86.0486Z" fill="#F24444"/>
    <path d="M50.0784 84.4659L44.0952 86.0487L63.7044 55.8104L69.6876 54.2275L50.0784 84.4659Z" fill="#E7EDF2"/>
    <path d="M69.6875 54.2277L31.6165 27.8745L25.6333 29.4573L63.7043 55.8106L69.6875 54.2277Z" fill="#FC5454"/>
    <path d="M38.3215 17.3911C39.544 12.1513 38.016 7.31219 34.9087 6.58256C31.8014 5.85293 28.2914 9.50912 27.0689 14.7489C25.8465 19.9887 27.3744 24.8278 30.4818 25.5574C33.5891 26.2871 37.0991 22.6309 38.3215 17.3911Z" fill="#FF5252"/>
    <path d="M72.5391 35.4612L76.9707 16.4663L34.9131 6.59078L30.4815 25.5857L72.5391 35.4612Z" fill="#FFBB24"/>
    <path d="M76.9616 16.4658L72.5356 35.4393L89.2352 30.1152C89.8704 29.9097 90.0549 29.108 89.5836 28.6557L76.9616 16.4658Z" fill="#FFDBA6"/>
    <path d="M84.0308 28.1416C83.7234 29.4572 83.6209 30.7317 83.6619 31.9035L89.2148 30.1356C89.85 29.93 90.0344 29.1283 89.5631 28.6761L85.3626 24.606C84.8094 25.6338 84.3381 26.826 84.0308 28.1416Z" fill="#494851"/>
    <path d="M69.1338 24.6267C67.9044 29.8685 69.4412 34.7198 72.5557 35.4393L76.9816 16.4453C73.8671 15.7258 70.3428 19.3848 69.1338 24.6267Z" fill="#FFDBA6"/>
    <path d="M37.0464 27.1139L41.4723 8.11985L34.9154 6.57812L30.1616 15.479L30.4895 25.5722L37.0464 27.1139Z" fill="#FF5252"/>
    <path d="M45.6765 19.1157C46.899 13.876 45.371 9.0368 42.2637 8.30717C39.1563 7.57754 35.6464 11.2337 34.4239 16.4735C33.2014 21.7133 34.7294 26.5524 37.8367 27.282C40.9441 28.0117 44.454 24.3555 45.6765 19.1157Z" fill="#EBF0F7"/>
    <path d="M41.2111 28.0218L45.624 9.10693L42.2722 8.31989L37.8593 27.2347L41.2111 28.0218Z" fill="#EBF0F7"/>
    <path d="M49.03 19.9033C50.2525 14.6635 48.7245 9.8244 45.6172 9.09477C42.5099 8.36514 38.9999 12.0213 37.7774 17.2611C36.555 22.5009 38.0829 27.34 41.1903 28.0696C44.2976 28.7993 47.8076 25.1431 49.03 19.9033Z" fill="#FFBB24"/>
    <path opacity="0.23" d="M70.4047 20.3917L39.4233 13.1147" stroke="#C0C9D8" strokeWidth="7" strokeMiterlimit="10"/>
    <path opacity="0.23" d="M89.2352 30.1151C89.5631 30.0123 89.768 29.7451 89.8294 29.4367L68.7039 27.8333L26.6373 17.9663C26.5349 21.8515 28.0102 24.9966 30.469 25.5721L37.0259 27.1139L72.5356 35.4392L83.6823 31.8829L89.2352 30.1151Z" fill="#C0C9D8"/>
    <path d="M64.8316 36.0144L51.5333 25.3662L35.6943 31.6153L48.9925 42.2635L64.8316 36.0144Z" fill="#FDDA44"/>
    <path d="M47.1484 54.6387L62.9874 48.4101L64.8316 36.0146L48.9926 42.2638L47.1484 54.6387Z" fill="#FCC947"/>
    <path d="M47.1483 54.6383L33.8296 44.0107L35.6942 31.6152L48.9924 42.2634L47.1483 54.6383Z" fill="#EDB435"/>
    <path d="M56.9834 33.3422L57.5571 29.5393L44.5253 27.5659L43.8901 31.78C43.5622 33.9384 46.226 36.1173 49.8323 36.6724C53.4386 37.2068 56.6351 35.9118 56.9424 33.7534C56.9834 33.63 56.9834 33.4861 56.9834 33.3422Z" fill="#FCC947"/>
    <path d="M57.5573 29.8683C57.88 27.7127 55.2113 25.5239 51.5966 24.9793C47.982 24.4348 44.7902 25.7407 44.4675 27.8963C44.1449 30.0519 46.8136 32.2408 50.4282 32.7853C54.0429 33.3299 57.2347 32.0239 57.5573 29.8683Z" fill="#FCE856"/>
    <path d="M77.9655 1.00732L77.5967 5.59138" stroke="#FFDA40" strokeWidth="9.74" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M80.0557 3.47402L75.4863 3.104" stroke="#FFDA40" strokeWidth="9.74" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M108.496 33.6712C109.334 33.6712 110.013 32.9902 110.013 32.1501C110.013 31.31 109.334 30.6289 108.496 30.6289C107.659 30.6289 106.98 31.31 106.98 32.1501C106.98 32.9902 107.659 33.6712 108.496 33.6712Z" fill="#FDDA44"/>
    <path d="M107.287 11.2442L104.583 8.05794C104.275 7.68793 104.316 7.13291 104.685 6.804L107.861 4.11113C108.23 3.80278 108.783 3.84389 109.111 4.21391L111.795 7.40014C112.103 7.77015 112.062 8.32517 111.693 8.65407L108.517 11.3675C108.148 11.6759 107.595 11.6142 107.287 11.2442Z" fill="#F44A4A"/>
    <path d="M93.8456 16.5892C95.1922 16.5892 96.2839 15.494 96.2839 14.143C96.2839 12.792 95.1922 11.6968 93.8456 11.6968C92.4989 11.6968 91.4072 12.792 91.4072 14.143C91.4072 15.494 92.4989 16.5892 93.8456 16.5892Z" fill="#288AEA"/>
    <path d="M3.42201 24.9554C4.59892 24.9554 5.553 23.9983 5.553 22.8175C5.553 21.6368 4.59892 20.6797 3.42201 20.6797C2.24509 20.6797 1.29102 21.6368 1.29102 22.8175C1.29102 23.9983 2.24509 24.9554 3.42201 24.9554Z" fill="#F44A4A"/>
    <path d="M3.77022 42.2434C5.85245 42.2434 7.54043 40.55 7.54043 38.4611C7.54043 36.3721 5.85245 34.6787 3.77022 34.6787C1.68798 34.6787 0 36.3721 0 38.4611C0 40.55 1.68798 42.2434 3.77022 42.2434Z" fill="#288AEA"/>
    <path d="M17.519 12.8477C17.642 14.266 16.474 14.3688 16.597 15.7872C16.7199 17.2056 17.9083 17.1028 18.0518 18.5212C18.1747 19.9396 17.0068 20.0424 17.1297 21.4813C17.2527 22.9203 18.4411 22.7969 18.5845 24.2153" stroke="#FFDA40" strokeWidth="8.7212" strokeMiterlimit="10" strokeLinecap="round"/>
  </g>
  <defs>
    <clipPath id="clip0_6477_64991">
      <rect width="112" height="86.0488" fill="white"/>
    </clipPath>
  </defs>
</svg>
);