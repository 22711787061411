import { AlertProps } from "@thepiquelab/archus-components-web/dist/components/Alert";
import {
  dropRight,
  findIndex,
  isEmpty,
  last,
  pull,
  slice,
  uniqBy,
} from "lodash";
import { makeAutoObservable, runInAction } from "mobx";

import { factory } from "../network";
import { AppConfig } from "../config";
import { IdToken } from "@auth0/auth0-spa-js";
import {
  FileCategories,
  FileAccessType,
  UserRole,
  localCompareFuncFactory,
  superAdminRoles,
} from "../utils";
import { ALL_ROUTES, RouteItem } from "../pages/routes";
import { ResponseData } from "../network/types";

import { FileAccess as ModelFileAccess } from "../models/file.model";
import { User as ModelUser } from "../models/users.model";

export interface FileProps1 {
  mimeType: string;
  name: string;
  size: number;
  type: string;
  uri: string;
}
export interface FileProps {
  path: string;
  lastModified: string;
  lastModifiedDate: string;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
  contentHash?: string;
}
export enum LevelType {
  Level = "Level",
  TimePeriod = "Time Period",
  PreRequisite = "Pre-requisite",
  Enrollment = "Enrollment",
  ContentOverlaps = "Content Overlaps",
  Result = "Result",
}
export interface LevelProps {
  label: string;
  key: string;
}
export interface TestFlowProps {
  show: boolean;
}

/** Class course type */
export enum CourseType {
  Regular = "Regular",
  Workshop = "Workshop",
}

export interface CourseProps {
  _id: string;
  name: string;
  nameShort: string;
  type: CourseType;
}

export enum NotebookTemplate {
  Blank = "Blank",
  Grid = "Grid",
  Dotted = "Dotted",
  Line = "Line",
}
export interface MetadataProps {
  contentType: string;
  contentCreatedAt: number;
  contentModifiedAt: number;
  size: number;
  contentHash?: string;
}
export enum FileType {
  Folder = "Folder",
  File = "File",
  Note = "Note",
}

/**
 * User base interface
 * @deprecated Use `src/models/user.model.ts` User instead.
 */
export interface User {
  /** User ID */
  _id: string;
  /** User name */
  name: string;
  /** User profile pic url */
  avatarUrl?: string;
  /** Roles assigned to user */
  roles?: string[];
}

/**
 * QuickLinks interface for both parent and child
 */
export interface QuickLink {
  /** QuickLink ID */
  _id: string;
  /** Quick links label */
  name: string;
  /** Route if quick link is a route item */
  route?: string;
  /** Route key */
  routeKey?: string;
  /** Quicklink file data if quick link is a folder */
  fileResponse?: FileResponse;
  /** True if quicklink is disabled */
  isDisabled?: boolean;
}

/**
 * QuickLinks item
 */
export interface QuickLinks extends QuickLink {
  /** Children files of quicklink file */
  children?: QuickLink[];
}

/**
 * BreadCrumb Data type
 */
export interface BreadCrumbDataInterface {
  /** Breadcrumb ID */
  _id: string;
  /** Breadcrumb name */
  name: string;
}

/**
 * Base Interface for File/Folder types
 */
export interface ItemBase extends BreadCrumbDataInterface {
  /** If item is a file or folder  */
  type: FileType;
  /** ID of parent folder if item is a child */
  parentId?: string;
  /** Creation date */
  createdAt: string;
  /** Date of last update */
  updatedAt: string;
}

/**
 * Item data response from Archus Notes Service
 * TODO move this to `models/file.model.ts` folder just like how we organized on Notes App V2.
 */
export interface FileResponse extends ItemBase {
  createdBy: string;
  isDeleted: boolean;
  metadata: MetadataProps;
  ownerId: string;
  parentIds: string[];
  thumbnailUrl: string;
  updatedBy: string;
  notebooks?: any[];
  url: string;
  permission: FileAccessType;
  template?: NotebookTemplate;
  owner: {
    _id: string;
    name: string;
  };
  createdUser: {
    _id: string;
    name: string;
  };
  annotationVersion: number;
  /** @deprecated /v2/files already includes the shares via `accesses` */
  share?: {
    _id: string;
    notebookId: string;
    type: FileAccessType;
  };
  accesses: ModelFileAccess[];
  isDownload?: boolean;
  /** File data of response */
  file?: ItemBase;
}
export interface FileInfoProps {
  name: string;
  type: FileType;
  parentId?: string;
  url?: string;
  thumbnailUrl?: string;
  metadata?: MetadataProps;
}

/**
 * Notebook object received from Notes Service
 */
export interface Notebook {
  /** Parent FileID of Notebook */
  fileId: string;
}

export interface LoggedInUserInterface {
  picture?: string;
  name?: string;
}

/**
 * Which users have access to a specific file/folder
 * @deprecated use FileShare instead.
 */
export interface SharedEvent {
  /** SharedEvent ID */
  _id: string;
  /** User ID of user with access to the file/folder */
  userId: string;
  /** Permission level of user */
  type: FileAccessType;
  /** Wass a child of the folder deleted */
  isSubDeleted: boolean;
  /** Was a child of the folder modified */
  isSubModified: boolean;
  /** If user only has access to a child and not the current folder */
  isRestricted?: boolean;
  /** User with access to the File/Folder */
  user: User;
}

/** Sorting function for FileResponse Arrays based on FileResponse.name */
const fileResponseNameSortFunc = localCompareFuncFactory("name");

export const authStorageKey = "notes.auth.storage.key";
export const authStorageKeyUser = "notes.auth.storage.user";

/**
 * Main store for Notes Upload UI
 */
export default class AppStore {
  constructor() {
    // 建议使用这种方式，自动识别类型，不需要再加前缀
    makeAutoObservable(this);
  }
  /** Logged in user token */
  userToken = "";
  // TODO: Eventually transition to permission based access
  /**
   * User roles.
   * For controlling which screens logged in user can view
   */
  userRole?: UserRole;
  /**
   * Currently displayed folder path
   * An Empty folderpath indicates that the current directory shown is the Dashboard
   */
  folderPaths: FileResponse[] = [];
  /** Files selected for upload */
  selectedFiles: FileProps[] = [];
  /**
   * ID of folder where the {@link selectedFiles} will be uploaded to
   *
   * Set before calling {@link uploadFile}
   */
  selectedFilesDestinationId: string = "";
  /** Current folders in current folder path */
  currentFolders: FileResponse[] = [];
  /** True if currently retrieving folder/file paths */
  currentFoldersLoading: boolean = false;
  /** Toast alert data */
  toast: AlertProps = {
    description: "",
    autoClose: true,
  };
  /** True if toast should be displayed */
  showToast: boolean = false;
  /** List of folders closest to the root */
  quickLinks: QuickLinks[] = [];
  /** Map of Notebook amounts for each file */
  fileNotebookCounts: Record<string, number> = {};
  /** Logged in user details */
  loggedInUser: LoggedInUserInterface = {};
  /** Folders/Files selected for moving */
  movingFolders: FileResponse[] = [];
  /** True when in the process of updating file/folder paths */
  isMoving: boolean = false;
  /** Currently dragging movingFolders to new folder  */
  isMoveDragging: boolean = false;
  /** Set to true to show New Folder input on directory */
  showCreateFolder: boolean = false;
  /** List of deleted files/folders */
  trashList: FileResponse[] = [];
  /** True when bulk selecting files */
  isSelecting: boolean = false;

  /**
   * Current files for display
   *
   * Also include {@link selectedFiles} if files have been added for upload
   * and if {@link selectedFilesDestinationId} is set to the current path in {@link folderPaths}
   */
  get displayFiles() {
    return this.selectedFiles.length &&
      last(this.folderPaths)?._id === this.selectedFilesDestinationId
      ? [...this.currentFolders, ...this.selectedFiles].sort(
          fileResponseNameSortFunc
        )
      : this.currentFolders;
  }

  /**
   * True if user is of role ACADEMIC_SUPPORT
   */
  get isAcademicSupport() {
    return this.userRole === UserRole.AcademicSupport;
  }

  /**
   * Routes available to logged in user
   */
  get availableRoutes() {
    return Object.values(ALL_ROUTES)
      .filter(
        (route: RouteItem) =>
          this.userRole && route.allowedRoles.includes(this.userRole)
      )
      .map((route: RouteItem, index: number) => ({
        ...route,
        route: index === 0 ? "/" : route.route,
      }));
  }

  /** IDs of items in movingFolders */
  get movingFoldersIds() {
    return this.movingFolders.map((file) => file._id);
  }

  /*
   * Function for setting logged in user details to session storage
   */
  setLoggedInUser = (idToken?: IdToken) => {
    sessionStorage.setItem(
      authStorageKeyUser,
      JSON.stringify({
        picture: idToken?.picture,
        name: idToken?.name,
      })
    );
  };

  /**
   * Set app user role
   * @param userRole
   */
  setUserRole = (userRole: UserRole) => {
    this.userRole = userRole;
  };

  /**
   * Clear user role on logout
   */
  clearUserRole = () => {
    this.userRole = undefined;
  };

  /*
   * Clean up for when users logout
   */
  doPreLogout = () => {
    sessionStorage.removeItem(authStorageKey);
    sessionStorage.removeItem(authStorageKeyUser);
    this.clearUserRole();
  };

  /*
   * Setting user data after user is authenticated
   */
  doPostLogin = () => {
    this.userToken = this.getUserToken();
    const userString = sessionStorage.getItem(authStorageKeyUser);
    this.loggedInUser = userString ? JSON.parse(userString) : {};
  };

  /**
   * @returns All users that are available for file/folder sharing
   */
  getAllUsers = async () => {
    const response = await factory.get(`${AppConfig.baseUrl}/users`, {
      params: {
        roles: UserRole.Teacher,
      },
    });

    return response as unknown as ResponseData<ModelUser[]>;
  };

  /**
   * @param id file/folder ID
   * @returns list of all users with access to the given file/folder
   */
  getItemSharedUsers = async (id: string) =>
    (await factory.get(
      AppConfig.baseUrl + `/v2/files/${id}`
    )) as unknown as ResponseData<FileResponse | undefined>;

  /**
   * Update user access to a specific file/folder
   * @param itemId
   * @param userId
   * @param type
   */
  setItemSharedPermission = async (
    itemId: string,
    userId: string,
    type: FileAccessType
  ) => {
    await factory.put(`${AppConfig.baseUrl}/v2/files/${itemId}/access/modify`, {
      userId,
      newAccessType: type,
    });
  };

  /**
   * Remove user access to a specific file/folder
   * @param itemId
   * @param userId
   */
  removeUserPermissionFromItem = async (itemId: string, userId: string) => {
    await factory.put(`${AppConfig.baseUrl}/v2/files/${itemId}/access/remove`, {
      userId: userId,
    });
  };

  /**
   * Add user access to a specific file/folder
   * @param itemId
   * @param access
   */
  shareToUsers = async (
    itemId: string,
    shareType: FileAccessType,
    targetUserIds: string[]
  ) => {
    await factory.put(`${AppConfig.baseUrl}/v2/files/${itemId}/access/grant`, {
      users: targetUserIds.map((userId) => ({ userId, type: shareType })),
    });
  };

  /**
   * Folders/Files selected for moving
   * @param items
   */
  setMovingFolders = (items: FileResponse | FileResponse[]) => {
    this.movingFolders = Array.isArray(items) ? [...items] : [items];
  };

  /**
   * Append items to movingFolders
   * @param items
   */
  appendMovingFolders = (items: FileResponse | FileResponse[]) => {
    this.movingFolders = [
      ...this.movingFolders,
      ...(Array.isArray(items) ? items : [items]),
    ];
  };

  /**
   * Remove file/folder from movingFolders
   * @param item
   */
  removeMovingFolders = (item: FileResponse) => {
    this.movingFolders = [...pull(this.movingFolders, item)];
  };

  /**
   * Select multiple items according to most recently selected item for moving (movingFiles)
   * @param item
   * @param currentList
   */
  shiftSelectMovingFolders = (
    item: FileResponse,
    currentList: FileResponse[]
  ) => {
    if (this.movingFolders.length) {
      const lastSelected = last(this.movingFolders) as FileResponse;

      if (lastSelected._id === item._id) {
        this.movingFolders = [];
      } else {
        const lastSelectedIndex = currentList.findIndex(
          (file) => file._id === lastSelected._id
        );
        const currentSelectedIndex = currentList.findIndex(
          (file) => file._id === item._id
        );

        let smallerIndex;
        let biggerIndex;

        if (lastSelectedIndex < currentSelectedIndex) {
          smallerIndex = lastSelectedIndex;
          biggerIndex = currentSelectedIndex;
        } else {
          smallerIndex = currentSelectedIndex;
          biggerIndex = lastSelectedIndex;
        }

        this.movingFolders = currentList.slice(smallerIndex, biggerIndex + 1);
      }
    } else {
      this.movingFolders = [item];
      this.isSelecting = true;
    }
  };

  /**
   * Update backend to update file/folder locations
   * @param destinationId
   * @param singleFolderID Provided if not moving in bulk
   */
  moveFilesFolders = async (
    destinationId?: string,
    singleFolderID?: string
  ) => {
    // Set `/files/move/bulk` parameters
    const params = {
      ids: singleFolderID ? [singleFolderID] : this.movingFoldersIds,
      ...(destinationId ? { destinationId } : {}),
    };

    try {
      this.isMoving = true;
      await factory.put(AppConfig.baseUrl + `/files/move/bulk`, params);
    } catch (error) {
      const { code, message } = error as { code: string; message: string };
      if (code === "ALREADY_EXISTED") {
        this.setToast({
          description: "Name already exists in folder, please try again.",
          type: "danger",
        });
      } else {
        this.setToast({
          description: "Error: " + message,
          type: "danger",
        });
      }
    } finally {
      await this.initActions();
    }
  };

  /**
   * Set isSelecting property
   * @param isSelecting
   */
  setIsSelecting = (isSelecting = true) => {
    this.isSelecting = isSelecting;
  };

  /**
   * Set isMoveDragging property
   */
  setMoveDragging = (isDrag = true) => {
    this.isMoveDragging = isDrag;
  };

  /**
   * Set files for upload
   * @param flow
   */
  setSelectedFiles = (flow: FileProps[]) => {
    const items = flow.filter((i) => !isEmpty(i.name));
    this.selectedFiles = [...this.selectedFiles, ...items];
  };

  /**
   * Set ID of upload location/directory
   * @param destinationID
   */
  setSelectedFilesDestinationId = (destinationID?: string) => {
    this.selectedFilesDestinationId =
      destinationID ?? last(this.folderPaths)?._id ?? "";
  };

  /**
   * Retrieve folders based on last item in the current folderPaths
   */
  getFoldersBaseOnParentId = async () => {
    let parentId = last(this.folderPaths)?._id ?? "";
    try {
      this.currentFoldersLoading = true;
      this.currentFolders = await this.getFoldersBasedOnId(parentId);
    } finally {
      this.currentFoldersLoading = false;
    }
  };

  /**
   * Retrieve folders based on provided ID
   */
  getFoldersBasedOnId = async (id?: string) => {
    const url = isEmpty(id)
      ? `/files?pageNumber=0&pageSize=0`
      : `/files?parentId=${id}&pageNumber=0&pageSize=0`;
    let result = await factory.get(AppConfig.baseUrl + url);
    const allItems = result.data.results;
    return isEmpty(allItems) ? [] : allItems.sort(fileResponseNameSortFunc);
  };

  /**
   * Initialize QuickLinks
   */
  initQuickLinks = async () => {
    const quickLinksFilter = (data: FileResponse) =>
      data.type === FileType.Folder;

    // Get all folders 1 level from the root (and their direct child folders) and sort according to name
    this.quickLinks = await Promise.all(
      (
        await factory.get(`${AppConfig.baseUrl}/v2/files`, {
          params: {
            pageInfo: {
              pageSize: 0,
              pageNumber: 0,
            },
            sortInfo: {
              sortBy: 'name',
              sortDirection: 'ASC',
            },
          },
        })
      ).data.results
        .filter(quickLinksFilter)
        .sort(fileResponseNameSortFunc)
        .map(async (data: FileResponse) => {
          let children = (
            await factory.get(`${AppConfig.baseUrl}/v2/files`, {
              params: {
                filterInfo: {
                  parentId: data.parentId,
                },
                pageInfo: {
                  pageSize: 0,
                  pageNumber: 0,
                },
                sortInfo: {
                  sortBy: 'name',
                  sortDirection: 'ASC',
                },
              },
            })
          ).data.results
            .filter(quickLinksFilter)
            .sort(fileResponseNameSortFunc)
            .map((childData: FileResponse) => ({
              _id: childData._id,
              name: childData.name,
              fileResponse: childData,
            }));

          return {
            _id: data._id,
            name: data.name,
            fileResponse: data,
            children,
          };
        })
    );
  };

  /**
   * Initialize map of file to notebook counts
   */
  initNotebooks = async () => {
    this.fileNotebookCounts = (
      await factory.get(AppConfig.baseUrl + "/notebooks/all")
    ).data?.results?.reduce(
      (agg: Record<string, number>, item: Notebook) => ({
        ...agg,
        [item.fileId]: (agg[item.fileId] ?? 0) + 1,
      }),
      {}
    );
  };

  /**
   * Initialize list of deleted files/folders
   */
  initTrashList = async () => {
    this.trashList = (
      await factory.get(AppConfig.baseUrl + "/trash")
    ).data.results.map((data: FileResponse) => ({
      ...data?.file,
      _id: data._id,
    }));
  };

  /**
   * Set curently displayed folder paths to new path
   * @param param0
   */
  changeFolderPaths = ({ paths }: { paths: FileResponse[] }) => {
    this.folderPaths = paths;
  };

  /**
   * Set currently displayed folder paths back to root/home
   */
  folderPathGoRoot = () => {
    this.folderPaths = [];
  };

  /**
   * Sets current folderPaths
   * @param items
   */
  setFolderPaths = (items: FileResponse[]) => {
    this.folderPaths = uniqBy([...items], "_id");
  };

  /**
   * Append to the current folderPaths
   */
  appendFolderPaths = ({ folderPath }: { folderPath: FileResponse }) => {
    this.setFolderPaths([...this.folderPaths, folderPath]);
  };

  /**
   * Remove file from files to be uploaded
   * @param i
   */
  removeSelectedFiles = (i: FileProps) => {
    runInAction(() => {
      this.selectedFilesDestinationId = "";
      this.selectedFiles = [...pull(this.selectedFiles, i)];
    });
  };

  /*
   * Get user token set in session storage
   */
  getUserToken = () => {
    return sessionStorage.getItem(authStorageKey) ?? "";
  };

  /*
   * Set user token set in session storage
   */
  setUserToken = (token: string) => {
    sessionStorage.setItem(authStorageKey, token);
  };

  /**
   * Set current toast data
   * @param toast
   */
  setToast = (toast: AlertProps) => {
    this.toast = toast;
    this.showToast = true;
  };

  /**
   * Use to hide/show toast with current toast data
   */
  setToastShow = (show: boolean) => {
    this.showToast = show;
  };

  /**
   * Upload file to Archus Notes Service
   */
  uploadFile = async () => {
    for (let index = 0; index < this.selectedFiles.length; index++) {
      const fileInfo = this.selectedFiles[index];
      let data = new FormData();
      data.append("type", "File");
      data.append("thumbnail", "false");
      //@ts-ignore
      data.append("file", fileInfo);
      try {
        let result1 = await factory.post(
          AppConfig.baseUrl + "/portal/upload",
          data,
          {
            onUploadProgress: (progressEvent) => {},
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        );

        const file: FileInfoProps = {
          name: fileInfo?.name,
          type: FileType.File,
          fileCategory: FileCategories.Documents,
          parentId: this.selectedFilesDestinationId,
          url: result1?.data.url,
          thumbnailUrl: "",
          metadata: {
            contentType: fileInfo.type,
            //@ts-ignore
            contentModifiedAt: fileInfo.lastModified,
            size: fileInfo.size,
            contentHash: result1.data.contentHash,
          },
        };
        await factory.post(`${AppConfig.baseUrl}/v2/files`, file);
        this.toast = {
          description: "Files successfully uploaded to Archus Notes",
          type: "success",
        };
      } catch (error) {
        console.error("Upload Failed: ", error);
        this.toast = {
          description: `‘${fileInfo.name}’ upload failed`,
          type: "warning",
        };
      }
    }
    this.showToast = true;

    await this.initActions();
  };

  /**
   * Reset folderpaths to immediate previous path
   */
  folderPathGoBack = () => {
    const items = [...this.folderPaths];
    const newItems = dropRight(items);
    this.folderPaths = newItems;
  };

  /**
   * Reset folderpaths to a previous path
   * @param file
   */
  folderPathGoBackTo = (file: BreadCrumbDataInterface) => {
    const items = [...this.folderPaths];
    const index = findIndex(items, (item) => item._id === file._id);
    const newItems = slice(items, 0, index + 1);
    this.folderPaths = newItems;
  };

  /**
   * Action to start create new folder process
   * Set to false to hide create new folder input
   * @param doStart
   */
  startCreateNewFolder = (doStart = true) => {
    this.showCreateFolder = doStart;
  };

  /**
   * Creates a new folder in Archus Notes Service
   * @param folderName New folder name
   */
  createNewFolder = async (folderName: string) => {
    await this.handleItemAction(() =>
      factory.post(`${AppConfig.baseUrl}/v2/files`, {
        name: folderName.trim(),
        type: FileType.Folder,
        parentId: last(this.folderPaths)?._id ?? "",
      })
    );
  };

  /**
   * Updates the file/folder name of a folder in Archus Notes Service
   * @param id
   * @param name
   * @param onFinish Function to run on finish of rename
   */
  renameItem = async (id: string, name: string, onFinish?: () => void) => {
    await this.handleItemAction(
      () => factory.put(AppConfig.baseUrl + `/files/${id}`, { id, name }),
      onFinish
    );
  };

  /**
   * Make copy of existing item
   * @param item
   */
  cloneItem = async (item: FileResponse) => {
    await this.handleItemAction(() =>
      factory.post(AppConfig.baseUrl + `/files/${item._id}/clone`, {
        id: item._id,
        name: `Copy of ${item.name}`,
      })
    );
  };

  /**
   * Delete existing items
   * @param item
   */
  deleteItems = async (id: string | string[]) => {
    await this.handleItemAction(() =>
      factory.delete(
        AppConfig.baseUrl +
          `/files/bulk?ids=${Array.isArray(id) ? id.toString() : id}`
      )
    );
  };

  /**
   * Restore items in trash
   * @param id
   */
  restoreItems = async (id: string | string[]) => {
    await this.handleItemAction(() =>
      factory.put(AppConfig.baseUrl + "/trash/restore/bulk", {
        ids: Array.isArray(id) ? id : [id],
      })
    );
  };

  /**
   * Permanently delete item in trash
   * @param id
   */
  deleteItemPermanently = async (id: string | string[]) => {
    await this.handleItemAction(() =>
      factory.delete(
        AppConfig.baseUrl +
          `/trash/bulk?ids=${Array.isArray(id) ? id.toString() : id}`
      )
    );
  };

  /**
   * Empty all items in trash
   */
  emptyTrash = async () => {
    await this.handleItemAction(() =>
      factory.post(AppConfig.baseUrl + `/trash/empty`)
    );
  };

  /**
   * Update/Create file/folder handler
   * @param fileChangeFunc
   * @param onFinish Function to run on finish of file update/create
   */
  handleItemAction = async (
    fileActionFunc: () => Promise<void>,
    onFinish?: () => void
  ) => {
    this.setToastShow(false);
    try {
      await fileActionFunc();
    } catch (error) {
      let errCode, errMessage;
      if (typeof error === "string") {
        errMessage = error;
        errCode = "";
      } else {
        const errorAsObj = error as { code: string; message: string };
        errCode = errorAsObj.code;
        errMessage = errorAsObj.message;
      }
      this.setToast({
        description:
          errCode === "ALREADY_EXISTED" ? "Name is taken" : errMessage,
        type: "danger",
      });
    } finally {
      if (onFinish) {
        onFinish();
      }
      await this.initActions();
    }
  };

  /**
   * Reset all actions and update all files
   */
  initActions = async () => {
    // Unselect folders selected for moving
    this.setMovingFolders([]);
    this.isMoving = false;
    this.isSelecting = false;

    // Hide Create new folder form
    this.startCreateNewFolder(false);

    // Update current folders
    await this.getFoldersBaseOnParentId();
    this.selectedFiles = [];
    this.selectedFilesDestinationId = "";

    // Update quicklinks
    await this.initQuickLinks();

    // Update list of deleted files
    await this.initTrashList();
  };
}
export const appStore = new AppStore();
