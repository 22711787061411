import { Dropdown, Checkbox } from "@thepiquelab/archus-components-web";
import { observer } from "mobx-react";
import { useMemo, useState } from "react";
import { MenuProps } from "antd";
import DirectoryNewFolder from "./DirectoryNewFolder";
import { DivProps, FileTypes, ToggleFuncType } from "../../utils";
import { FileProps, FileResponse, FileType } from "../../mobx/app.store";
import { colors } from "../../constant";
import useDragActions from "../../hooks/useDragActions";
import { useAppStore } from "../../mobx";
import DirectoryBaseListItem from "./DirectoryBaseListItem";

/**
 * Type for Dropdown
 */
export type DropDownMenuFuncType = ( 
  /** Item Data received from DirectoryListItemRow component */
  item: FileResponse | FileProps,
  /** Function for setting isOptionMenuOpen in DirectoryListItemRow component */
  setOptionMenuOpen: ToggleFuncType,
  /** True if bulk select is active */
  isSelecting: boolean,
  /** Function for setting DirectoryListItemRow component to for edit */
  setIsEdit?: ToggleFuncType,
) => MenuProps;


/** List item for DirectoryList */
interface ListItemProps extends DivProps {
  /** List item data */
  item: FileTypes;
  /** Disable clickability of item */
  disable?: boolean;
  /** Function on click of ListItem row */
  onItemClick?: (item: FileResponse) => void;
  /** Dropdown menu props getter for DirectoryListItem */
  getDropdownMenu: DropDownMenuFuncType;
  /** True to hide number of notebooks */
  hideNotebooks?: boolean;
  /** Action on shift select of items */
  onShiftSelect: () => void;
}

/**
 * Check if passed File Item is of a FileResponse type
 * @param item 
 */
export const isForUpload = (item: FileTypes): item is FileProps => {
  return !(item as FileResponse)._id;
};

/**
 * List item for DirectoryList
 * @param props 
 */
const DirectoryListItem: React.FC<ListItemProps> = (props) => {
  const {
    item, 
    disable,
    onItemClick,
    getDropdownMenu,
    onShiftSelect,
    hideNotebooks,
    ...divProps
  } = props;

  const {
    removeMovingFolders,
    appendMovingFolders,
    movingFolders,
    fileNotebookCounts,
    isSelecting,
  } = useAppStore();

  /** True if item data is for folders */
  let isFolder = useMemo(() => item.type === FileType.Folder, [item]);

  /** True if item options dropdown is open */
  const [isOptionMenuOpen, setOptionMenuOpen] = useState(false);

  /**
   * True if DirectoryListItem had been selected for moving
   */
  const isActive = useMemo(
    () => 
      !isForUpload(item) && movingFolders.some(move => move._id === item._id),
    [movingFolders, item]
  );

  const {
    isHovering,
    dragActionRootProps,
    getInputProps,
  } = useDragActions(
      isActive,
      !isActive && !isForUpload(item) && isFolder,
      false,
      isForUpload(item) ? undefined : item._id
    );

  /**
   * If DirectoryListItem is shift selected, handle adding/removing to/from movingFiles
   * Otherwise, if DirectoryListItem is a folder, change directories
   * @param event
   */
  const onClick = (event:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isForUpload(item) && !disable){
      if (event.shiftKey) {
        if (isActive) {
          removeMovingFolders(item);
        } else if (onShiftSelect) {
          onShiftSelect();
        }
      } else {
        if (isActive) {
          removeMovingFolders(item);
        } else if (isSelecting) {
          appendMovingFolders(item);
        } else if (onItemClick && isFolder) {
          onItemClick(item);
        } 
      }
    }
  }

  /**
   * Action on right click of folder/file
   * @param event 
   */
  const onRightClick = (event:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
  };

  /** List Item styles that change when item is a folder or a file */
  const listItemStyles = useMemo(() => ({
    ...( (isHovering || isOptionMenuOpen) && !isActive ? { backgroundColor: colors.primary.navy100 }: {}),
    ...( isActive? { backgroundColor: colors.primary.blue100 }: {} ),
  }), [isHovering, isActive, isOptionMenuOpen]);

  /** Set to true if file name is for edit  */
  const [isEdit, setIsEdit] = useState(false);

  /**
   * Number of notebooks if item is a file
   */
  const notebookNumber = useMemo(() => {
    return (
      !isForUpload(item) && fileNotebookCounts && fileNotebookCounts[item._id]
      ) ? fileNotebookCounts[item._id] : 0
  }, [fileNotebookCounts, item]);

  return (
    <Dropdown
      disabled={!!movingFolders.length && isForUpload(item)}
      trigger={["contextMenu"]}
      menu={getDropdownMenu(item, setOptionMenuOpen, isSelecting, setIsEdit)}
      onOpenChange={isOpen => setOptionMenuOpen(isOpen)}
      overlayStyle={{ minWidth: '220px' }}
    >
      {
        isEdit ? 
          <DirectoryNewFolder 
            id={isForUpload(item) ? '': item._id} 
            originalName={item.name} 
            isFile={!isFolder} 
            doAfterRename={() => setIsEdit(false)}
          />
        :
          <DirectoryBaseListItem
            { ...divProps }
            { ...dragActionRootProps }
            key={isForUpload(item) ? item.lastModifiedDate : item._id}
            onClick={onClick}
            onContextMenu={onRightClick}
            style={listItemStyles}
            className="cursor-pointer"
            isFile={!isFolder}
            itemTitle={item.name}
            notebookNumber={ (hideNotebooks || isFolder || isForUpload(item)) ? undefined : `${notebookNumber}` }
          >
            { 
              isSelecting && !isForUpload(item) && 
                <Checkbox checked={isActive} /> 
            }
            { isFolder && <input { ...getInputProps() } /> }
          </DirectoryBaseListItem>
      }
    </Dropdown>
  );
}

export default observer(DirectoryListItem);
