import { observer } from "mobx-react";
import { DivProps } from "../../utils";

/** Directory content container */
const DirectoryContent:React.FC<DivProps> = ({children, ...props}) => (
  <div className="flex flex-col bg-white rounded overflow-clip shadow m-2 flex-grow"
    style={{
      // 89px = 73px header height + 16px padding
      maxHeight: "calc(100vh - 89px)"
    }}
    {...props}
  >
    {children}
  </div>
);


export default observer(DirectoryContent);
