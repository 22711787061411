import { appStore } from "./app.store";
import CoreStore from "./core.store";

export const coreStore = new CoreStore(appStore);

const mainStore = {
  appStore,
  coreStore
};

export default mainStore;
