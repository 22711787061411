import { observer } from "mobx-react";
import { useAppStore } from "../../mobx";
import { FileProps, FileResponse } from "../../mobx/app.store";
import DirectoryNewFolder from "../Directory/DirectoryNewFolder";
import DirectoryListItem, { DropDownMenuFuncType, isForUpload } from "../Directory/DirectoryListItem";
import { ToggleFuncType } from "../../utils";
import { useState } from "react";
import ShareUserModal from "../Modals/ShareUserModal";
import MoveModal from "../Modals/MoveModal/MoveModal";

/** List of Files/Folders in Notes */
const HomeList = () => {
  const {
    displayFiles,
    currentFoldersLoading,
    showCreateFolder,
    movingFoldersIds,
    appendFolderPaths,
    cloneItem,
    deleteItems,
    shiftSelectMovingFolders,
    currentFolders,
    folderPaths
  } = useAppStore();

  /** Set folder paths on click of DirectoryListItem */
  const doOnClick = (item: FileResponse) => {
    appendFolderPaths({ folderPath: item });
  };

  /** Item to be shared in ShareUserModal */
  const [shareUserItem, setShareUserItem] = useState<FileResponse>();
  
  /** True if UserShareModal is open */
  const [openUserShareModal, setOpenUserShareModal] = useState(false);
  /** True if MoveModal is open */
  const [openMoveModal, setOpenMoveModal] = useState(false);
  
  /** 
   * Dropdown menu props for DirectoryListItem 
   * Menu items will change depending on whether bulk select mode is active or not
   */
  const getDropdownMenu: DropDownMenuFuncType = (
    item: FileResponse | FileProps,
    setOptionMenuOpen: ToggleFuncType,
    isSelecting: boolean,
    setIsEdit?: ToggleFuncType,
  ) => (
    { 
      items: [
        ...(
          isSelecting ? [
              {
                key: 'move',
                label: "Move",
                onClick: () => {
                  setOpenMoveModal(true);
                }
              },
            ]
            :[
              {
                key: 'rename',
                label: "Rename",
                onClick: () => {
                  if (setIsEdit){
                    setIsEdit(true);
                  }
                  setOptionMenuOpen(false);
                }
              },
              {
                key: 'copy',
                label: "Make a Copy",
                onClick: () => {
                  if (!isForUpload(item)){
                    cloneItem(item);
                  }
                  setOptionMenuOpen(false);
                }
              },
              {
                key: 'share',
                label: "Share",
                onClick: () => {
                  if (!isForUpload(item)){
                    setShareUserItem(item);
                  }
                  setOpenUserShareModal(true);
                  setOptionMenuOpen(false);
                }
              }
            ]
        ),
        {
          key: 'delete',
          label: "Delete",
          onClick: () => {
            if (isSelecting) {
              deleteItems(movingFoldersIds);
            } else if (!isForUpload(item)){
              deleteItems(item._id);
            }
            setOptionMenuOpen(false);
          }
        }
      ]
    }
  );

  return (
    <div className="flex-grow h-100 overflow-scroll" onClick={(e) => e.stopPropagation()}>
      {
        showCreateFolder && <DirectoryNewFolder />
      }
      { 
        !!displayFiles.length &&  
          displayFiles.map((item, index) => <DirectoryListItem 
              key={item.name + index}
              getDropdownMenu={getDropdownMenu} 
              onItemClick={doOnClick} 
              item={item} 
              disable={currentFoldersLoading} 
              onShiftSelect={() => !isForUpload(item) && shiftSelectMovingFolders(item, currentFolders)}
            />
          )
      }
      {
        openUserShareModal && <ShareUserModal item={shareUserItem} onClose={() => setOpenUserShareModal(false)} />
      }
      {
        openMoveModal && <MoveModal onClose={() => setOpenMoveModal(false)} initialPaths={[...folderPaths]} />
      }
    </div>
  );
}

export default observer(HomeList);
