import { observer } from "mobx-react";
import DirectoryEmpty from "../Directory/DirectoryEmpty";
import { EmptyFolder } from "../Icons";
import { FileRejection, useDropzone } from "react-dropzone";
import { useState } from "react";
import UploadModal from "./UploadModal";
import { useAppStore, useCoreStore } from "../../mobx";
import {
  TestSubmissionTest,
  createTestFileName,
  UneditableTestSubmissionStatus,
} from "../../utils";
import DirectoryBaseListItem from "../Directory/DirectoryBaseListItem";
import TestStatusDropdown from "./TestStatusDropdown";
import { Typography } from "@thepiquelab/archus-components-web";

/** Component for displaying list of Students */
const StudentsList = () => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState<any[]>();
  const { currentTestSubmissions, updateTestSubmissionStatus } = useCoreStore();
  const { setToast } = useAppStore();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles: any[], fileRejections: FileRejection[]) => {
      if (fileRejections.some((file) => file.errors && file.errors)) {
        // Show error toast if file is not accepted
        setToast({
          description: "Files should only be in PDF format.",
          type: "danger",
        });
      } else {
        setFilesToUpload(acceptedFiles);
        setShowUploadModal(true);
      }
    },
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  /**
   * Builds the test scores string from the test submission data
   * @example "MCQ: 21/30 | OEQ: 59/70 | Total: 80/100"
   */
  const buildTestScoresStr = (test: TestSubmissionTest) => {
    if (!test) return "";

    /** Maps the test section scores into a string, then joins all with " | " */
    const sectionsStr = test?.sections
      .map((section) => `${section.name}: ${section.score}/${section.maxScore}`)
      .join(" | ");

    /** Add the total score to the response. */
    return `${sectionsStr} | Total: ${test?.totalScore}/${test?.maxTotalScore}`;
  };

  return (
    <div className="flex flex-col flex-grow">
      <div className="flex-grow h-100 overflow-scroll" {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <DirectoryEmpty
            EmptyIcon={EmptyFolder}
            title="To add tests, drag and drop them here"
          />
        ) : (
          <>
            {currentTestSubmissions && currentTestSubmissions.length ? (
              currentTestSubmissions.map((submission) => (
                <DirectoryBaseListItem
                  itemTitle={createTestFileName(
                    submission.examinee.fullName,
                    submission.worksheet.test.name,
                    submission.class.shortName
                  )}
                  key={submission.id}
                  isFile
                  onClick={(e) => e.stopPropagation()}
                  sideComponent={
                    <div className="flex gap-10 items-center">
                      <Typography.Text>{submission.id}</Typography.Text>
                      <Typography.Text className="!text-primary-navyLight font-normal text-sm">
                        {submission.status ===
                        UneditableTestSubmissionStatus.Marked
                          ? buildTestScoresStr(submission.worksheet.test)
                          : ""}
                      </Typography.Text>
                      <TestStatusDropdown
                        value={submission.status}
                        onClick={(status) =>
                          updateTestSubmissionStatus(submission.id, status)
                        }
                      />
                    </div>
                  }
                ></DirectoryBaseListItem>
              ))
            ) : (
              <DirectoryEmpty
                EmptyIcon={EmptyFolder}
                subtitle="To add tests, drag and drop them here"
                title="No files have been added yet"
              />
            )}
          </>
        )}
      </div>
      {showUploadModal && filesToUpload && (
        <UploadModal
          filesToUpload={filesToUpload}
          onClose={() => setShowUploadModal(false)}
        />
      )}
    </div>
  );
};

export default observer(StudentsList);
