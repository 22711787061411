import { ErrorScreen } from "@thepiquelab/archus-components-web";
import InsufficientCredentials from "../../assets/images/InsufficientCredentials.svg";
import { observer } from "mobx-react";
import useAuthentication from "../../hooks/useAuthentication";

/**
 * Error Page for when logged in user is not allowed access to Notes Upload
 */
const NoAccess = () => {
  const { doLogout } = useAuthentication(true);

  return (
  <ErrorScreen
    logo="the-pi-lab"
    title="Error"
    description="We are sorry, but you do not have access to this page."
    primaryButtonLabel="Return to Login"
    onPressPrimaryButton={doLogout}
    illustration={InsufficientCredentials}
  />
)};

export default observer(NoAccess);
