
import React from 'react';
import { Dropdown } from "@thepiquelab/archus-components-web";
import { PoweroffOutlined, CaretDownOutlined } from "@ant-design/icons";
import { observer } from 'mobx-react';
import useAuthentication from '../hooks/useAuthentication';

/**
 * Header dropdown
 */
const NavbarDropdown = () => {
  const { doLogout } = useAuthentication();
  
  return (
    <Dropdown
      menu={
        {items: [
          {
            key: 'logout',
            label: (
              <div className="flex flex-row items-center gap-4" onClick={doLogout}>
                <PoweroffOutlined className="text-primary-navy h-3 w-3" />
                <div className="text-primary-navy">
                  Log out
                </div>
              </div>
            ),
          }
        ]}
      }
    >
      <CaretDownOutlined className="text-primary-navy cursor-pointer" />
    </Dropdown>
  );
};

export default observer(NavbarDropdown);
