import React, { useEffect, useState } from "react";
import { useAppStore, useCoreStore } from "../mobx";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";
import { Auth0Config } from "../config";
import { isEmpty, replace } from "lodash";
import moment from "moment";
import jwtDecode from "jwt-decode";
import actions from "../actions";
import { AllowedRoles, UserRole } from "../utils";

/**
 * Hook for the authentication processes
 */
const useAuthentication = (isError = false) => {
  const location = useLocation();
  const {
    getUserToken,
    setUserToken,
    setLoggedInUser,
    doPostLogin,
    doPreLogout,
    setUserRole,
  } = useAppStore();
  const {
    loginWithRedirect,
    logout,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  // Set to true when user redirected from Auth0 login screen
  const fromLogin = searchParams.get("fromLogin");
  // Set to true when user redirected from Auth0 logout
  const fromLogout = searchParams.get("fromLogout");

  useEffect(() => {
    //@ts-ignore
    if (!isError && !window.__POWERED_BY_QIANKUN__ && !fromLogin) {
      const token = getUserToken();
      if (
        fromLogout ||
        isEmpty(token) ||
        moment(
          (jwtDecode(token ?? "") as { exp: number }).exp * 1000
        ).isBefore()
      ) {
        loginWithRedirect({
          authorizationParams: {
            redirect_uri: window.location.origin + "?fromLogin=true",
          },
        });
      }
    }
  }, []);

  const getToken = async () => {
    setUserToken(await getAccessTokenSilently());
  };

  /** 
   * Returns the user role with the highest access.
   * 1. Owner
   * 2. Academic Support
   * 3. Teacher Supervisor
   * 4. Teacher
   */
  const getHighestUserRoleAccess = (userRoles: UserRole[]): UserRole => {
    if (userRoles.includes(UserRole.Owner)) {
      return UserRole.Owner;
    } else if (userRoles.includes(UserRole.AcademicSupport)) {
      return UserRole.AcademicSupport;
    } else if (userRoles.includes(UserRole.TeacherSupervisor)) {
      return UserRole.TeacherSupervisor;
    } else {
      return UserRole.Teacher;
    }
  };

  useEffect(() => {
    if (!isError && !isLoading) {
      if (isAuthenticated) {
        let navigateTo = "/";
        getIdTokenClaims()
          .then((claims) => {
            const userRoles =
              claims && claims[`${Auth0Config.coreAudience}/roles`]
                ? claims[`${Auth0Config.coreAudience}/roles`]
                : [];
            const isAllowed = AllowedRoles.some((role) =>
              userRoles.includes(role)
            );
            if (isAllowed) {
              // TODO: Eventually transition to permission based access
              const highestRoleAccess = getHighestUserRoleAccess(userRoles);
              setUserRole(highestRoleAccess);
              getToken();
              setLoggedInUser(claims);
              setLoggedIn(true);
            } else {
              navigateTo = "/error";
            }
          })
          .finally(() => {
            navigate(navigateTo);
          });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    actions.onGlobalStateChange((state: any) => {
      setUserToken(replace(state.token, "Bearer ", ""));
    }, true);
  }, [location]);

  useEffect(() => {
    if (!isError && isLoggedIn) {
      doPostLogin();
    }
  }, [isLoggedIn]);

  const doLogout = () => {
    doPreLogout();
    logout({
      logoutParams: { returnTo: window.location.origin + "?fromLogout=true" },
    });
  };

  return {
    isLoggedIn,
    doLogout,
  };
};

export default useAuthentication;
