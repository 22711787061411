import { Typography } from "@thepiquelab/archus-components-web";
import { FileResponse, FileType } from "../../../mobx/app.store";
import { observer } from "mobx-react";
import { useAppStore } from "../../../mobx";
import { FileIcon, FolderIcon } from "../../Icons";
import { EmptyFolderMoveModal } from "../../Icons/EmptyFolderMoveModal";

/**
 * MoveModalBody Properties
 */
interface MoveModalProps {
  /** Action to set current folder paths */
  setCurrentFolderPaths: (paths: FileResponse[]) => void;
  /** Folder Paths for breadcrumbs component */
  currentFolderPaths: FileResponse[];
  /** Directory data for Modal Body */
  currentFolderDirectory: FileResponse[];
}

/**
 * Content for Move Modal
 * @param props 
 */
const MoveModal:React.FC<MoveModalProps> = (props) => {
  const {
    setCurrentFolderPaths,
    currentFolderDirectory,
    currentFolderPaths
  } = props;

  const {
    movingFoldersIds
  } = useAppStore();

  return (
    <div className="flex flex-col">
      {
        currentFolderDirectory.length ?
          currentFolderDirectory.map(item => (
            <div className="flex gap-2 p-2 items-center cursor-pointer hover:bg-primary-navy100"
              onClick={() => {
                if (item.type === FileType.Folder && !movingFoldersIds.includes(item._id)) {
                  setCurrentFolderPaths([...currentFolderPaths, item])
                }
              }}
            >
              { item.type === FileType.Folder ? <FolderIcon size={20} /> : <FileIcon size={20} /> }
              <Typography.Text size="middle">
                {item.name}
              </Typography.Text>
            </div>
          ))
          : <div className="flex flex-col p-3 gap-8 items-center">
            <EmptyFolderMoveModal />
            <Typography.Text size="middle" className="!text-primary-navyLight !font-semibold">
              The folder is empty
            </Typography.Text>
          </div>
      }
    </div>
  ); 
};

export default observer(MoveModal);