import { observer } from "mobx-react";
import QuickLinksListItem from "../QuickLinksListItem";
import { QuickLink } from "../../../mobx/app.store";
import { QuickLinksItemProps } from "../QuickLinksList";

/**
 * Quick Links Trash Component
 */
const QuickLinksTrash:React.FC<QuickLinksItemProps> = (props) => {
  const { currentActive, setCurrentActive, checkRoute, ...linkData } = props;

  return <QuickLinksListItem 
    isNotDroppable
    isRoute
    link={linkData}  
    currentActive={currentActive} 
    onClick={(links:QuickLink[]) => {
      if (linkData.route) {
        checkRoute(linkData.route);
        setCurrentActive("");
      }
    }} 
  />;
}

export default observer(QuickLinksTrash);
