import { Typography } from "@thepiquelab/archus-components-web";
import { FileResponse } from "../../../mobx/app.store";
import { observer } from "mobx-react";
import { LeftCircleOutlined } from "@ant-design/icons";
import Breadcrumbs from "../../Breadcrumb/Breadcrumbs";
import { useMemo } from "react";
import { useAppStore } from "../../../mobx";

/**
 * MoveModalHeader Properties
 */
interface MoveModalProps {
  /** Action to set current folder paths */
  setCurrentFolderPaths: (paths: FileResponse[]) => void;
  /** Folder Paths for breadcrumbs component */
  currentFolderPaths: FileResponse[];
}

/**
 * Header details for Move Modal
 * @param props 
 */
const MoveModalHeader : React.FC<MoveModalProps> = (props) => {
  const {
    setCurrentFolderPaths,
    currentFolderPaths
  } = props;

  const {
    movingFolders
  } = useAppStore();

  /** Name to be displayed in Modal Header */
  const movingFileName = useMemo(
    () => movingFolders.length? `'${movingFolders[0].name}'` : 'files'
    , [movingFolders]
  );

  return (
    <div className="flex items-center gap-2">
      <LeftCircleOutlined 
        className="text-7 !text-primary-navyLight" 
        onClick={() => {
          console.log('unreasonable', currentFolderPaths.length)
          if (currentFolderPaths.length > 1) {
            setCurrentFolderPaths(currentFolderPaths.slice(0, currentFolderPaths.length - 1))
          } else {
            setCurrentFolderPaths([]);
          }
        }}
      />
      <div className="flex flex-col gap-1">
        <Typography.Text>
          Move { movingFileName }
        </Typography.Text>
        <Breadcrumbs
          level={4}
          paths={currentFolderPaths}
          onItemClick={
            path => {
              const items = [...currentFolderPaths];
              const index = items.findIndex(item => item._id === path._id);
              setCurrentFolderPaths(items.slice(0, index + 1));
            }
          }
          onRootClick={() => setCurrentFolderPaths([])}
        />
      </div>
    </div>
  ); 
};

export default observer(MoveModalHeader);