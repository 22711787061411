import { observer } from "mobx-react";
import React, { useMemo } from "react";
import { Checkbox, Typography } from "@thepiquelab/archus-components-web";
import { useAppStore } from "../../mobx";
import DirectoryRow from "./DirectoryRow";
import { FileResponse } from "../../mobx/app.store";
import { isForUpload } from "./DirectoryListItem";
import { FileTypes } from "../../utils";

/**
 * Bulk Select All Checkbox properties
 */
interface BulkSelectAllProps {
  /** List of item data */
  items: FileTypes[];
}

/** 
 * Checkbox for bulk select all
 */
const BulkSelectAll: React.FC<BulkSelectAllProps> = (props) => {
  const {
    items
  } = props;

  const {
    isSelecting,
    movingFolders,
    setMovingFolders,
  } = useAppStore();

  /**
   * True if all the items in the directory are selected
   */
  const isAllChecked = useMemo(() => movingFolders.length === items.length, [items, movingFolders]);

  return isSelecting ? (
    <DirectoryRow onClick={e => e.stopPropagation()}>
      <Checkbox 
        checked={isAllChecked} 
        onClick={
          () => {
            setMovingFolders(
              isAllChecked ? []: (items.filter(item => !isForUpload(item)) as FileResponse[])
            )
          }
        }
      />
      <Typography.Text size="large" className="font-semibold !text-primary-red">
        Select All
      </Typography.Text>
    </DirectoryRow>
  ): <></>;
};

export default observer(BulkSelectAll);
