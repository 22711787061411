import { Typography } from "@thepiquelab/archus-components-web";
import { EmptyPattern } from "../Icons/EmptyPattern";
import { IconSVGProps } from "../../utils";

/** General empty component properties */
interface DirectoryEmptyProps {
  /** Icon to display */
  EmptyIcon: React.FC<IconSVGProps>;
  /** Title text to display to user */
  title: string;
  /** Subtitle text to display under title */
  subtitle?: string;
  /** True to hide gray pattern */
  showIconOnly?: boolean;
}

/** Component for empty directory */
const DirectoryEmpty:React.FC<DirectoryEmptyProps> = (props) => {
  const {
    EmptyIcon,
    title,
    subtitle,
    showIconOnly
  } = props;
  
  return (
    <div className="flex flex-col h-full w-full items-center relative" onClick={(e) => e.stopPropagation()}>
      {
        showIconOnly 
          ? <></> 
          : <div className="absolute z-0 m-16">
            <EmptyPattern />
          </div>
      }
      <EmptyIcon className="mb-18 mt-36" />
      <Typography.Text className="text-lg font-semibold !mb-4 !text-primary-navyLight">
        {title}
      </Typography.Text>
      {
        subtitle && 
          <Typography.Text size="middle" className="!text-primary-navyLight">
            {subtitle}
          </Typography.Text>
      }
    </div>
  )
};

export default DirectoryEmpty;
