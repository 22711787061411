import { observer } from "mobx-react";
import { AcademicLevel } from "../../utils";
import DirectoryRow from "../Directory/DirectoryRow";
import { useCoreStore } from "../../mobx";
import { Typography } from "@thepiquelab/archus-components-web";
import AcademicLevelClassesListItem from "./AcademicLevelClassesListItem";

/** Academic level component properties */
interface AcademicLevelClassesListProps {
  /** Academic level data */
  academicLevel: AcademicLevel;
}

/** Academic Level Component with Core Classes List */
const AcademicLevelClassesList:React.FC<AcademicLevelClassesListProps> = (props) => {
  const { academicLevel } = props;

  const {
    academicLevelMapsWithClasses,
  } = useCoreStore();

  return (
  <div className="flex flex-col">
    <DirectoryRow>
      <Typography.Heading level={4} className="!font-normal">
        {academicLevel.name}
      </Typography.Heading>
    </DirectoryRow>
    {
       academicLevelMapsWithClasses[academicLevel.id]
         .map(
            coreClass => 
              <AcademicLevelClassesListItem 
                key={coreClass.id} 
                coreClass={coreClass} 
              />
         )
    }
  </div>
)};


export default observer(AcademicLevelClassesList);
