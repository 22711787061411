import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import QuickLinksListItemRow from "./QuickLinksListItemRow";
import { useLocation } from "react-router-dom";
import { QuickLink, QuickLinks } from "../../mobx/app.store";

/** Properties for list item for QuickLinks */
interface QuickLinksListItemProps {
    /** QuickLinksItem data */
    link: QuickLinks;
    /** Set to true if item is first */
    isRoute?: boolean;
    /** Current active item */
    currentActive?: string;
    /** Action on click of list item */
    onClick?: (links: QuickLink[]) => void;
    /** Set true to disable all drop actions */
    isNotDroppable?: boolean;
    /** True if quicklink is disabled */
    isDisabled?: boolean;
}

/** List item for QuickLinks */
const QuickLinksListItem: React.FC<QuickLinksListItemProps>  = (props) => {
  const { link, onClick, isRoute, currentActive, isNotDroppable, isDisabled } = props;
  const location = useLocation();

  /** True if item is a parent and the parent is open */
  const [ isOpen, setOpen ] = useState(false);

  /** True if link item has children */
  let hasChildren = useMemo(() => !!(link.children && link.children.length), [link]);

  /** 
   * True if item is the current actve item or a child is the current active item
   */
  const isActive = useMemo(() => (
    (isRoute && location.pathname === link.route) || (
        !isRoute && (
          currentActive === link._id 
          || (hasChildren && link.children?.some(child => currentActive === child._id))
        )
      )
  ), [currentActive, link, hasChildren, isRoute, location]);

  useEffect(() => {
    // Open parent quick link if child is open
    if ((hasChildren && link.children?.some(child => currentActive === child._id) && !isOpen)) {
      setOpen(true);
    }
  }, [currentActive, link, isOpen, hasChildren]);

  return (
    <div className="flex flex-col gap-2 w-full">
      <QuickLinksListItemRow 
        link={link}
        onClick={() => {
          if (onClick) {
            onClick([link]);
          }
          if (hasChildren && (!isActive || !isOpen)) {
            setOpen(!isOpen)
          }
        }}
        isActive={isActive}
        isOpen={isOpen}
        onOpen={setOpen}
        isRoute={isRoute}
        isNotDroppable={isNotDroppable}
        isDisabled={isDisabled}
      />
      {!isRoute && isOpen && hasChildren && (
        <div className="flex flex-col">
          {
            link.children?.map(child => (
              <QuickLinksListItemRow 
                link={child}
                onClick={() => {
                  if (onClick) {
                    onClick([link, child]);
                  }
                }}
                isActive={currentActive === child._id}
                isChild
                isDisabled={isDisabled}
              />
            ))
          }
        </div>
      )}
    </div>
  );
}

export default observer(QuickLinksListItem);
