import { observer } from "mobx-react";
import DirectoryBody from "../Directory/DirectoryBody";
import { useCoreStore } from "../../mobx";
import AcademicLevelClassesList from "./AcademicLevelClassesList";
import TestsSkeleton from "./TestsSkeleton";
import DirectoryEmpty from "../Directory/DirectoryEmpty";
import { EmptyFolder } from "../Icons";

/** Component for displaying list of Classes from Archus Core Service */
const ClassList = () => {
  const {
    availableAcademicLevels,
    coreClasses,
    academicLevelMapsWithClasses,
    loadingInitData,
  } = useCoreStore();

  return (
      <DirectoryBody isDragActionsDisabled
        showDirectoryList={loadingInitData || !!coreClasses.length}
        items={coreClasses}
        directoryEmpty={<DirectoryEmpty 
          EmptyIcon={EmptyFolder} 
          subtitle="All the classes will be displayed here"
          title="No classes have been added yet" />}
      >
        {
          <div className="flex-grow h-100 overflow-scroll" onClick={(e) => e.stopPropagation()}>
            { 
              !!coreClasses.length && availableAcademicLevels
                .filter(acadLevel => academicLevelMapsWithClasses[acadLevel.id] && !!academicLevelMapsWithClasses[acadLevel.id].length)
                .map(acadLevel => <AcademicLevelClassesList 
                    key={acadLevel.id} 
                    academicLevel={acadLevel} 
                  />) 
            }
            <TestsSkeleton />
          </div>
        }
      </DirectoryBody>
  );
}

export default observer(ClassList);