import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import { useAppStore } from "../../mobx";
import DirectoryListItem, { DropDownMenuFuncType, isForUpload } from "../Directory/DirectoryListItem";
import { FileProps, FileResponse } from "../../mobx/app.store";
import { ToggleFuncType } from "../../utils";
import useSimpleModal from "../../hooks/useSimpleModal";

/** List of deleted Files/Folders in Notes */
const TrashList = () => {
  const {
    trashList,
    isSelecting,
    movingFoldersIds,
    initTrashList,
    restoreItems,
    shiftSelectMovingFolders,
    deleteItemPermanently
  } = useAppStore();

  useEffect(() => {
    initTrashList();
  }, [initTrashList]);

  /** The item of interest in the delete warning modal */
  const [deleteItem, setDeleteItem] = useState<FileResponse | string[]>([]);
  /** Name of deleteItems depending on if items are for bulk delete or not */
  const deletedItemName = useMemo(
    () => Array.isArray(deleteItem) ? 'these files': `‘${deleteItem?.name}‘`
    , [deleteItem]
   );
   /** Pronouns to be used in delete warning modal */
  const deletedItemPronouns = useMemo(
    () => Array.isArray(deleteItem) ? ['them', 'their']: ['it', 'its']
    , [deleteItem]
  );

  const { doOpenModal, SimpleModal } = useSimpleModal({
    title: `Delete ${deletedItemName} Permanently?`,
    body: `Deleting ${deletedItemName} will remove ${deletedItemPronouns[0]} and ${deletedItemPronouns[1]}
      content completely and cannot be restored. Do you still want to proceed?`,
    primaryButton: {
      label: 'Confirm',
      onClick: () => (deleteItem && Array.isArray(deleteItem)) 
        ? deleteItemPermanently(deleteItem) 
        : deleteItemPermanently(deleteItem?._id),
    },
    secondaryButton: {
      label: 'Cancel'
    }
  });

  /** Dropdown menu props for DirectoryListItem */
  const getDropdownMenu: DropDownMenuFuncType = (
    item: FileResponse | FileProps,
    setOptionMenuOpen: ToggleFuncType,
  ) => (
    { 
      items: [
        {
          key: 'restore',
          label: "Restore",
          onClick: () => {
            if (isSelecting) {
              restoreItems(movingFoldersIds)
            } else if (!isForUpload(item)) {
              restoreItems(item._id)
            }
            setOptionMenuOpen(false);
          }
        },
        {
          key: 'delete',
          label: "Delete permanently",
          onClick: () => {
            if (isSelecting) {
              setDeleteItem(movingFoldersIds);
            } else if (!isForUpload(item)) {
              setDeleteItem(item);
            }
            doOpenModal(true);
            setOptionMenuOpen(false);
          }
        }
      ]
    }
  );

  return (
    <div className="flex-grow h-100 overflow-scroll" onClick={(e) => e.stopPropagation()}>
      {
        trashList.map((item) => <DirectoryListItem 
            item={item} 
            getDropdownMenu={getDropdownMenu} 
            onShiftSelect={() => shiftSelectMovingFolders(item, trashList)}
            hideNotebooks
          />
        )
      }
      <SimpleModal />
    </div>
  );
}

export default observer(TrashList);
