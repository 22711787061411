import React from "react";
import createApolloClient from "./ApolloClient";
import { ApolloProvider } from "@apollo/client";
import { enableLogging } from "mobx-logger";
import { Route, Routes } from "react-router-dom";

import WebHeader from "./components/WebHeader";
import Toast from "./components/Toast";
import NoAccess from "./pages/errors/NoAccess";
import { observer } from "mobx-react";
import { useAppStore } from "./mobx";
import Loading from "./components/Loading";
import useAuthentication from "./hooks/useAuthentication";
import LoggedIn from "./pages/LoggedIn";
import { colorsStyles } from "./constant";

enableLogging({ action: true, reaction: true });

function App() {
  const { userToken } = useAppStore();
  const { isLoggedIn } = useAuthentication();

  /**
   * Add color css variables to root element
   */
  Object.keys(colorsStyles).forEach(colorKey => {
    document.documentElement.style.setProperty(colorKey, colorsStyles[colorKey]);
  });

  return <div className="h-full w-full" style={colorsStyles}>
    {  isLoggedIn ? (
        <ApolloProvider client={createApolloClient(userToken)}>
          <div className="App relative overflow-hidden h-full bg-gray-200 flex flex-col">
            <WebHeader />
            <LoggedIn />
            <Toast/>
          </div>
        </ApolloProvider>
      ) : (
        <Routes>
          <Route path="/" element={<Loading/>}/>
          <Route path="/error" element={<NoAccess/>}/>
        </Routes>
      )
    }
  </div>;
}

export default observer(App);
