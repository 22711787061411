import { Typography } from "@thepiquelab/archus-components-web";
import { observer } from "mobx-react";
import QuickLinksList from "./QuickLinksList";
import { useEffect } from "react";
import { useAppStore } from "../../mobx";

/**
 * Quick links to folders closest to the root
 * @returns 
 */
const QuickLinks = () => {
  const { initQuickLinks, initNotebooks } = useAppStore();

  useEffect(() => {
    initQuickLinks();
    initNotebooks();
  }, [initQuickLinks, initNotebooks]);

  return (
    <div className="flex flex-col px-6 pt-10 bg-white basis-1/4 gap-8 border-r border-gray-500/[0.30]"
      style={{
        // 73px = header height 
        maxHeight: "calc(100vh - 73px)"
      }}
    >
      <Typography.Heading className="!font-normal !text-6">
        Quick Links
      </Typography.Heading>
     <QuickLinksList />
    </div>
  );
}

export default observer(QuickLinks);
