/**
 * Tabs for classes list
 */
export enum ClassTabs {
  MyClasses = "My Classes",
  OtherClasses="Other Classes"
}

/**
 * Type for ClassFilterInput
 */
export type ClassFilterInput = {
  /** IDs of academiclevels for filtering */
  academicLevelIds?: string[];
  /** Lesson Days for filtering */
  lessonDays?: string[];
  /** IDs of teachers for filtering */
  teacherIds?: string[];
};

/**
 * Type for Academic Level
 */
export interface AcademicLevel {
  /** Academic Level ID */
  id: string;
  /** Academic Level name */
  name: string;
  /** True if Academic Level is visible */
  isVisible?: boolean;
  /** True if Academic Level is archuved */
  isArchived?: boolean;
  /** True if Academic Level is deleted */
  isDeleted?: boolean;
}

/**
 * Type for Classes in Archus Core Service
 */
export interface CoreClass {
  /** Class ID */
  id: string;
  /** Academic Levels associated with Class */
  academicLevels: AcademicLevel[];
  /** Class course  */
  course:  Course;
  /** Class teacher */
  teacher: Teacher | null;
  /** Class status */
  status: string;
  /** Class type */
  type: string;
  /** Class code */
  classCode: string;
  /** True if Academic Level is visible */
  isVisible?: boolean;
  /** Data for Seats/Students in class */
  seats: Seat[];
  /** Core class name */
  name: string;
  /** Shorter version of class name*/
  shorthand: null | string;
  /** Default lesson start date */
  defaultLessonStart: Date | null;
  /** Default lesson end ate */
  defaultLessonEnd: Date | null;
  /** Date of first lesson */
  firstLessonDate: Date;
  /** Date of last lesson */
  lastLessonDate: Date;
  /** The yearly lesson plan of the teachers for a course. */
  lessonPlan?: LessonPlan;
  /** AttendanceReport use populate graphql field. */
  lessons?: Lesson[];
}

/**
 * Type for Course data
 */
export interface Course {
  /** Course ID */
  id: string;
  /** Course name */
  name: string;
  /** Course name but shortened */
  nameShort: string;
}

/** 
 * Type for Teacher data
 */
export interface Teacher {
  /** Teacher ID */
  id: string;
  /** Teacher's full name */
  fullName: string;
}

/**
 * Enum for different Week Days
 */
export enum WeekDay {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

/**
 * Type for Lesson 
 */
export interface Lesson {
  /** Lesson ID */
  id: string;
  /** Lesson flow */
  lessonFlow: LessonFlow;
}

/**
 * Type for Lesson Flow
 */
export interface LessonFlow {
  /** Which academic week this LessonFlow Item is in */
  academicWeek: AcademicWeek;
  /** List of LessonFlow schedules */
  schedules: Schedule[];
  /** Parent Lesson ID */
  lessonID?: string;
}

/**
 * Type for Lesson Schedule Item
 */
export interface Schedule {
  /**
   * The object containing the test details. This is required if the schedule type is `TEST`.
   *     When added to the lesson flow schedule, the corresponding worksheet must also be added to the collectable list
   */
  test: LessonFlowTestItem;
  /**
   * The lesson flow schedule type.
   *     Used for identifying the item added to the schedule in the lesson flow.
   */
  type: LessonFlowScheduleType;
  /**
   * The manually generated id that will be used
   *     for reference when performing operations.
   */
  id: string;
}

/**
 * Lesson flow schedule type values
 */
export enum LessonFlowScheduleType {
  Break = 'BREAK',
  Other = 'OTHER',
  Test = 'TEST',
  Worksheet = 'WORKSHEET'
}

/**
 * The entry on the lesson flow containing the test details.
 *   This is currently only being used in Schedule.
 */
export type LessonFlowTestItem = {
  /**
   * The description that the teacher placed for the test.
   *     (not copied from the WorkSheet entity).
   */
  description?: string;
  /** The test name copied from the WorkSheet entity. */
  testName: string;
  /** Reference to the WorkSheet entity. */
  worksheetId: string;
  /** The title copied from the WorkSheet entity. */
  worksheetTitle: string;
};

/**
 * Type for AcademicWeek
 */
export interface AcademicWeek {
  /** Academic week number */
  weekNumber: number;
  /** When academic week starts */
  startDate: Date;
  /** When academic week ends */
  endDate: Date;
}

/** These are the sections copied from the grading template. */
export type WorkSheetTestSection = {
  /** The test section name, copied from the grading template section. */
  name: string;
  /** The section total, initially copied from the grading template, then can be updated afterwards. */
  total: number;
};

/** A worksheet that is used as a test for the students. */
export type WorkSheetTest = {
  /** The name of the test used by the teachers */
  name: string;
  /** The different sections of a Test worksheet */
  sections: WorkSheetTestSection[];
  /** The overall score of the test. It is the sum of all the section total. */
  totalScore: number;
};

/** This refers to a sheet paper given by teachers to students to do a particular task. There are two types of worksheets: Regular worksheet and Test worksheet */
export type WorkSheet = {
  /** Worksheet ID */
  id: string;
  /** When this is not null, the worksheet is considered a test. */
  test?: WorkSheetTest;
  /** Worksheet title */
  title: string;
  /** Academic week number */
  weekNumber?: number;
  /** Parent Lesson ID */
  lessonId?: string;
};

/** Type for Seats */
export interface Seat {
  /** Seat/Student Info associated with current seat */
  seatInfo: SeatInfo[];
  /**
   * Start date of student
   */
  start: Date;
  /**
   * End date of student
   */
  end: Date;
}

/** Type for SeatInfo */
export interface SeatInfo {
  /** Student associated with seat */
  student: Student;
  /** What type of seat this info is for */
  type: ClassCapacitySeatType;
}

/**
 * SeatInfo Type details
 */
export enum ClassCapacitySeatType {
  /** A regular seat */
  Regular = 'Regular',
  /** Seat is reserved */
  Reserved = 'Reserved',
  /** The student is transferred in */
  TransferIn = 'TransferIn',
  /** The student was transferred out */
  TransferOut = 'TransferOut',
  /** The student will be withdrawing */
  Withdrawing = 'Withdrawing'
}

/**
 * Type for student data
 */
export interface Student {
  /** Student ID */
  id: string;
  /** Student's full name */
  fullName: string;
}

/**
 * Type for uploaded student test file details
 */
export interface UploadedFile {
  /** Where the file can be downloaded in the bucket */
  url: string;
  /** Where the thumbnail can be downloaded from in the bucket */
  thumbnailUrl?: string;
  /** Content hash key */
  contentHash?: string;
  /** Full URL to access thumbnail as a src for image elements */
  thumbnailRemoteUrl?: string;
}

/** The yearly lesson plan of the teachers for a course. */
export interface LessonPlan {
  /** Unique identifier of the lesson plan. */
  id: string;
};

/** Base Input for `testSubmissionCreate` mutation. */
export interface BaseTestSubmissionCreateInput {
  /**
   * Indicates whether the test will be marked digitally or
   *     the test has already been marked physically by the teacher.
   *     When not provided, it should default to `Digital`.
   */
  annotationType: TestSubmissionAnnotationType;
  /** The student who took the exam. */
  examineeId: string;
  /** The lesson that the test was taken on. */
  lessonId: string;
  /**
   *
   *       The list of scores (written physically) that the teacher placed on the test.
   *       This is required for `annotationType`="Physical" test submission.
   */
  scores?: TestSubmissionScoresInput[];
  /** The worksheet that was used as a test. */
  worksheetId: string;
};

/** Input for `testSubmissionCreate` mutation. */
export interface TestSubmissionCreateInput extends BaseTestSubmissionCreateInput {
  /** Url to the test file that was submitted. */
  testFileUrl: string;
  /** Url to the thumbnail of the test file that was submitted. */
  testThumbnailFileUrl: string;
};

/**
 * Contains scores of a physical/marked test.
 *   This is required for `annotationType`="Physical" test submission.
 */
export type TestSubmissionScoresInput = {
  /** The section name to provide the score. */
  name: string;
  /** The score to provide the test section. */
  score: number;
};

/**
 * Represents the type of annotation for a test submission.
 */
export enum TestSubmissionAnnotationType {
  /** 
   * Typically a TestSubmission that was initially uploaded with an `Unmarked` status.
   * This is the default value when creating a TestSubmission.
   */
  Digital = 'Digital',
  /**
   * Typically a TestSubmission that was initially uploaded with a `Marked` status.
   * Would also have been uploaded with student's scores.
   */
  Physical = 'Physical'
}

/** The test submission of each student. */
export type TestSubmission = {
  /** Class of the student. */
  class: TestSubmissionClass;
  /** Student who took the exam. */
  examinee: TestSubmissionStudent;
  /** Indicates the current state of the test submission. */
  status: TestSubmissionStatus;
  /** The submitted worksheet used as the test. */
  worksheet: TestSubmissionWorksheet;
  /** Unique identifier of the record. */
  id: string;
};

/** The submitted worksheet used as the test. */
export type TestSubmissionWorksheet = {
  /** Contains the test information: name, scores, scoredBy. */
  test: TestSubmissionTest;
  /** Title of the worksheet copied from the worksheet entity. */
  title: string;
  /** Reference to the worksheet entity. */
  id: string;
};

/** Contains the test information. */
export type TestSubmissionTest = {
  /** The name of the test. */
  name: string;
  /** Unique identifier for the test. */
  id: string;
  /** The sum of all the section scores of the student. */
  totalScore: number;
  /** The total score of all the test sections. */
  maxTotalScore: number;
  /** The sections of the test. */
  sections: TestSubmissionTestSection[];
};

/** Contains the test scores information. */
export type TestSubmissionTestSection = {
  /** The name of the test section. */
  name: string;
  /** The score of the student. */
  score: number;
  /** The total score of the test section. */
  maxScore: number;
};

/**
 * Represents the status of an editable test submission.
 */
export enum EditableTestSubmissionStatus {
  /**
   * The test submission has a Teacher's Copy and is being edited by the teacher.
   */
  Draft = 'Draft',
  /**
   * The teacher has set this status to be reviewed by a senior teacher.
   */
  ForReview = 'ForReview',
  /**
   * A senior teacher has reviewed the test submission and deemed ready to be set to `Marked`.
   */
  Reviewed = 'Reviewed',
}

/*
* Represents the status of an uneditable test submission.
*/
export enum UneditableTestSubmissionStatus {
  /**
   * The test submission already has been marked and has the student's grades.
   */
  Marked = 'Marked',
  /**
   * The test submission has been returned to the student.
   * Used by finalized test submissions that were digitally annotated.
   */
  Returned = 'Returned',
  /**
   * Initial status of a test submission that has not been physically marked.
   */
  Unmarked = 'Unmarked'
}

/**
 * Represents the status of a test submission.
 */
export type TestSubmissionStatus = EditableTestSubmissionStatus | UneditableTestSubmissionStatus

/** Student who took the exam. */
export type TestSubmissionStudent = {
  /** Reference to the student entity. */
  id: string;
  /** The full name of the student who took the test (examinee). */
  fullName: string;
};

/** Class where the student is assigned to. */
export type TestSubmissionClass = {
  /** Reference to the class entity. */
  id: string;
  /** Name of the class copied from the class entity. */
  name: string;
  /** Short hand of the class name copied from the class entity. */
  shortName: string;
};

/**
 * Represents the categories of files.
 */
export enum FileCategories {
  /** Files used in Archus Notes Service */
  Documents = "Documents",
  /** Files that are used in Test Submission */
  Tests = "Tests",
}

/**
 * Student Attendances
 */
export interface Attendance { 
  /** Reference to the Attendance Entity */
  _id: any, 
  /** Student the attendance record is for */
  student?: {_id: any }
}