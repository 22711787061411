import { Modal, ModalButtonGroup } from "@thepiquelab/archus-components-web";
import { FileResponse } from "../../../mobx/app.store";
import { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { useAppStore } from "../../../mobx";
import MoveModalHeader from "./MoveModalHeader";
import MoveModalBody from "./MoveModalBody";

/**
 * MoveModal Properties
 */
interface MoveModalProps {
  /** Action on click of close/cancel buttons */
  onClose?: () => void;
  /** Starting path of move modal directory */
  initialPaths?: FileResponse[];
}

/**
 * Modal for setting destination folder of movingItems
 * @param props 
 */
const MoveModal:React.FC<MoveModalProps> = (props) => {
  const {
    onClose,
    initialPaths = []
  } = props;
  const {
    getFoldersBasedOnId,
    moveFilesFolders,
  } = useAppStore();

  /** Folder paths of current directory */
  const [currentFolderPaths, setCurrentFolderPaths] = useState<FileResponse[]>(initialPaths);

  /** Modal directory */
  const [currentFolderDirectory, setCurrentFolderDirectory] = useState<FileResponse[]>([]);

  /**
   * The latest path in currentFolderPaths
   */
  const latestCurrentPath = useMemo(
    () => currentFolderPaths.length ? currentFolderPaths[currentFolderPaths.length - 1]._id: ''
    , [currentFolderPaths]
  );

  /**
   * Disable move action if destination file has not changed from initial location
   */
  const isMoveDisabled = useMemo(
    () => (!!initialPaths.length && initialPaths[initialPaths.length - 1]._id === latestCurrentPath)
    , [initialPaths, latestCurrentPath]
  );

  useEffect(() => {
    /** Set current paths for modal directory */
    getFoldersBasedOnId(latestCurrentPath).then(setCurrentFolderDirectory)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestCurrentPath]);

  return (<Modal visible onClose={() => onClose && onClose()}>
    <Modal.Header 
      title={
        <MoveModalHeader
          currentFolderPaths={currentFolderPaths}
          setCurrentFolderPaths={setCurrentFolderPaths}
        />
      }
    />
    <Modal.Content>
      <MoveModalBody 
        currentFolderDirectory={currentFolderDirectory}
        currentFolderPaths={currentFolderPaths}
        setCurrentFolderPaths={setCurrentFolderPaths}
      />
    </Modal.Content>
    <Modal.Footer>
      <ModalButtonGroup
        showOk showCancel
        okText="Move"
        okButtonProps={{
          type: "primary",
          disabled: isMoveDisabled,
        }}
        cancelButtonProps={{
          type: "ghost"
        }}
        onOK={async () => {
          await moveFilesFolders(latestCurrentPath)
          if (onClose) {
            onClose();
          }
        }}
        onCancel={() => {
          if (onClose) {
            onClose();
          }
        }}
      />
    </Modal.Footer>
  </Modal>); 
};

export default observer(MoveModal);