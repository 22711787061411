import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { last } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppStore } from "../../mobx";
import { QuickLink } from "../../mobx/app.store";

/** Dashboard constant */
const DASHBOARD = "Dashboard";

/**
 * Interface for route (top-most) Quick Links
 */
export interface QuickLinksItemProps extends QuickLink {
  /** The currently selected quick link */
  currentActive?: string;
  /** Function to set the curently selected quick link */
  setCurrentActive: (currentActive: string) => void;
  /** Function that runs a route check before any action in the route quick link*/
  checkRoute: (currentActive: string) => void;
}

/**
 * List of all the quick links
 */
const QuickLinksList = () => {
  const { folderPaths, availableRoutes } = useAppStore();
  const navigate = useNavigate();
  const location = useLocation();

  /** Current active item in Quick Links List */
  const [currentActive, setCurrentActive] = useState(DASHBOARD);

  useEffect(() => {
    // QuickLinks only display paths up to the second level of descendants from the Dashboard
    if (folderPaths.length < 3) {
      setCurrentActive(last(folderPaths)?._id ?? DASHBOARD)
    }
  }, [folderPaths]);

  /**
   * Check if current route is equal to provided route
   * If not then navigate to provided route
   * @param route 
   */
  const checkRoute = (route:string) => {
    if (location.pathname !== route) {
      navigate(route);
    }
  };

  return (
    <div className="flex flex-grow overflow-scroll flex-col gap-6 pb-10">
      {
         availableRoutes
          .map(route => {
            const RouteQuickLink = route.quickLink;
            
            return (
              <RouteQuickLink
                checkRoute={checkRoute} 
                setCurrentActive={setCurrentActive} 
                currentActive={currentActive} 
                _id=""
                key={route._id}
                name={route.name}
                routeKey={route._id}
                route={route.route}
              />
            );
          })
      }
    </div>
  );
}

export default observer(QuickLinksList);
