import { makeAutoObservable } from "mobx";
import { FileType, factory } from "../network";
import { AppConfig } from "../config";
import { AcademicLevel, Attendance, BaseTestSubmissionCreateInput, ClassFilterInput, ClassTabs, CoreClass, FileCategories, Teacher, TestSubmission, TestSubmissionAnnotationType, TestSubmissionCreateInput, TestSubmissionStatus, UploadedFile, WorkSheet, WorkSheetTestSection, localCompareFuncFactory } from "../utils";
import AppStore, { BreadCrumbDataInterface, FileInfoProps, FileProps, User } from "./app.store";
import { WorksheetData } from "../components/Tests/UploadModal";

/**
 * Sort function for academic levels
 * @param classA 
 * @param classB 
 */
const acadLevelSort = localCompareFuncFactory("name");

/**
 * Sort function for classes
 * @param classA 
 * @param classB 
 */
const coreClassSort = localCompareFuncFactory("shorthand");

/**
 * Represents the properties required for test submission upload.
 */
interface TestSubmissionUploadProperties {
  /** File to Upload */
  file: FileProps,
  /** New TestSubmission details */
  testSubmissionInput: BaseTestSubmissionCreateInput
}

/**
 * Store for Archus Core Service interactions
 */
export default class CoreStore {
  constructor(appStore: AppStore) {
    makeAutoObservable(this);
    this.appStore = appStore;
  }

  /** Main store for Archus Notes Upload UI */
  appStore: AppStore | null = null; 
  /** User's Teacher ID from Archus Core Service */
  teacherId = "";
  /** List of classes from Archus Core Service */
  coreClasses: CoreClass[] = [];
  /** Current class details displayed in TestsList */
  currentClass: CoreClass | null = null;
  /** Current types of classes being displayed */
  currentClassTab: ClassTabs = ClassTabs.MyClasses; 
  /** Current directory path for Tests */
  currentTestPaths: BreadCrumbDataInterface[] = [];
  /** True if teacher data is still loading */
  loadingTeacherData = false;
  /** True if list of classes are still loading */
  loadingClassData = false;
  /** True if class details are still loading */
  loadingClassDetails = false;
  /** Total number of classes available to view */
  coreClassesTotal = 0;
  /** Latest page loaded for class list pagination */
  coreClassesCurrentPage = 0;
  /** All academic levels available for filtering */
  availableAcademicLevels:AcademicLevel[] = [];
  /** All academic levels available for filtering */
  allTeachers: User[] = [];
  /** Filter input for class list */
  coreClassesFilter: ClassFilterInput= {};
  /** Current Test folder selected */
  currentTestDetails: WorkSheet | undefined;
  /** Core classes list search value */  
  coreClassesSearchValue: string = "";
  /**
   * An array of TestSubmission objects representing the current test submissions.
   */
  currentTestSubmissions: TestSubmission[] = [];
  /** List of failed test submission uploads */
  failedUploads: TestSubmissionUploadProperties[] = [];
  /** Attendances to the current class' lessons */
  attendances: Attendance[] = [];

  /**
   * True if teacher data or list of classes are still loading
   */
  get loadingInitData () {
    return this.loadingTeacherData || this.loadingClassData;
  }

  /**
   * True if currently displaying other users' classes
   */
  get isNotDisplayingMyClasses () {
    return this.currentClassTab === ClassTabs.OtherClasses;
  }

  /**
   * Core classes lists filtered by teacher name or class shorthand
   */
  get coreClassesFilteredWithSearch () {
    const searchValue = this.coreClassesSearchValue.toLocaleLowerCase();
    return this.coreClasses.filter(coreClass => 
      !searchValue 
      || (coreClass.shorthand ?? coreClass.name).toLocaleLowerCase().includes(searchValue)
      || coreClass.teacher?.fullName.toLocaleLowerCase().includes(searchValue)
    );
  }

  /**
   * Maps of academic levels to their available classes for viewing
   */
  get academicLevelMapsWithClasses () {
    return this.coreClassesFilteredWithSearch?.reduce((results: Record<string, CoreClass[]>, tempClassItem:CoreClass) => {
      const classItem: CoreClass = {
        ...tempClassItem,
        shorthand: tempClassItem.shorthand ?? tempClassItem.name
      }   
      const academicLevels = classItem.academicLevels.reduce((results1: Record<string, CoreClass[]>, acadLevel: AcademicLevel) => ({
        ...results1,
        [acadLevel.id]: results1[acadLevel.id] ? [...results1[acadLevel.id], classItem].sort(coreClassSort): [classItem]
      }), {...results});

      return {
        ...academicLevels
      }
    }, {});
  }

  /** List of folders closest to the root */
  get testQuickLinks () {
    return this.currentClassTab === ClassTabs.MyClasses
      ? this.availableAcademicLevels.flatMap(academicLevel => 
        this.academicLevelMapsWithClasses[academicLevel.id]?.map(coreClass => ({
          _id: coreClass.id,
          name: coreClass.shorthand || coreClass.name,
          isDisabled: !coreClass.lessonPlan
        })) ?? []
      )
      : [];
  }

  /**
   * Mapped core classes to their class ID
   */
  get coreClassesIdMap () {
    return this.coreClasses.reduce(
      (agg, currentClass) => ({
        ...agg,
        [currentClass.id] : currentClass
      }),
      {} as Record<string, CoreClass>
    );
  }

  /**
   * Student IDs list
   */
  get studentAttendanceList () {
    return this.attendances?.flatMap(attendance => [attendance?.student?._id] ?? []) ?? [];
  }

  /**
   * Set the search value to filter out the core classes list
   * @param searchValue 
   */
  setCoreClassesSearchValue = (searchValue: string ) => {
    this.coreClassesSearchValue = searchValue;
  }

  /**
   * Sets currentClass and resets the classlist
   * @param classTab 
   */
  setCurrentClassTab = async (classTab: ClassTabs) => {
    this.loadingClassData = true;
    this.currentClassTab = classTab;
    try {
      this.resetClasses();
      await this.initClasses();
    } finally {
      this.loadingClassData = false;
    }
  }
  
  /**
   * Set current path for tests view
   * @param testPaths 
   */
  setCurrentTestPaths = (testPaths: BreadCrumbDataInterface[]) => {
    this.currentTestPaths = testPaths;
  }

  /** Append to current path for tests view */
  appendCurrentTestsPath = (testPath: BreadCrumbDataInterface) => {
    this.currentTestPaths = [...this.currentTestPaths, testPath];
  }

  /**
   * Reset class list and class data
   */
  resetClasses = () => {
    this.coreClasses = [];
    this.coreClassesCurrentPage = 0;
    this.coreClassesTotal = 0;
    this.coreClassesFilter = {};
    this.coreClassesSearchValue = "";
  }

  /**
   * Reinitialize class/teacher data 
   */
  initData = async () => {
    this.teacherId = "";
    this.currentClassTab = ClassTabs.MyClasses; 
    this.currentTestPaths = [];
    
    this.resetClasses();

    await this.initTeacherData();
  }

  /**
   * Initialize teacher data for current logged in user
   */
  initTeacherData = async () => {
    try {
      this.loadingTeacherData = true;
      let result = await factory.get(AppConfig.coreUrl);
      this.teacherId = result.data.ref ?? "";

      await this.initClasses();
      await this.getAllAcademicLevels();
      await this.getAllTeachers();
    } finally {
      this.loadingTeacherData = false;
    }
  }

  /**
   * Sets the classFilter for filtering class data
   * @param filterInput 
   */
  setClassFilter = (filterInput: ClassFilterInput) => {
    this.coreClassesFilter = filterInput;
  }

  /**
   * Returns default teacherIds parameter for searching classes
   */
  getTeacherIdParam = () => {
    if (this.appStore?.isAcademicSupport) {
      // Academic Support can see all active classes in one page
      return [];
    } else if (this.currentClassTab === ClassTabs.MyClasses) {
      return [this.teacherId];
    } else {
      // If current tab is 'OtherClasses' then return all teacherIds except for current logged in user's
      return this.allTeachers.flatMap(teacher => teacher._id === this.teacherId ? []: teacher._id);
    }
  }

  /**
   * Initialize class list based on ClassFilterInput and searchString
   * @param searchString 
   */
  initClasses = async (searchString?: string) => {
    const {
      teacherIds = this.getTeacherIdParam(),
      academicLevelIds,
      lessonDays
    } = this.coreClassesFilter;

    try {
      // Different parts of endpoint needed for querying class data
      const endpoint = `${AppConfig.coreUrl}/classes`;
      const pageIndexParam = `?pageIndex=${this.coreClassesCurrentPage}`;
      const teacherIdParam = teacherIds ? `&teacherIds=${teacherIds}` : "";
      const academicLevelsParam = `&academicLevelIds=${!!academicLevelIds ? academicLevelIds : this.availableAcademicLevels.map(acadLevel => acadLevel.id)}`;
      const lessonDaysParam = !!lessonDays ? `&lessonDays=${lessonDays}`: "";
      const searchParam = !!searchString ? `&search=${encodeURIComponent(searchString)}`: "";
      
      const coreClassesData = (
        await factory.get(`${endpoint}${pageIndexParam}${teacherIdParam}${academicLevelsParam}${lessonDaysParam}${searchParam}`)
      );

      if (!this.coreClassesCurrentPage) {
        this.coreClassesTotal = coreClassesData?.data.data.classes.total;
        this.coreClasses = coreClassesData?.data.data.classes.items;
      } else {
        this.coreClasses = [...this.coreClasses, ...coreClassesData?.data.data.classes.items];
      }
      
    } catch (e) {
      console.log("There was an error querying classes from core: ", e);
    }
  }

  /**
   * Go to the next page of class data if there are any more to load
   */
  loadMoreOtherClasses = async () => {
    if (this.coreClasses.length < this.coreClassesTotal) {
      this.coreClassesCurrentPage++;
      this.initClasses();
    }
  }

  /**
   * Load classes of other teachers with the provided classFilterInput and searchString
   * @param filterInput 
   * @param search 
   */
  loadOtherClassesWithFilter = async (filterInput: ClassFilterInput, search?: string) => {
    try {
      this.loadingClassData = true;
      if (Object.keys(filterInput).length || search) {
        this.resetClasses();
        this.setClassFilter(filterInput);
        await this.initClasses(search);
      }
    } finally {
      this.loadingClassData = false;
    }
  }

  /**
   * Get all available teacher for filtering
   */
  getAllTeachers = async () => {
    const result = (await factory.get(AppConfig.coreUrl + `/teachers`)).data?.items?.map((item:Teacher) => ({
        _id: item.id,
        name: item.fullName
    }))
    .filter((item: Teacher) => (
      !this.appStore 
      || this.appStore.isAcademicSupport
      || item.id !== this.teacherId 
    )) ?? [];
    this.allTeachers = result;
  }

  /**
   * On click of class in Class List, load the tests for Tests List
   * @param classDetail 
   */
  setClassDetails = async(classDetail: BreadCrumbDataInterface) => {
    try {
      this.loadingClassDetails = true;

      const selectedClass = this.coreClassesIdMap[classDetail._id];
      const classLessonPlanData = (await factory.get(AppConfig.coreUrl + `/classes/${selectedClass.id}`))
      this.currentClass = classLessonPlanData.data.data.class;
        
      this.currentTestPaths = [classDetail];
    } finally {
      this.loadingClassDetails = false;
    }
  }

  /**
   * Get all available academic levels
   */
  getAllAcademicLevels = async () => {
    this.availableAcademicLevels = (await factory.get(AppConfig.coreUrl + `/levels`)).data
      .data.academicLevels?.sort(acadLevelSort)
      .filter((acadLevel:AcademicLevel) => acadLevel.isVisible && !acadLevel.isDeleted && !acadLevel.isArchived);
  }

  /**
   * On click of test folder in TestsList
   * @param exercise 
   */
  setCurrentTestDetails = async (worksheetId: string, weekNumber: number, lessonId: string) => {
    const returnData = (await factory.get(AppConfig.coreUrl + `/worksheet/${worksheetId}`));

    this.currentTestDetails = {
      ...returnData.data.data.workSheet,
      weekNumber,
      lessonId: lessonId
    };

    await this.loadTestSubmissions(lessonId, returnData.data.data.workSheet.id);
    await this.loadLessonAttendances({
      lessonId,
      classId: this.currentClass?.id ?? ""
    })
    
  }

  /**
   * Uploads a test submission file to the cloud.
   * @param file - The file to be uploaded.
   * @param newFileName - Optional new file name.
   * @returns The uploaded file data.
   */
  uploadTestSubmissionToCloud = async (file: FileProps) => {
    let data = new FormData();
    data.append("type", "File");
    data.append("thumbnail", "true");
    //@ts-ignore
    data.append("file", file);
    try {
      return (await factory.post(AppConfig.baseUrl + '/portal/upload', 
        data,
        { 
          onUploadProgress: (progressEvent) => {}, 
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )).data as UploadedFile;
    } catch (e) {
      this.appStore?.setToast({
        description: `There was an error uploading test file "${file.name}": ` + e,
        type: "danger"
      });
    }
  }

  /**
   * 
   * @param createInput 
   */
  createTestSubmissionEntry = async (createInput: TestSubmissionCreateInput) => {
    const testSubmissionEntry = (await factory.post(AppConfig.coreUrl + '/test-submissions', 
        createInput
      ));

    const newTestSubmissionEntry: TestSubmission = 
      testSubmissionEntry?.data?.data?.testSubmissionCreate;

    // Add new test submission entry to the current test submissions
    this.currentTestSubmissions = [
      ...this.currentTestSubmissions,
      newTestSubmissionEntry
    ];

    return newTestSubmissionEntry;
  }

  /**
   * Returns the URL for downloading a file with the given file name.
   * URL received expires in 15 minutes.
   * @param fileName - The name of the file to download.
   * @returns The URL for downloading the file.
   */
  getFileUrl = (fileName: string) => {
    return (factory.get(AppConfig.coreUrl + `/download-url?url=${encodeURIComponent(fileName)}`));
  }

  /**
   * Processes the test submissions for the given worksheets.
   * 
   * @param worksheets - The array of worksheet data containing the test submissions.
   * @returns A promise that resolves when all test submissions have been processed.
   */
  processTestSubmissions = async (worksheets: WorksheetData[]) => {
    const currentTestDetails = this.currentTestDetails;

    await Promise.allSettled(
      worksheets.map(async (worksheetData) => {
        const scores =
          worksheetData.testFileType === TestSubmissionAnnotationType.Physical
            ? worksheetData.markedScores?.map((markedScore: WorkSheetTestSection) => ({
                name: markedScore.name,
                score: markedScore.total
              }))
            : undefined;

        await this.processTestSubmission({
          file: worksheetData.worksheet, 
          testSubmissionInput: {
            annotationType: worksheetData.testFileType,
            examineeId: worksheetData.selectedStudent ?? '',
            worksheetId: currentTestDetails?.id ?? '',
            scores,
            lessonId: currentTestDetails?.lessonId ?? ''
          }
        });
      })
    );

    /**
     * If there are any failed uploads after all uploads have been settled, display a toast to notify the user.
     */
    if (this.failedUploads.length) {
      this.appStore?.setToast({
        description: `Some files were not uploaded successfully: ${
          this.failedUploads?.map(failedUpload => failedUpload.file.name)?.join(', ')
        }`,
        type: "danger"
      });

      // TODO: Handle failed uploads at a later time

      this.clearFailedUploads();
    }
  };

  /**
   * Processes the test submission by uploading the file to the cloud, creating a test submission entry,
   * and storing the file information in the database.
   * @param uploadProps - The properties for the test submission upload.
   * @returns A Promise that resolves when the test submission is processed successfully.
   * @throws If there is an error during the process.
   */
  processTestSubmission = async (uploadProps: TestSubmissionUploadProperties) => {
    const {file, testSubmissionInput} = uploadProps;

    try {
      const fileUrls = await this.uploadTestSubmissionToCloud(file);

      if (fileUrls?.url) {
        const testSubmissionEntry = await this.createTestSubmissionEntry({
          ...testSubmissionInput,
          testFileUrl: fileUrls.url,
          testThumbnailFileUrl: fileUrls.thumbnailUrl ?? ''
        })

        const fileInfo: FileInfoProps = {
          name: file?.name,
          type: FileType.File,
          fileCategory: FileCategories.Tests,
          parentId: testSubmissionEntry.id,
          url: fileUrls.url,
          thumbnailUrl: fileUrls.thumbnailUrl ?? '',
          metadata: {
            contentType: file.type,
            //@ts-ignore
            contentModifiedAt: file.lastModified,
            size: file.size,
            //@ts-ignore
            contentHash: file.contentHash,
          },
        };

        await factory.put(AppConfig.baseUrl + "/files/tests", fileInfo);
      }
    } catch (e) {
      this.appendToFailedUploads({
        file,
        testSubmissionInput
      });
    }
  }

  /**
   * Clears the failed uploads.
   */
  clearFailedUploads = () => {
    this.failedUploads = [];
  };

  /**
   * Appends a failed upload to the failed uploads list.
   * @param uploadProps - The properties for the failed upload.
   */
  appendToFailedUploads = (uploadProps: TestSubmissionUploadProperties) => {
    this.failedUploads.push(uploadProps);
  }

  /**
   * Loads test submissions for a specific lesson and worksheet.
   * @param lessonId The ID of the lesson.
   * @param worksheetId The ID of the worksheet.
   */
  loadTestSubmissions = async (lessonId: string, worksheetId: string) => {
    const retVal = await factory.get(AppConfig.coreUrl + `/lesson/${lessonId}/test-submissions`);
    this.currentTestSubmissions = 
      retVal?.data?.data?.testSubmissions?.items
        .filter(
          (testSubmission: TestSubmission) => testSubmission.worksheet.id === worksheetId
        );
  }

  /**
   * Clears the test submissions.
   */
  clearTestSubmissions = () => {
    this.currentTestSubmissions = [];
  }

  /**
   * Updates the status of a test submission.
   * @param testSubmissionId - The ID of the test submission.
   * @param status - The new status of the test submission.
   * @returns - A promise that resolves when the status is successfully updated.
   */
  updateTestSubmissionStatus = async (testSubmissionId: string, status: string) => {
    const testSubmissionEntry = (await factory.post(AppConfig.coreUrl + 
      `/test-submissions/status`,
      {
        testSubmissionId,
        status
      }
    ));
    
    // replace current test submission value status
    this.currentTestSubmissions = this.currentTestSubmissions.map((testSubmission: TestSubmission) => {
      return (testSubmission.id === testSubmissionId) 
        ? {
          ...testSubmission,
          status: status as TestSubmissionStatus
        }
        : testSubmission;
    });
  }

  /**
   * Sets the current class' attendance details
   */
  async loadLessonAttendances(params: {lessonId: string, classId: string}) {
    const {lessonId, classId} = params;

    this.attendances = (await factory.get(AppConfig.coreUrl + `/class/${classId}/lesson/${lessonId}/attendances`))?.data?.items ?? [];
  }
}
