import { Typography } from "@thepiquelab/archus-components-web";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { Property } from "csstype";
import { QuickLinks } from "../../mobx/app.store";
import useDragActions from "../../hooks/useDragActions";
import { colors } from "../../constant";
import { RightIcon } from "../Icons/RightIcon";
import { ALL_ROUTES, RouteItemRoutes } from "../../pages/routes";

/** List item for QuickLinks */
interface QuickLinksListItemRowProps {
    /** QuickLinksItem data */
    link: QuickLinks;
    /** Set to true if item is a route item */
    isRoute?: boolean;
    /** Set to true if item is a child */
    isChild?: boolean;
    /** Set to true if item is current open item */
    isActive?: boolean;
    /** Set to true if parent item is open */
    isOpen?: boolean;
    /** Action on click of arrow icon */
    onOpen?: (isOpen:boolean) => void,
    /** Action on click of list item row */
    onClick: () => void;
    /** Set to true to disable drop actions on item */
    isNotDroppable?: boolean;
    /** True if quicklink is disabled */
    isDisabled?: boolean;
}

/**
 * A row in the QuickLinksList Item
 * @param props 
 * @returns 
 */
const QuickLinksListItemRow: React.FC<QuickLinksListItemRowProps>  = (props) => {
  const { 
    link, 
    onClick, 
    isRoute, 
    isActive = false, 
    isChild, 
    isOpen, 
    onOpen,
    isNotDroppable = false,
    isDisabled = false
  } = props;

  const {
    isHovering,
    dragActionRootProps,
    getInputProps
  } = useDragActions(
    false,
    !isNotDroppable,
    !isNotDroppable,
    link._id);

  /** Link icon styles */
  let iconStyles = useMemo(() => ({
    ...(isRoute ? { display: "none" as Property.Visibility }: {}),
    ...(isOpen ? {
      "--tw-rotate": '90deg',
      transform: "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
    }: {})
  }), [isOpen, isRoute]);

  /** Link List Item styles */
  let listItemStyles = useMemo(() => {
      let finalStyles: React.CSSProperties = {};

      if (isActive) {
        if (isRoute) {
          finalStyles.color = colors.primary.red;
        } else {
          if (isChild) {
            finalStyles.backgroundColor = colors.primary.navy100;
          } else {
            finalStyles.color = colors.white;
            finalStyles.backgroundColor = colors.primary.red;
          }
        }
      } else {
        if (isHovering && !isDisabled) {
          finalStyles.backgroundColor = colors.primary.navy100;
          finalStyles.cursor = "pointer";
        }
        if (isDisabled) {
          finalStyles.opacity = 0.5;
        }
      }

      if (isChild) {
        finalStyles.paddingLeft = "3.5rem";
      }

      finalStyles.padding = isRoute ? '10px 5px' : '0.75rem';

      return finalStyles;
    },
    [isChild, isActive, isHovering, isRoute, isDisabled]
  );

  /**
   * Icon for route items
   */
  const RouteIcon = useMemo(() => isRoute && link.routeKey
      ? ALL_ROUTES[link.routeKey as RouteItemRoutes].Icon 
      : () => <></>
    ,[link, isRoute]
  );

  return (
    <div 
      { ...dragActionRootProps }
      className="flex gap-4 p-3 rounded items-center w-full"
      style={listItemStyles}
      onClick={onClick}
    >
      { !isNotDroppable && <input { ...getInputProps() } /> }
      {
        !isChild && onOpen &&
          (isRoute ? <RouteIcon color={isActive ? colors.primary.red: colors.primary.navy} /> :
            <div className="w-5 h-5">
              <RightIcon style={iconStyles} 
                color={isActive ? colors.white : colors.primary.navy} 
                onClick={(e) => {
                  e.stopPropagation();
                  onOpen(!isOpen);
                }} 
              />
            </div>)
      }
      { 
        !isChild 
          ? (
            <Typography.Heading level={4} className="!text-inherit !font-normal">
              {link.name}
            </Typography.Heading>
          ): (
            <Typography.Heading level={4} className="!font-normal" >{link.name}</Typography.Heading>
          )
      }
    </div>
  );
}

export default observer(QuickLinksListItemRow);
