import { observer } from "mobx-react";
import { Button, Input, Modal, Select, Typography } from "@thepiquelab/archus-components-web";
import { useCallback, useEffect, useMemo, useState } from "react";
import CustomSegment from "../Common/CustomSegment";
import { FileProps } from "../../mobx/app.store";
import { useAppStore, useCoreStore } from "../../mobx";
import { TestSubmissionAnnotationType, WorkSheetTestSection, customDateFormatter } from "../../utils";
import { pdfjs } from 'react-pdf';


/** Creates a URL object that represents the object given in the parameter. */
const urlCreator = window.URL || window.webkitURL;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/**
 * Uploaded student tests can either be 'Marked' or 'Unmarked'
 */
enum UploadedTestFileTypes {
  /**
   * A test file that will be digitally annotated by the teacher.
   */
  Unmarked = "Unmarked", 
  /**
   * A test file that has already been marked by the teacher. 
   * It is uploaded with the student's scores.
   */
  Marked = "Marked"
}

/**
 * Options for the types of uploaded test files.
 */
const TestFileTypeOptions = [
  {
    label: UploadedTestFileTypes.Unmarked,
    value: TestSubmissionAnnotationType.Digital
  },
  {
    label: UploadedTestFileTypes.Marked,
    value: TestSubmissionAnnotationType.Physical
  }
];

/**
 * Student Worksheet Data
 */
export interface WorksheetData {
  /** Worksheet status on whether it has been 'Marked' or 'Unmarked' */
  testFileType: TestSubmissionAnnotationType;
  /** Student scores if test file is 'Marked' */
  markedScores?: WorkSheetTestSection[];
  /** Student who owns the worksheet */
  selectedStudent?: string;
  /** Actual worksheet file */
  worksheet: FileProps;
}

/**
 * MoveModal Properties
 */
interface UploadModalProps {
  /** Action on click of close/cancel buttons */
  onClose?: () => void;
  filesToUpload: FileProps[];
}

/**
 * Modal for uploading student test files
 * @param props 
 */
const UploadModal: React.FC<UploadModalProps> = (props) => {
  const { 
    onClose,
    filesToUpload,
   } = props;

   const {
    currentClass,
    currentTestDetails,
    currentTestSubmissions,
    studentAttendanceList,
    processTestSubmissions,
   } = useCoreStore();

   const {
    setToast
   } = useAppStore();

  /** Worksheet data to be uploaded to Archus Core Service */
  const [worksheetData, setWorksheetData] = useState<WorksheetData[]>([]);

  /** Editable scores of student when 'Marked' is selected as current test file type */
  const [markedScores, setMarkedScores] = useState<WorkSheetTestSection[]>([]);

  /** Student whose test is currently being displayed */
  const [selectedStudent, setSelectedStudent] = useState<string>();

  /** Index of current uploaded test file being displayed */
  const [currentFileIndex, setCurrentFileIndex] = useState(0);

  /** Whether the current test being displayed is 'Marked' or 'Unmarked' */
  const [currentTestFileType, setCurrentTestFileType] = useState(TestSubmissionAnnotationType.Digital);

  /** True if modal is currently uploading files */
  const [isLoading, setIsLoading] = useState(false);

  /** Student whose test is currently being displayed */
  const [previewLink, setPreviewLink] = useState<string>();

  /** Current uploaded test file being displayed */
  const currentFile = useMemo(() => filesToUpload[currentFileIndex], [currentFileIndex, filesToUpload]);

  /** Current date is when the file was uploaded */
  const currentDate = customDateFormatter(new Date());

  /** True if the last uploaded file is being displayed */
  const isLastFile = useMemo(() => (currentFileIndex + 1) === filesToUpload.length, [currentFileIndex, filesToUpload]);

  /**
   * Represents the array of examinee IDs for the current test submissions.
   * @type {string[]}
   */
  const currentTestSubmissionStudents = useMemo(() => {
    return currentTestSubmissions?.map(submission => submission.examinee.id) ?? [];
  }, [currentTestSubmissions]);

  /** All unique students available in this class */
  const currentStudentOptions = useMemo(() => {
    const allSeats = currentClass?.seats
      .flatMap(seat => seat.seatInfo) ?? [];

    // Remove duplicates
    return Array.from(
      new Set(allSeats
        .map(seat => seat.student.id)
      )
    ).map(studentId => ({
      label: allSeats.find(seat => seat.student.id === studentId)?.student.fullName ?? '',
      value: studentId,
      disabled: worksheetData.some(worksheet => worksheet.selectedStudent === studentId) 
        || currentTestSubmissionStudents.some(id => id === studentId)
        || !studentAttendanceList.includes(studentId),
    }))
  }, [currentClass?.seats, studentAttendanceList, worksheetData, currentTestSubmissionStudents]);

  /**
   * Processes the preview link and sets the preview link state with the rendered image data.
   * @param previewLink - The preview link to be processed.
   * @returns A Promise that resolves when the preview link has been processed and the preview link state has been set.
   */
  const processAndSetPreviewLink = useCallback(async () => {
      //@ts-ignore
      const previewLink = urlCreator.createObjectURL(currentFile);

      if(previewLink) {
        const pdf = await pdfjs.getDocument(previewLink).promise;
        const page = await pdf.getPage(1);

        const viewport = page.getViewport({ scale: 1.5 });
        const canvas = document.createElement('canvas');
        const canvasContext = canvas.getContext('2d');

        if (!canvasContext) {
          setToast({
            description: "Could not get 2D context from canvas",
            type: "danger"
          });

          return;
        }

        canvas.height = viewport.height;
        canvas.width = viewport.width;
        page.render({
          canvasContext,
          viewport
        }).promise.then(() => {
          const imgData = canvas.toDataURL('image/png');
          setPreviewLink(imgData);
        });
      }
    },
    [currentFile, setToast]
  );

  /**
   * Sets the image preview and worksheet data of the file to be uploaded 
   * on change of the current file being displayed in the modal
   * (on initialize of modal and clicking 'Next'/'Back').
   */
  useEffect(() => {
    processAndSetPreviewLink();

    // Set with current worksheet data
    setMarkedScores(
      worksheetData[currentFileIndex]?.markedScores ?? []
    );
    setSelectedStudent(
      worksheetData[currentFileIndex]?.selectedStudent ?? ''
    );
    setCurrentTestFileType(
      worksheetData[currentFileIndex]?.testFileType 
        ?? TestSubmissionAnnotationType.Digital
    );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFileIndex, filesToUpload, processAndSetPreviewLink, worksheetData]);

  /**
   * Sets the initial worksheet data state with the files to be uploaded.
   */
  useEffect(() => {
    setWorksheetData(
      filesToUpload.map(file => ({
        markedScores: currentTestDetails?.test?.sections?.map(
          section => ({ ...section, total: 0 })
        ),
        testFileType: TestSubmissionAnnotationType.Digital,
        worksheet: file
      }))
    );
  }, [filesToUpload, currentTestDetails]);

  /**
   * Pre-processes the test submission by generating a new file name and processing the submission data.
   * @param worksheets The array of worksheet data to be processed.
   * @returns A Promise that resolves when all the test submissions have been processed.
   */
  const preProcessTestSubmission = async (worksheets: WorksheetData[]): Promise<void> => {
    setIsLoading(true);

    try {
      await processTestSubmissions(worksheets);
    } finally {
      setIsLoading(false);
      onClose?.();
    }
  };

  /**
   * Handles the "Ok" button click event.
   * If it is the last file, it preprocesses the test submission.
   * Otherwise, it updates the worksheet data, clears the modal data, and displays the next file.
   */
  const onOk = async () => {
    const currentWorksheetData = {
      markedScores: [...markedScores],
      selectedStudent: selectedStudent ?? '',
      testFileType: currentTestFileType,
      worksheet: filesToUpload[currentFileIndex]
    };

    const worksheetDataCopy = [...worksheetData];
    worksheetDataCopy[currentFileIndex] = currentWorksheetData;
    setWorksheetData(worksheetDataCopy);

    if (isLastFile) {
      preProcessTestSubmission(worksheetDataCopy);
    } else {
      // Display next file
      setCurrentFileIndex(currentFileIndex + 1);
    }
  }

  return (
    <Modal visible onClose={onClose}>
      <Modal.Header title="Upload Test(s)" />
      <Modal.Content className="flex flex-col p-6 !gap-6">
        <div className="flex flex-row-reverse gap-2 items-center">
          <Typography.Heading level={4} className="!text-primary-navyLight !font-normal">
            Tests to upload
          </Typography.Heading>
          <Typography.Heading level={1} className="!text-6 !m-0" >
            {`${currentFileIndex + 1}/${filesToUpload.length}`}
          </Typography.Heading>
        </div>
        <div className="flex gap-6 items-end">
          <div className="h-40 w-35 rounded border !border-gray-400">
            <img alt="Uploaded Test Preview" src={previewLink} />
          </div>
          <div className="flex flex-col-reverse gap-5">
            <div className="flex flex-col-reverse gap-2">
              <Typography.Heading level={4} className="flex-grow !font-normal !m-0">
                File Uploaded On:
                <span className="text-primary-navyLight">
                  {` ${currentDate}`}
                </span>
              </Typography.Heading>
              <Typography.Heading level={4} className="flex-grow !font-normal !text-primary-navyLight !m-0">
                {`Academic Week ${currentTestDetails?.weekNumber ?? ''}`}
              </Typography.Heading>
              <Typography.Heading level={4} className="flex-grow !font-semibold !m-0">
                {currentFile.name}
              </Typography.Heading>
            </div>
            <CustomSegment 
              value={currentTestFileType} 
              disabled={isLoading}
              options={TestFileTypeOptions} 
              onChange={(value) => {
                setCurrentTestFileType(value as TestSubmissionAnnotationType);
              }}
            />
          </div>
        </div>
        <div className="flex items-center gap-5">
          <Typography.Heading level={4} className="flex-grow !font-semibold">
            Class Shorthand:
          </Typography.Heading>
          <div className="basis-80">
            <Input disabled value={currentClass?.shorthand || currentClass?.name} />
          </div>
        </div>
        <div className="flex items-center gap-5">
          <Typography.Heading level={4} className="flex-grow !font-semibold">
            Test Name:
          </Typography.Heading>
          <div className="basis-80">
            <Input disabled value={currentTestDetails?.test?.name} />
          </div>
        </div>
        <div className="flex items-center gap-5">
          <div className="flex-grow text-3.5 font-semibold">
            Student Name: 
          </div>
          <div className="basis-80">
            <Select 
              value={selectedStudent}
              className="w-full"
              placeholder="Select" 
              options={currentStudentOptions}
              loading={isLoading}
              onChange={(selectedStudent) => setSelectedStudent(selectedStudent)}
            />
          </div>
        </div>
        {
          currentTestFileType === TestSubmissionAnnotationType.Physical &&
          (
            <>
              <div className="flex items-center gap-5">
                <div className="flex-grow text-3.5 font-semibold basis-20">
                  Scores:
                </div>
                <div className=" flex flex-grow justify-between">
                  {
                    currentTestDetails?.test && markedScores && currentTestDetails?.test?.sections?.map((section, index) => (
                        <div className="flex-grow flex items-center gap-4"
                          key={section.name}
                        >
                          <div className="text-3.5 font-semibold">
                            { section.name }
                          </div>
                          <div className="flex-grow flex items-center gap-1">
                            <Input className="!w-12" value={markedScores[index].total}
                              onChange={(event) => {
                                const markedScoresNew = [...markedScores];
                                
                                // Set total score to 0 if input is empty
                                // Set total score to section total if input is greater than section total
                                if (event.target.value) {
                                  const parsedValue = parseInt(event.target.value);
                                  if (!isNaN(parsedValue) ) {
                                    if (parsedValue > section.total) {
                                      markedScoresNew[index].total = section.total;
                                    } else {
                                       markedScoresNew[index].total = parsedValue;
                                    }
                                  }
                                } else {
                                  markedScoresNew[index].total = 0;
                                }

                                setMarkedScores(markedScoresNew);
                              }}
                            />
                            <div className="text-3.5 font-semibold">
                              / 
                            </div>
                            <div className="text-3.5 font-semibold">
                              { section.total }
                            </div>
                          </div>
                        </div>
                      )
                    )
                  }
                </div>
              </div>
              <div className="flex items-center gap-5">
                <div className="flex-grow text-3.5 font-semibold">
                  Total Score:
                </div>
                <div className="basis-80">
                <Typography.Heading level={4} className="flex-grow !font-normal !m-0 !text-primary-navyLight">
                  {`${markedScores.reduce((agg, score) => agg + score.total, 0)} / ${currentTestDetails?.test?.totalScore}`}
                </Typography.Heading>
                </div>
              </div>
            </>
          )
        }
      </Modal.Content>
      <Modal.Footer className="flex flex-row">
        {
          currentFileIndex > 0 &&
          <Button
            loading={isLoading}
            onClick={() => {
              setCurrentFileIndex(currentFileIndex - 1);
            }}
          >
            Back
          </Button>
        }
        <div className="flex-grow"></div>
        <Button
          loading={isLoading}
          onClick={() => onClose?.()}
        >
          Cancel
        </Button>
        <Button type="primary"
          disabled={!selectedStudent}
          loading={isLoading}
          onClick={onOk}
        >
          {isLastFile ? "Confirm": "Next" }
        </Button>
      </Modal.Footer>
    </Modal>
  )
};


export default observer(UploadModal);