import { IconSVGProps } from "../../utils";

/** SVG for no Trash */
export const EmptyTrash:React.FC<IconSVGProps> = ({height = 190, width = 280, ...props}) => (
  <svg width={width} height={height} viewBox="0 0 280 190" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1_16)">
      <path d="M276.227 38.782L261.213 35.5376" stroke="#FFDA40" strokeWidth="10.1499" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M267.098 44.6672L270.342 29.6524" stroke="#FFDA40" strokeWidth="10.1499" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M226.958 58.0975C229.75 58.0975 232.013 55.8342 232.013 53.0423C232.013 50.2504 229.75 47.9871 226.958 47.9871C224.166 47.9871 221.902 50.2504 221.902 53.0423C221.902 55.8342 224.166 58.0975 226.958 58.0975Z" fill="#52DEFF"/>
      <path d="M247.028 81.412L258.044 74.3196C259.628 73.3387 261.741 73.7914 262.722 75.3005L269.814 86.3164C270.795 87.9008 270.342 90.0135 268.833 90.9943L257.817 98.0868C256.233 99.0676 254.12 98.6149 253.139 97.1059L246.047 86.09C245.066 84.581 245.519 82.4683 247.028 81.412Z" fill="#F44A4A"/>
      <path d="M201.455 16.9011C206.122 16.9011 209.906 13.1177 209.906 8.45055C209.906 3.78344 206.122 0 201.455 0C196.788 0 193.005 3.78344 193.005 8.45055C193.005 13.1177 196.788 16.9011 201.455 16.9011Z" fill="#288AEA"/>
      <path d="M214.735 149.62C200.097 158.071 181.989 158.071 167.351 149.62L101.331 111.517C99.1431 110.234 99.1431 107.065 101.331 105.783L140.566 83.1474C142.528 82.0156 144.867 82.0156 146.828 83.1474L214.735 122.307C225.222 128.418 225.222 143.584 214.735 149.62Z" fill="#E9EFF4"/>
      <path d="M134.907 110.913V43.9127H120.798V111.442C120.798 113.781 123.967 115.667 127.89 115.667C131.814 115.667 134.982 113.781 134.982 111.442C134.982 111.215 134.982 111.064 134.907 110.913Z" fill="#38B4F2"/>
      <path d="M184.101 30.4069L136.491 2.9426L102.916 20.4473L150.525 47.8362L184.101 30.4069Z" fill="#F24444"/>
      <path d="M168.106 29.9542C153.015 38.6311 143.735 54.7777 143.735 72.1315V98.6149L191.043 71.3015V43.2336C191.043 31.3878 178.292 24.069 168.106 29.9542Z" fill="#C62A4B"/>
      <path d="M215.414 111.894C200.323 120.571 181.762 120.571 166.672 111.894L143.735 98.6149L191.043 71.3015L215.414 85.3355C225.6 91.2961 225.6 106.009 215.414 111.894Z" fill="#F24444"/>
      <path d="M199.569 58.5503L140.415 24.4462L124.268 60.0593L183.422 94.1633L199.569 58.5503Z" fill="#D1E0ED"/>
      <path d="M199.569 58.5503L159.806 59.9838L152.487 31.2369L199.569 58.5503Z" fill="#F3FAFF"/>
      <path d="M184.101 30.4069L184.026 30.3314C184.026 30.3314 184.101 30.4069 184.101 30.4069Z" fill="#F24444"/>
      <path d="M150.224 47.7607C154.449 40.4419 160.56 34.2549 168.03 29.9542C172.859 27.1625 178.292 27.3134 182.592 29.5769L137.472 3.54621C132.87 0.150904 126.381 -0.679061 120.722 2.56535C113.177 6.94153 107.065 13.1285 102.916 20.3719C98.6904 27.6906 96.4268 36.0657 96.4268 44.7427V67.0008C96.4268 69.6416 97.8604 72.056 100.124 73.4142L143.735 98.6149V72.1315C143.735 63.4546 146.074 55.0795 150.224 47.7607Z" fill="#F24444"/>
      <path d="M129.475 39.7629L122.458 35.6885V69.1134C122.458 70.2452 123.061 71.377 124.042 71.9051L127.664 74.0178C128.494 74.4705 129.475 73.9423 129.475 72.9615V39.7629Z" fill="#FDDA44"/>
      <path d="M107.518 28.0679V40.6683C107.518 41.7246 108.122 42.7055 109.027 43.2336L129.475 55.0795V40.1401C129.475 38.8575 128.795 37.7257 127.739 37.1221L109.857 26.7852C108.801 26.1816 107.518 26.9361 107.518 28.0679Z" fill="#FDDA44"/>
      <path d="M51.8351 182.441C79.8377 182.441 102.538 168.929 102.538 152.261C102.538 135.593 79.8377 122.08 51.8351 122.08C23.8324 122.08 1.13177 135.593 1.13177 152.261C1.13177 168.929 23.8324 182.441 51.8351 182.441Z" fill="#E9EFF4"/>
      <path d="M86.0145 154.826C92.2016 156.033 98.3886 157.693 104.5 159.127C106.915 159.655 110.008 160.862 112.498 160.561C115.063 160.183 116.346 157.618 117.704 155.732C119.666 152.94 115.139 150.299 113.177 153.091C111.517 155.43 110.763 155.203 108.046 154.6C105.632 154.072 103.217 153.468 100.803 152.864C96.3514 151.808 91.8998 150.676 87.3727 149.771C84.1283 149.092 82.6947 154.147 86.0145 154.826Z" fill="#442A25"/>
      <path d="M93.786 131.285C93.786 131.285 102.991 129.399 106.915 131.285C110.838 133.172 121.929 142.528 119.666 146.677C117.327 150.752 102.237 150.978 84.5055 149.922C85.9391 141.019 93.786 131.285 93.786 131.285Z" fill="#36B7FF"/>
      <path d="M51.8351 168.03C80.4628 168.03 103.67 144.823 103.67 116.195C103.67 87.5674 80.4628 64.36 51.8351 64.36C23.2074 64.36 0 87.5674 0 116.195C0 144.823 23.2074 168.03 51.8351 168.03Z" fill="#FDDA44"/>
      <path d="M12.1477 102.84C5.50795 120.119 9.50687 137.85 14.8639 139.887C20.221 141.924 29.9542 129.625 36.5939 112.347C43.2336 95.0687 44.29 79.3748 38.9329 77.3376C33.5759 75.3005 18.7874 85.5618 12.1477 102.84Z" fill="#FDE272"/>
      <path d="M89.6372 108.501C91.9058 107.798 92.834 104.291 91.7104 100.669C90.5868 97.0475 87.8369 94.682 85.5683 95.3857C83.2998 96.0895 82.3716 99.5961 83.4951 103.218C84.6187 106.84 87.3686 109.205 89.6372 108.501Z" fill="#442A25"/>
      <path d="M50.6864 134.5C53.8704 133.512 55.4806 129.582 54.283 125.721C53.0853 121.861 49.5333 119.532 46.3493 120.519C43.1654 121.507 41.5551 125.438 42.7528 129.298C43.9505 133.159 47.5025 135.487 50.6864 134.5Z" fill="#442A25"/>
      <path d="M77.7904 115.441C78.6203 118.383 78.9221 122.156 76.2059 124.268C73.5651 126.381 70.6979 125.627 69.3398 122.608C67.9062 119.515 63.3791 122.231 64.8127 125.249C67.3026 130.606 73.4896 132.191 78.4694 129.097C83.6756 125.853 84.5055 119.439 82.921 113.932C81.9402 110.763 76.8849 112.196 77.7904 115.441Z" fill="#442A25"/>
      <path d="M52.7405 64.0582C65.19 65.19 75.4514 78.3185 65.19 90.0889C55.3813 101.331 32.6704 103.067 17.9574 104.877C17.9574 104.877 4.67799 96.955 14.1094 83.2229C23.5408 69.4907 40.291 62.9264 52.7405 64.0582Z" fill="#EDC51B"/>
      <path d="M52.7405 64.0582C65.19 65.19 69.5661 71.0752 64.6618 81.2611C59.682 91.5225 32.6704 104.047 17.9574 104.802C17.9574 104.802 4.67799 96.8795 14.1094 83.1474C23.5408 69.4153 40.291 62.9264 52.7405 64.0582Z" fill="#48DDFF"/>
      <path d="M38.856 92.6141C49.7674 81.7212 54.3169 68.5876 49.0177 63.2793C43.7184 57.9711 30.5771 62.4983 19.6657 73.3912C8.75427 84.2842 4.20472 97.4178 9.50397 102.726C14.8032 108.034 27.9446 103.507 38.856 92.6141Z" fill="#5AC1EA"/>
      <path d="M8.90326 102.086L49.8733 64.36L43.3845 54.4759L2.48989 92.2016L8.90326 102.086Z" fill="#5AC1EA"/>
      <path d="M32.9229 83.4791C43.8343 72.5862 48.3838 59.4526 43.0846 54.1443C37.7853 48.8361 24.644 53.3633 13.7326 64.2562C2.82117 75.1492 -1.72838 88.2828 3.57087 93.5911C8.87013 98.8993 22.0115 94.3721 32.9229 83.4791Z" fill="#48DDFF"/>
      <path d="M20.0701 145.998C19.1646 148.488 18.1083 150.903 16.9766 153.317C16.0711 155.279 12.0722 165.238 9.35597 164.71C6.03611 164.107 4.60253 169.162 7.92239 169.766C14.2603 170.973 17.2029 164.333 19.7683 159.504C21.8054 155.581 23.6163 151.506 25.1253 147.432C26.3325 144.263 21.2773 142.829 20.0701 145.998Z" fill="#442A25"/>
      <path d="M63.7564 163.805C66.3972 168.106 68.9626 172.406 71.6033 176.707C73.7914 180.329 75.8286 185.384 78.9976 188.251C79.6012 188.855 80.6575 189.307 81.5629 188.93C84.3546 187.798 85.8637 185.459 86.9954 182.743C88.2781 179.65 83.2229 178.216 81.9402 181.31C81.7138 181.913 80.8839 183.498 80.2048 183.8C81.0348 184.026 81.9402 184.252 82.7702 184.479C81.2611 183.045 80.2803 180.706 79.2239 178.895C77.9413 176.858 76.734 174.821 75.4514 172.708C73.1124 168.785 70.6979 164.937 68.3589 161.013C66.5481 158.222 61.9456 160.862 63.7564 163.805Z" fill="#442A25"/>
      <path d="M10.4123 146.753C10.4123 146.753 18.4101 142.301 27.917 141.17C37.1975 140.113 63.1528 147.281 96.2759 147.281" stroke="#36B7FF" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round"/>
    </g>
    <defs>
      <clipPath id="clip0_1_16">
        <rect width="280" height="189.081" fill="white"/>
      </clipPath>
    </defs>
  </svg>
)