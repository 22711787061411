import React, { useEffect, useState } from 'react';
import { 
  Typography,
  Checkbox,
  CheckboxOptionType,
  CheckboxValueType 
} from '@thepiquelab/archus-components-web';
import "./checkbox-grouped.scss";
import { observer } from 'mobx-react';

/**
 * Custom Grouped Options properties
 */
interface CustomGroupedOptionsProperties {
  /** Header text/Title of the option group */
  groupTitle: string;
  /** Sets the Select All functionality label */
  selectAllLabel: string;
  /** All the selectable options */
  options: Array<CheckboxOptionType>;
  /** True to disable selecting options */
  disable?: boolean;
  /** Initial selected options */
  value?: CheckboxValueType[];
  /** Action on change of selected options */
  onChange: (checked: CheckboxValueType[]) => void;
}

/**
 * Custom component for displaying GroupedOptions for input
 * @param props 
 */
const CustomGroupedOptions:React.FC<CustomGroupedOptionsProperties> = (props) => {
  const {
    options,
    selectAllLabel,
    disable,
    value,
    onChange,
    groupTitle
  } = props;

  /** List of options selected */
  const [selectedOptions, setSelectedOptions] = useState<CheckboxValueType[]>(value ?? []);

  useEffect(() => {
    onChange(selectedOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);
  
  return (
    <div className="checkbox-grouped-options flex flex-col gap-4">
      <Typography.Heading level={4} className='!font-semibold'>{groupTitle}</Typography.Heading>
      <Checkbox 
        onChange={event => 
          event.target.checked 
            ? setSelectedOptions([...options.map(opt => opt.value)])
            : setSelectedOptions([])} 
        checked={value?.length === options.length}
      > 
          <span className='!text-sm !font-medium'>{selectAllLabel}</span>
      </Checkbox>
      {
        options.map(opt => (
          <Checkbox value={opt.value} 
            checked={value?.includes(opt.value)} 
            name={groupTitle} 
            disabled={disable || opt.disabled} 
            onChange={event => 
              event.target.checked 
                ? setSelectedOptions([...selectedOptions, opt.value])
                : setSelectedOptions([...selectedOptions.filter(option => option !== opt.value)])} 
          > 
            <span className='!text-sm !font-normal'>{opt.label}</span>
          </Checkbox>
        ))
      }
      
    </div>
  );
  
};

export default observer(CustomGroupedOptions);