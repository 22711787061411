import React, { useMemo, useState } from 'react';
import { Button, Divider, Typography, Checkbox, FilterDrawer, Select } from '@thepiquelab/archus-components-web';
import { observer } from 'mobx-react';
import { useCoreStore } from '../../mobx';
import { ClassFilterInput, WeekDay } from '../../utils';
import CustomGroupedOptions from '../Common/CustomGroupedOptions';
import './class-filter-drawer.scss';

/** Class Filter Drawer Properties */
interface ClassFilterDrawerProps {
  /** Action on close of filter drawer */
  onClose?:((e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void);
  /** Action on click of apply button */
  onApplyFilter: (filter: ClassFilterInput) => void;
}

/**
 * Filter Drawer for class items
 * @param props 
 */
const ClassFilterDrawer:React.FC<ClassFilterDrawerProps> = (props) => {
  const {
    onClose,
    onApplyFilter
  } = props;

  const {
    availableAcademicLevels,
    allTeachers,
    coreClassesFilter
  } = useCoreStore();

  /** All available options for academic level */
  const academicLevelOptions = useMemo(() => (
    availableAcademicLevels.map((acadLevel) => ({
      label: acadLevel.name,
      value: acadLevel.id
    })))
    , [availableAcademicLevels]);

  /** All available options for lesson day */
  const lessonDayOptions = Object.values(WeekDay).map(day => ({
    label: day,
    value: day
  }));

  /** Search value for teacher options */
  const [allTeacherSearch, setAllTeacherSearch] = useState("");

  /** All available options for all teachers */
  const allTeacherOptions = useMemo(() => (
    allTeachers.map((teacher) => ({
      label: teacher.name,
      value: teacher._id
    })))
    , [allTeachers]);

  /** Academic Level values selected for filtering */
  const [filterAcademicLevel, setFilterAcademicLevel] = useState<string[]>(
    coreClassesFilter.academicLevelIds 
      ? coreClassesFilter.academicLevelIds
      : academicLevelOptions.map(acadLevel => acadLevel.value)
  );
  /** Lesson Day values selected for filtering */
  const [filterLessonDay, setFilterLessonDay] = useState<string[]>(
    coreClassesFilter.lessonDays
      ? coreClassesFilter.lessonDays
      : Object.values(WeekDay)
  );
  /** Teacher Id values selected for filtering */
  const [filterTeacherIds, setFilterTeacherIds] = useState<string[]>(
    coreClassesFilter.teacherIds
      ? coreClassesFilter.teacherIds
      : []
  );

  return (
    <FilterDrawer key="right" 
      visible 
      onClose={onClose} >
        <FilterDrawer.Content className='flex flex-col gap-4'>
          <CustomGroupedOptions 
            groupTitle='Levels'
            onChange={(acadLevels) => setFilterAcademicLevel(acadLevels as string[])}
            options={academicLevelOptions}
            selectAllLabel='All Levels'
            value={filterAcademicLevel}
          />
          <Divider className='!m-0' />
          <CustomGroupedOptions 
            groupTitle='Lesson Day'
            onChange={(lessonDays) => setFilterLessonDay(lessonDays as string[])}
            options={lessonDayOptions}
            selectAllLabel='All Lesson Days'
            value={filterLessonDay}
          />
          <Divider className='!m-0' />
          <div className="checkbox-grouped-options flex flex-col gap-4">
            <Typography.Heading level={4} className='!font-semibold'>Teacher</Typography.Heading>
            <Checkbox 
              onChange={() => setFilterTeacherIds([])} 
              checked={filterTeacherIds.length === 0 || filterTeacherIds.length === allTeacherOptions.length}
            > 
              <span className='!text-sm !font-medium'>All Teachers</span>
            </Checkbox>
            <Select value={filterTeacherIds} 
              placeholder="Select" 
              options={allTeacherOptions}
              onChange={selectedTeachers => setFilterTeacherIds(selectedTeachers)}
              searchValue={allTeacherSearch}
              mode='multiple'
              showSearch
              autoClearSearchValue
              optionFilterProp='label'
              onSearch={(searchVal) => setAllTeacherSearch(searchVal)} />
          </div>
        </FilterDrawer.Content>
        <FilterDrawer.Footer className="flex flex-row-reverse gap-2">
          <Button type='primary' 
            onClick={(event) => {
              onApplyFilter({
                academicLevelIds: filterAcademicLevel,
                lessonDays: filterLessonDay,
                teacherIds: filterTeacherIds
              });
              if (onClose){
                onClose(event);
              }
            }}>
            Apply
          </Button>
          <Button type='default' onClick={onClose}>
            Cancel
          </Button>
        </FilterDrawer.Footer>
    </FilterDrawer>
  );

};

export default observer(ClassFilterDrawer);