import { IconSVGProps } from "../../utils";

/** SVG Icon for Dashboard */
export const DashboardIcon:React.FC<IconSVGProps>  = ({size = 16, ...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_253_2069)">
      <path d="M3.85742 12.2879H5.28599C5.36457 12.2879 5.42885 12.2236 5.42885 12.145V3.85931C5.42885 3.78074 5.36457 3.71646 5.28599 3.71646H3.85742C3.77885 3.71646 3.71457 3.78074 3.71457 3.85931V12.145C3.71457 12.2236 3.77885 12.2879 3.85742 12.2879ZM7.28599 7.28789H8.71457C8.79314 7.28789 8.85742 7.2236 8.85742 7.14503V3.85931C8.85742 3.78074 8.79314 3.71646 8.71457 3.71646H7.28599C7.20742 3.71646 7.14314 3.78074 7.14314 3.85931V7.14503C7.14314 7.2236 7.20742 7.28789 7.28599 7.28789ZM10.7146 8.5736H12.1431C12.2217 8.5736 12.286 8.50932 12.286 8.43074V3.85931C12.286 3.78074 12.2217 3.71646 12.1431 3.71646H10.7146C10.636 3.71646 10.5717 3.78074 10.5717 3.85931V8.43074C10.5717 8.50932 10.636 8.5736 10.7146 8.5736ZM14.5717 0.859314H1.42885C1.11278 0.859314 0.857422 1.11467 0.857422 1.43074V14.5736C0.857422 14.8897 1.11278 15.145 1.42885 15.145H14.5717C14.8878 15.145 15.1431 14.8897 15.1431 14.5736V1.43074C15.1431 1.11467 14.8878 0.859314 14.5717 0.859314ZM13.8574 13.8593H2.14314V2.14503H13.8574V13.8593Z" fill={props.color}/>
    </g>
    <defs>
      <clipPath id="clip0_253_2069">
      <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);