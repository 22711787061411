import React from 'react';
import { observer } from 'mobx-react';
import { Route, Routes } from 'react-router-dom';
import QuickLinks from '../components/QuickLinks/QuickLinks';
import { useAppStore } from '../mobx';

/**
 * Home screen when user is logged in.
 */
const LoggedIn:React.FC = () => {
  const {
    availableRoutes,
  } = useAppStore();

  return (
    <div className='flex h-full'>
      <QuickLinks />
      <Routes>
        {  
          availableRoutes.map(route => (
            <Route key={route._id} path={route.route} element={route.element}/> 
          ))
        }
      </Routes>
    </div>
  );
};

export default observer(LoggedIn);