import { observer } from "mobx-react";
import useDragActions from "../../hooks/useDragActions";
import BulkSelectAll from "./BulkSelectAll";
import { useAppStore } from "../../mobx";

/** Directory Body Props */
interface DirectoryBodyProps {
  /** Component to display when items are empty */
  directoryEmpty: React.ReactNode;
  /** List of item data */
  items?: any[];
  /** Optional property to display directory list regardless if empty */
  showDirectoryList?: boolean;
  /** True if drag actions are disabled */
  isDragActionsDisabled?:boolean;
  /** Content to display when items are not empty */
  children: React.ReactNode;
}

/** Body content of Directory component */
const DirectoryBody: React.FC<DirectoryBodyProps>= (props) => {
  const {
    directoryEmpty,
    items,
    showDirectoryList = false,
    isDragActionsDisabled = false,
    children
  } = props;
  const { isSelecting } = useAppStore();
  const { dragActionRootProps, getInputProps } = useDragActions(false, false, !isDragActionsDisabled);

  return <div className="flex flex-col flex-grow" { ...dragActionRootProps }>
    {  !isSelecting && <input {...getInputProps()} />}
    { 
      showDirectoryList || (items &&!!items.length) 
        ? <> 
          { items && <BulkSelectAll items={items} /> }
          { children }
        </>
        : directoryEmpty
    }
  </div>;
}

export default observer(DirectoryBody);
