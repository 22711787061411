import { useEffect } from "react";
import { observer } from "mobx-react";
import { useAppStore } from "../mobx";
import { Divider } from "@thepiquelab/archus-components-web";
import DirectoryHeader from "../components/Directory/DirectoryHeader";
import DirectoryContent from "../components/Directory/DirectoryContent";
import Breadcrumbs from "../components/Breadcrumb/Breadcrumbs";
import DirectoryAddButton from "../components/Directory/DirectoryAddButton";
import DirectoryEmpty from "../components/Directory/DirectoryEmpty";
import { EmptyFolder } from "../components/Icons";
import HomeList from "../components/Home/HomeList";
import DirectoryBody from "../components/Directory/DirectoryBody";

/** Home directory of Notes Files/Folders */
const Home = () => {
  const {
    folderPaths,
    initActions,
    setMovingFolders,
    currentFolders,
    displayFiles,
    folderPathGoBackTo,
    folderPathGoRoot,
    showCreateFolder,
  } = useAppStore();

  useEffect(() => {
    initActions();
  }, [folderPaths, initActions]);

  useEffect(() => {
    setMovingFolders([])
  }, [currentFolders, setMovingFolders]);

  return (
    <DirectoryContent>
      <DirectoryHeader 
        buttons={<DirectoryAddButton />}
        hideBulkSelect={showCreateFolder}
      >
        <Breadcrumbs
          onItemClick={folderPathGoBackTo}
          onRootClick={folderPathGoRoot}
          paths={folderPaths}
        />
      </DirectoryHeader>
      <Divider className="!text-gray-500 !m-0" />
      <DirectoryBody 
        items={displayFiles}
        directoryEmpty={
          <DirectoryEmpty 
            EmptyIcon={EmptyFolder} 
            subtitle="All the parent folders that are being added will be displayed here"
            title="No folders and files have been added yet" />
        }
        showDirectoryList={showCreateFolder}
      >
        <HomeList />
      </DirectoryBody>
    </DirectoryContent>
  );
}

export default observer(Home);