import { useMemo, useState } from "react";
import { useAppStore } from "../mobx";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";

/**
 * Hook for drag/drop and upload processes
 * @param isMoveDraggable True if component should be able to move folder/files
 * @param isMoveDroppable True if component should be able to receive moved folder/files
 * @param isUploadDroppable True if component should be able to receive uploaded files
 * @param customId 
 * @returns 
 */
const useDragActions = (
    isMoveDraggable: boolean,
    isMoveDroppable: boolean,
    isUploadDroppable: boolean,
    customId?: string
  ) => {

  const { 
    uploadFile,
    setSelectedFiles,
    setSelectedFilesDestinationId,
    setToast,
    setToastShow,
    setMoveDragging,
    moveFilesFolders,
    folderPaths,
    isMoveDragging,
    selectedFilesDestinationId,
  } = useAppStore();

  /** True if mouse is hovering on listItem */
  const [ isHovering, setHovering ] = useState(false);
  
  /** 
   * Upload to Archus Notes Service on drop/select of files
   */
  const onUploadDrop = async (acceptedFiles: any[], fileRejections:FileRejection[]) => {
    if (fileRejections.some(file => file.errors && file.errors)) {
      // Show error toast if file is not accepted
      setToast({
        description: "Files should only be in PDF format.",
        type: "danger"
      });
    } else {
      if (!selectedFilesDestinationId && folderPaths.length) {
        setSelectedFilesDestinationId(folderPaths[folderPaths.length - 1]._id);
      }
      setSelectedFiles(acceptedFiles);
      await uploadFile();
    }
  };

  /**
   * Action if highlighted files (movingFiles) were dropped on a folder
   */
  const onMoveDrop = useMemo(() => (event: DropEvent) => {
    event.stopPropagation();
    if (isMoveDroppable && customId) {
      moveFilesFolders(customId);
    }
  }, [customId, isMoveDroppable, moveFilesFolders])

  /**
   * React-Dropzone options
   */
  const dropzoneProps = useMemo (() => ({
    onDrop: onUploadDrop,
    accept: {
      "application/pdf": [".pdf"],
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [onUploadDrop, customId]);

  /**
   * Functionality to handle uploading files on drag and drop
   */
  const { 
    getRootProps,
    getInputProps 
  } = useDropzone(dropzoneProps);

  /**
   * Component properties to facilitate move functionalities
   */
  const moveFileProps = useMemo(() => ({
      draggable: isMoveDraggable,
      /**
       * Set moveDragging property in store if the component is moveDraggable
       */
      onDragStart: () => {
        if (isMoveDraggable){
          setMoveDragging(true);
        }
      },
      /**
       * Set moveDragging property in store
       */
      onDragEnd:() => setMoveDragging(false),
      /**
       * Hide toast and set hovering over folder on enter of drag
       */
      onDragEnter: (event: { stopPropagation: () => void; }) => {
        event.stopPropagation();
        setToastShow(false);
        if (!isMoveDragging || isMoveDroppable) {
          setHovering(true);
        }
        if (!isMoveDragging) {
          setSelectedFilesDestinationId(customId);
        }
      },
      /**
       * Set not hovering over folder on leave of drag
       */
      onDragLeave: () => setHovering(false),
      /**
       * Set hovering over folder on hover of mouse
       */
      onMouseEnter: () => setHovering(true),
      /**
       * Set not hovering over folder on leave of mouse
       */
      onMouseLeave: () => setHovering(false)
    }),
    [
      isMoveDraggable,
      setMoveDragging,
      isMoveDragging,
      isMoveDroppable,
      setToastShow,
      customId,
      setSelectedFilesDestinationId
    ]
  );

  /** Root Properties for all drag actions */
  const dragActionRootProps = useMemo(() => ({
      ...(isUploadDroppable ? getRootProps(): {}),
      ...(isMoveDragging ? { onDrop: onMoveDrop } : {}),
      ...moveFileProps,
    }), [getRootProps, isMoveDragging, moveFileProps, onMoveDrop, isUploadDroppable]);

  return {
    dragActionRootProps,
    moveFileProps,
    getInputProps,
    isHovering,
  }
};

export default useDragActions;
