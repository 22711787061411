import React from 'react';
import "./custom-segment.scss";
import { observer } from 'mobx-react';
import { Segmented, SegmentedProps } from 'antd';

/**
 * Segment component with custom styles
 * @param props 
 */
const CustomSegment : React.FC<Omit<SegmentedProps, "ref"> & React.RefAttributes<HTMLDivElement>> = 
(props) => {
  return <Segmented className="custom-segment" {...props} />;
};

export default observer(CustomSegment);