import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Divider } from "@thepiquelab/archus-components-web";
import { SharePermissionType } from "../../utils";
import ShareUserSelect from "./ShareUserSelect";
import ShareUserList from "./ShareUserList";
import { FileResponse, SharedEvent } from "../../mobx/app.store";
import { useAppStore } from "../../mobx";

import { User } from "../../models/users.model";
import { FileAccess } from "../../models/file.model";

/**
 * ShareUserModal Body properties
 */
interface ShareUserProps {
  /** Item to be shared in ShareUserModal */
  item?: FileResponse;
  /** Action on select of permission */
  onPermissionSelect: (permission: SharePermissionType) => void;
  /** Action on add/remove of selected users */
  onSelectedUsersChange: (user: User[]) => void;
}

/**
 * ShareUserModal Body content
 */
const ShareUser: React.FC<ShareUserProps> = (props) => {
  const { item, onPermissionSelect, onSelectedUsersChange } = props;

  const { getItemSharedUsers, getAllUsers } = useAppStore();

  /** List of users who already have access to the File/Folder */
  const [sharedUsers, setSharedUsers] = useState<FileAccess[]>([]);

  /** All users available for file/folder sharing */
  const [allUsers, setAllUsers] = useState<User[]>([]);

  useEffect(() => {
    // Initialize list of users who already have access to the Item
    initSharedEvents();

    // Initialize list of all users available for file/folder sharing
    getAllUsers().then((result) => {
      setAllUsers(result.data ?? []);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Initializes list of users who already have access to the Item
   */
  const initSharedEvents = () => {
    if (item) {
      getItemSharedUsers(item._id).then((_accessDetailsList) => {
        setSharedUsers(_accessDetailsList.data?.accesses ?? []);
      });
    }
  };

  return (
    <div className="flex flex-col gap-7 p-6">
      <ShareUserSelect
        onPermissionSelect={onPermissionSelect}
        onSelectedUsersChange={onSelectedUsersChange}
        sharedUsers={sharedUsers}
        allUsers={allUsers}
      />
      {sharedUsers && !!sharedUsers.length && item && (
        <>
          <Divider className="!text-gray-500 !m-0" />
          <ShareUserList
            itemID={item._id}
            sharedUsers={sharedUsers}
            doAfterUpdate={initSharedEvents}
          />
        </>
      )}
    </div>
  );
};

export default observer(ShareUser);
