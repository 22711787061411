import axios from "axios";
import { isEmpty } from "lodash";
import { AppConfig } from "../config";
import { authStorageKey } from "../mobx/app.store";

/**
 * @note this has a very weird behaviour wherein, it converts ALL `response` to
 * always `response.data`. So even if it returns AxiosResponse, it actually
 * returns AxiosResponse.data.
 * 
 * e.g.
 * ```
 * const response = await factory.get('/files', { });
 * response.data; // Will be undefined.
 * response // Will be `response.data` even if typed as AxiosResponse. :/
 * ```
 */
export const factory = axios.create({
  timeout: 30000,
  baseURL: AppConfig.baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
  onUploadProgress: (progressEvent) => console.log(progressEvent.loaded),
});

factory.interceptors.request.use(function (config) {
  const token = sessionStorage.getItem(authStorageKey);
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

factory.interceptors.response.use(
  (response) => {
    if (response.request.responseURL.includes("files/sub-files")) {
      if (response.data.code === 1 && response.data.data.pageNumber === 0) {
        localStorage.setItem("syncDate", response.headers.date);
      }

      return response.data;
    }
    if (response.request.responseURL.includes("/sync/annotations")) {
      // console.log("syncAnnotationsDate", response.headers.date);
      if (!isEmpty(response.data.data)) {
        localStorage.setItem("syncAnnotationsDate", response.headers.date);
      }

      return response.data;
    }
    if (response.request.responseURL.includes("annotations/all")) {
      // console.log("syncAnnotationsDate", response.headers.date);
      if (response.data.code === 1 && response.data.data.pageNumber === 0) {
        localStorage.setItem("syncAnnotationsDate", response.headers.date);
      }

      return response.data;
    }
    if (response.request.responseURL.includes("/sync?")) {
      return response;
    }
    return response.data;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
    }
    return Promise.reject(error.response ? error.response.data : error);
  }
);

export interface FileProps {
  mimeType: string;
  name: string;
  size: number;
  type: string;
  uri: string;
}

/** @deprecated use src/network/types.ts ResponseData instead. */
export interface ResponseProps {
  code: number;
  message: string;
}

export enum FileType {
  Folder = "Folder",
  File = "File",
  Note = "Note",
}

export interface MetadataProps {
  contentType: string;
  contentCreatedAt: number;
  contentModifiedAt: number;
  size: number;
  contentHash?: string;
}
export interface FileInfoProps {
  name: string;
  type: FileType;
  parentId?: string;
  url?: string;
  thumbnailUrl?: string;
  metadata?: MetadataProps;
}

export enum UploadType {
  User = "User",
  File = "File",
}

export interface GetFilesFilter {
  parentId?: string;
  pageNumber?: number;
  pageSize?: number;
}
export async function GetFiles(filter: GetFilesFilter) {
  try {
    let result = await factory.get(AppConfig.baseUrl + "/files", {
      params: filter,
    });
    return result.data;
  } catch (error) {
    return { error };
  }
}

// export default {
//   PostAxios,
//   GetAxios,
//   UploadFile,
// };

// export default factory;
