import { observer } from "mobx-react";
import React from "react";
import { Button, Typography } from "@thepiquelab/archus-components-web";
import { useAppStore } from "../../mobx";


/** 
 * Button for bulk select mode 
 */
const BulkSelectButton: React.FC = () => {
  const {
    setIsSelecting,
    setMovingFolders,
    isSelecting,
  } = useAppStore();

  /**
   * If deactivating bulk select mode then empty movingFolders
   */
  const onClickBulkSelect = () => {
    if (isSelecting) {
      setMovingFolders([]);
    }
    setIsSelecting(!isSelecting)
  }

  return (
    <Button type="text" onClick={onClickBulkSelect}>
      <Typography.Heading level={4} className="!font-normal !text-primary-red">
        { isSelecting ? 'Cancel' : 'Select' }
      </Typography.Heading>
    </Button>
  );
};

export default observer(BulkSelectButton);
