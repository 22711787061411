import { useMemo, useState } from "react";
import { Dropdown, Typography } from "@thepiquelab/archus-components-web";
import { CaretDownOutlined } from "@ant-design/icons";
import { SharePermissionOptions, SharePermissionType } from "../../utils";
import { MenuProps } from "antd";

import "./permission-selector.scss";

/** PermissionSelector Properties */
interface PermissionSelectorProps {
  /** Action on select of permission */
  onPermissionSelect: (permission: SharePermissionType) => void;
  /** Initial permission value */
  initialPermission?: string;
  /** True if a child of the item has been modified */
  isModified?: boolean;
  /**
   * True if the permission has been restriced
   * e.g. permission of user to a child item was changed
   */
  isRestricted?: boolean;
}

/**
 * Component for selecting SharePermissions
 */
const PermissionSelector: React.FC<PermissionSelectorProps> = (props) => {
  const {
    onPermissionSelect,
    initialPermission,
    isModified = false,
    isRestricted = false,
  } = props;

  /** Permission selected */
  const [permission, setPermission] = useState<SharePermissionType>(
    // If initialPermission value was provided then find corresponding SharePermissionOption
    initialPermission
      ? SharePermissionOptions.filter(
          (opt) => opt.value === initialPermission
        )?.[0] ?? SharePermissionOptions[0]
      : SharePermissionOptions[0]
  );

  /**
   * Details to append to the end of Permission label
   * - `Restricted` - if the permission to a child item had been removed
   * or user does not have access to all children items
   * - `Modified` - if the permission to a child item had been modified
   * (e.g. Parent permission is 'Edit' but child permission is 'View')
   */
  const permissionDetails = useMemo(() => {
    const retVal = [];

    if (isRestricted) {
      retVal.push("Restricted");
    }

    if (isModified) {
      retVal.push("Modified");
    }

    return retVal.length ? `(${retVal.join(", ")})` : "";
  }, [isModified, isRestricted]);

  /**
   * SharePermission Dropdown list items
   */
  const SharePermissionDropdownOptions = useMemo<MenuProps>(
    () => ({
      className: "permission-select-options", // The styles are overriden by `permission-selector.scss`
      items: SharePermissionOptions.map((opt) => ({
        key: opt.value,
        label: (
          <div
            className="flex flex-col bg-transparent cursor-pointer"
            onClick={() => {
              setPermission(opt);
              onPermissionSelect(opt);
            }}
          >
            <Typography.Text size="middle">{opt.label}</Typography.Text>

            <Typography.Text
              className="!text-primary-navyLight text-wrap"
              size="small"
            >
              {opt.description}
            </Typography.Text>
          </div>
        ),
      })),
    }),
    []
  );

  return (
    <Dropdown
      menu={SharePermissionDropdownOptions}
      trigger={["click"]}
      overlayStyle={{ width: "300px" }}
      autoAdjustOverflow
    >
      <div className="flex cursor-pointer whitespace-nowrap items-center gap-2">
        <Typography.Text size="middle" className="select-none">
          {permission.label} {permissionDetails}
        </Typography.Text>
        <CaretDownOutlined className="!text-primary-navy cursor-pointer" />
      </div>
    </Dropdown>
  );
};

export default PermissionSelector;
